import React, { Component } from 'react';
import Loader from '../dashboard/loader';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import 'react-datepicker/dist/react-datepicker.css';
import { Pagination } from '../action';
import {
  ExcelExport,
  ExcelExportColumn
} from '@progress/kendo-react-excel-export';
import PropTypes from 'prop-types';
import logout from '../../component/dashboard/logout';
class QuestionReportCustom extends Component {
  _exporter;
  export = () => {
    let token = localStorage.getItem('token');
    if (token !== null) {
      this._exporter.save();
    } else {
      logout(this.props.props);
    }
  };

  

  render() {
    const table_spacing = {
      paddingRight:"10px !important"
    };

    return (
      <div>
        <Loader isLoading={this.props.propsState.isLoading} />
        <Loader isLoadingExport={this.props.propsState.isLoadingExport} />
        <div className='page-wrapper'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12'>
                <div className='card'>
                  <h4 className='card-title m-b-0'>Question Report</h4>
                  <div className='card-body'>
                    <div className='row'>
                      <div className='col-md-3' data-select2-id='11'>
                        <b className='filter_head'>Filter by category title</b>
                        <select
                          className='select2 form-control custom-select select2-hidden-accessible'
                          aria-hidden='true'
                          name='filter_category'
                          onChange={e => this.props.handleChange(e)}
                        >
                          <option value=''>Select category title</option>
                          {this.props.category.length > 0
                            ? this.props.category.map((category, index) => {
                                if (category.status === '1') {
                                  return (
                                    <option
                                      key={index}
                                      value={category.category_id}
                                    >
                                      {category.category_name}
                                    </option>
                                  );
                                }
                              })
                            : ''}
                        </select>
                      </div>
                      <div className='col-md-2' data-select2-id='11'>
                        <b className='filter_head'>Filter by quiz title</b>
                        <select
                          className='select2 form-control custom-select select2-hidden-accessible'
                          aria-hidden='true'
                          name='filter_quiz_name'
                          onChange={e => this.props.handleChange(e)}
                        >
                          <option value=''>Select quiz title</option>
                          {this.props.quiz.length > 0
                            ? this.props.quiz.map((quiz, index) => {
                                if (quiz.status === '1') {
                                  return (
                                    <option key={index} value={quiz.quiz_id}>
                                      {quiz.quiz_name}
                                    </option>
                                  );
                                }
                              })
                            : ''}
                        </select>
                      </div>


                      <div className='col-md-2' data-select2-id='11'>
                        <b className='filter_head'>Filter by users attempted</b>
                        <select
                          className='select2 form-control custom-select select2-hidden-accessible'
                          aria-hidden='true'
                          name='filter_attempt'
                          onChange={e => this.props.handleChange(e)}
                        >
                          {/* <option value=''>Select</option> */}
                          <option value=''>All</option>
                          <option value='1'>Retakes only</option>
                          <option value='2'>All user's first attempt</option>

                        </select>
                      </div>


                      <div className='col-md-5 text-right' data-select2-id='11'>
                        <button
                          className='dt-button buttons-excel'
                          onClick={e => this.props.exportReport(e)}
                        >
                          <span>Excel</span>
                        </button>
                      </div>
                    </div>
                    <div className='row mt-4'>
                      <div className='col-sm-12 col-md-6'>
                        <div
                          className='dataTables_length'
                          id='zero_config_length'
                        >
                          <label className='table-label'>
                            <span className='table-span'>Show entries</span>
                            <select
                              name='page_size'
                              aria-controls='zero_config'
                              className='form-control form-control-sm'
                              value={this.props.propsState.page_size}
                              onChange={e => this.props.handleChange(e)}
                            >
                              <option value='10'>10</option>
                              <option value='25'>25</option>
                              <option value='50'>50</option>
                              <option value='100'>100</option>
                            </select>
                          </label>
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-6 text-right'>
                        <div
                          id='zero_config_filter'
                          className='dataTables_filter'
                        >
                          <label className='text-left'>
                            <span>Search:</span>
                            <input
                              type='search'
                              className='form-control form-control-sm'
                              placeholder='Search'
                              aria-controls='zero_config'
                              name='searchTerm'
                              id='searchTerm'
                              value={this.props.propsState.searchTerm}
                              onChange={e => this.props.handleChange(e)}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* <ExcelExport
                      data={this.props.propsState.excel_data}
                      fileName={
                        'Question-Report-' + Math.floor(Date.now() / 1000) + '.xlsx'
                      }
                      ref={exporter => {
                        this._exporter = exporter;
                      }}
                    >
                      <ExcelExportColumn
                        field='category_name'
                        title='Category Title'
                      />
                      <ExcelExportColumn field='quiz_name' title='Quiz Title' />
                      <ExcelExportColumn
                        field='question_text'
                        title='Question Title'
                      />
                      <ExcelExportColumn
                        field='attemptedUser'
                        title='User Count'
                      />
                      <ExcelExportColumn field='option_A' title='Option A' />
                      <ExcelExportColumn field='option_B' title='Option B' />
                      <ExcelExportColumn field='option_C' title='Option C' />*/}
                       {/*<ExcelExportColumn field='option_D' title='Option D' />*/}
                      {/*<ExcelExportColumn
                        field='correctAns'
                        title='Correct Answer'
                      />
                    </ExcelExport>*/}
                    <div className='table-responsive'>
                      <table id='zero_config' className='table question_table'>
                        <thead>
                          <tr>
                            <th
                              width='7%'
                              onClick={() =>
                                this.props.handleSort(
                                  'category_name',
                                  this.props.propsState.sortOrder
                                )
                              }
                            >
                              Category Title
                              {this.props.propsState.sortName ===
                              'category_name' ? (
                                <span className='sort-active'>
                                  {this.props.propsState.sortOrder === 'asc' ? (
                                    <i className='fas fa-sort-amount-up' />
                                  ) : (
                                    <i className='fas fa-sort-amount-down' />
                                  )}
                                </span>
                              ) : (
                                <span className='sort-inactive'>
                                  <i className='fas fa-sort-amount-up' />
                                </span>
                              )}
                            </th>
                            <th
                              width='7%'
                              onClick={() =>
                                this.props.handleSort(
                                  'quiz_name',
                                  this.props.propsState.sortOrder
                                )
                              }
                            >
                              Quiz Title
                              {this.props.propsState.sortName ===
                              'quiz_name' ? (
                                <span className='sort-active'>
                                  {this.props.propsState.sortOrder === 'asc' ? (
                                    <i className='fas fa-sort-amount-up' />
                                  ) : (
                                    <i className='fas fa-sort-amount-down' />
                                  )}
                                </span>
                              ) : (
                                <span className='sort-inactive'>
                                  <i className='fas fa-sort-amount-up' />
                                </span>
                              )}
                            </th>
                            <th
                              width='60%'
                              onClick={() =>
                                this.props.handleSort(
                                  'question_text',
                                  this.props.propsState.sortOrder
                                )
                              }
                            >
                              Title
                              {this.props.propsState.sortName ===
                              'question_text' ? (
                                <span className='sort-active'>
                                  {this.props.propsState.sortOrder === 'asc' ? (
                                    <i className='fas fa-sort-amount-up' />
                                  ) : (
                                    <i className='fas fa-sort-amount-down' />
                                  )}
                                </span>
                              ) : (
                                <span className='sort-inactive'>
                                  <i className='fas fa-sort-amount-up' />
                                </span>
                              )}
                            </th>
                            <th
                              className='text-center'
                              width='10%'
                              onClick={() =>
                                this.props.handleSort(
                                  'attemptedUser',
                                  this.props.propsState.sortOrder
                                )
                              }
                            >
                              User Count
                              {this.props.propsState.sortName ===
                              'attemptedUser' ? (
                                <span className='sort-active'>
                                  {this.props.propsState.sortOrder === 'asc' ? (
                                    <i className='fas fa-sort-amount-up' />
                                  ) : (
                                    <i className='fas fa-sort-amount-down' />
                                  )}
                                </span>
                              ) : (
                                <span className='sort-inactive'>
                                  <i className='fas fa-sort-amount-up' />
                                </span>
                              )}
                            </th>
                            <th width='5%' className='text-center'>
                              Option A
                            </th>
                            <th width='5%' className='text-center'>
                              Option B
                            </th>
                            <th width='5%' className='text-center'>
                              Option C
                            </th>
                            {/*<th width='5%' className='text-center'>
                              Option D
                              </th>*/}
                            <th width='5%' className='text-center'>
                              Correct Answer
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.propsState.isLoading === false ? (
                            this.props.propsState.reports_data.length > 0 ? (
                              this.props.propsState.reports_data.map(
                                (row, index) => (
                                  <tr key={index} id={row.question_id}>
                                    <td className='table_spacing'>{row.category_name}</td>
                                    <td className='table_spacing'>{row.quiz_name}</td>
                                    <td className='table_spacing'>
                                      {row.question_text ? (
                                        row.question_text
                                      ) : (
                                        <span
                                          style={{
                                            display: 'block',
                                            fontSize: 28
                                          }}
                                        >
                                          -
                                        </span>
                                      )}
                                    </td>
                                    <td className='text-center'>
                                      {row.attemptedUser > 0
                                        ? row.attemptedUser
                                        : 0}
                                    </td>
                                    <td className='text-center'>
                                      {row.option_A > 0 ? row.option_A : 0}
                                    </td>
                                    <td className='text-center'>
                                      {row.option_B > 0 ? row.option_B : 0}
                                    </td>
                                    <td className='text-center'>
                                      {row.option_C ? row.option_C : 0}
                                    </td>
                                   {/* <td className='text-center'>
                                      {row.option_D ? row.option_D : 0}
                                      </td> */}
                                    <td className='text-center'>
                                      {row.correctAns ? row.correctAns : '-'}
                                    </td>
                                  </tr>
                                )
                              )
                            ) : (
                              <tr>
                                <td
                                  className='dataTables_empty text-center'
                                  colSpan='10'
                                >
                                  No matching records found
                                </td>
                              </tr>
                            )
                          ) : (
                            <tr>
                              <td
                                className='dataTables_empty text-center'
                                colSpan='10'
                              >
                                Loading...
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className='row mt-4'>
                      <div className='col-sm-12 col-md-5'>
                        <div
                          className='dataTables_info'
                          id='zero_config_info'
                          role='status'
                          aria-live='polite'
                        >
                          {this.props.propsState.isLoading === true
                            ? ''
                            : this.props.propsState.reports_data.length === 0
                            ? `Showing 0 of 0 entries`
                            : `Showing  
                            ${
                              this.props.propsState.reports_data.length <
                              this.props.propsState.page_size
                                ? this.props.propsState.resultCount
                                : parseInt(
                                    this.props.propsState.reports_data.length *
                                      this.props.propsState.currentPage
                                  )
                            }
                            of ${this.props.propsState.resultCount} entries`}
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-7'>
                        <div
                          className='dataTables_paginate paging_simple_numbers float-right'
                          id='zero_config_paginate'
                        >
                          {this.props.propsState.reports_data.length > 0 ? (
                            <Pagination
                              noOfPages={this.props.propsState.noOfPages}
                              currentPage={this.props.propsState.currentPage}
                              nextPage={this.props.propsState.nextPage}
                              prevPage={this.props.propsState.prevPage}
                              lastPage={this.props.propsState.lastPage}
                              gotoPage={this.props.gotoPage}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
QuestionReportCustom.propTypes = {
  handleChange: PropTypes.func,
  gotoPage: PropTypes.func,
  handleSort: PropTypes.func,
  category: PropTypes.array,
  quiz: PropTypes.array,
  exportReport:PropTypes.func
};
export default QuestionReportCustom;
