import React from "react";
import Loader from "../dashboard/loader";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { NavLink } from "react-router-dom";
import { assetsURL, Pagination } from "../action";
import Modal from "react-modal";
import PropTypes from "prop-types";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
const QuestionListCustom = (props) => {
  //  console.log( props.propsState.question_data);

  return (
    <div>
      <Loader isLoading={props.propsState.isLoading} />
      <div className="page-wrapper">
        <Modal
          isOpen={props.propsState.modalIsOpen}
          onAfterOpen={props.afterOpenModal}
          onRequestClose={props.closeModal}
          style={customStyles}
        >
          <span
            className="fas fa-times closepopup"
            onClick={props.closeModal}
          />
          <div>
            {props.propsState.modelContentType === "IMAGE" ? (
              <img
                style={{ width: 800, height: 600 }}
                src={assetsURL + "" + props.propsState.modelContent}
                alt="Questionimage"
              />
            ) : (
              ""
            )}
            {props.propsState.modelContentType === "VIDEO" ? (
              <video style={{ width: 800, height: 600 }} controls>
                <source
                  src={assetsURL + "" + props.propsState.modelContent}
                  type="video/mp4"
                />
                <source
                  src={assetsURL + "" + props.propsState.modelContent}
                  type="video/ogg"
                />
              </video>
            ) : (
              ""
            )}
            {props.propsState.modelContentType === "PDF" ? (
              <embed
                src={assetsURL + "" + props.propsState.modelContent}
                style={{ width: 800, height: 600 }}
              />
            ) : (
              ""
            )}
          </div>
        </Modal>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <h5 className="card-title m-b-0 pl20">Manage Questions</h5>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-2" data-select2-id="11">
                      <b className="filter_head">Filter by category title</b>
                      <select
                        className="select2 form-control custom-select select2-hidden-accessible"
                        data-select2-id="1"
                        aria-hidden="true"
                        name="filter_category_name"
                        onChange={(e) => props.handleChange(e)}
                      >
                        <option value="">Select category title</option>
                        {props.category.length > 0
                          ? props.category.map((category, index) => {
                              return (
                                <option
                                  key={index}
                                  value={category.category_id}
                                >
                                  {category.category_name}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                    </div>

                    <div className="col-md-2" data-select2-id="11">
                      <b className="filter_head">Filter by quiz title</b>
                      <select
                        className="select2 form-control custom-select select2-hidden-accessible"
                        data-select2-id="1"
                        aria-hidden="true"
                        name="filter_quiz_name"
                        onChange={(e) => props.handleChange(e)}
                      >
                        <option value="">Select quiz title</option>
                        {props.quiz.length > 0
                          ? props.quiz.map((quiz, index) => {
                              return (
                                <option key={index} value={quiz.quiz_id}>
                                  {quiz.quiz_name}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-sm-12 col-md-6">
                      <div
                        className="dataTables_length"
                        id="zero_config_length"
                      >
                        <label className="table-label">
                          <span className="table-span">Show entries</span>
                          <select
                            name="page_size"
                            aria-controls="zero_config"
                            className="form-control form-control-sm"
                            value={props.propsState.page_size}
                            onChange={(e) => props.handleChange(e)}
                          >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 text-right">
                      <div
                        id="zero_config_filter"
                        className="dataTables_filter"
                      >
                        <label className="text-left">
                          <span>Search:</span>
                          <input
                            type="search"
                            className="form-control form-control-sm"
                            placeholder="Search"
                            aria-controls="zero_config"
                            name="searchTerm"
                            id="searchTerm"
                            value={props.propsState.searchTerm}
                            onChange={(e) => props.handleChange(e)}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table id="zero_config" className="table">
                      <thead>
                        <tr>
                          <th width="8%">Question ID</th>
                          <th
                            width="10%"
                            onClick={() =>
                              props.handleSort(
                                "category_id",
                                props.propsState.sortOrder
                              )
                            }
                          >
                            Category Title
                            {props.propsState.sortName === "category_id" ? (
                              <span className="sort-active">
                                {props.propsState.sortOrder === "asc" ? (
                                  <i className="fas fa-sort-amount-up" />
                                ) : (
                                  <i className="fas fa-sort-amount-down" />
                                )}
                              </span>
                            ) : (
                              <span className="sort-inactive">
                                <i className="fas fa-sort-amount-up" />
                              </span>
                            )}
                          </th>
                          <th
                            width="10%"
                            onClick={() =>
                              props.handleSort(
                                "quiz_name",
                                props.propsState.sortOrder
                              )
                            }
                          >
                            Quiz Title
                            {props.propsState.sortName === "quiz_name" ? (
                              <span className="sort-active">
                                {props.propsState.sortOrder === "asc" ? (
                                  <i className="fas fa-sort-amount-up" />
                                ) : (
                                  <i className="fas fa-sort-amount-down" />
                                )}
                              </span>
                            ) : (
                              <span className="sort-inactive">
                                <i className="fas fa-sort-amount-up" />
                              </span>
                            )}
                          </th>

                          <th
                            width="20%"
                            onClick={() =>
                              props.handleSort(
                                "question_text",
                                props.propsState.sortOrder
                              )
                            }
                          >
                            Title
                            {props.propsState.sortName === "question_text" ? (
                              <span className="sort-active">
                                {props.propsState.sortOrder === "asc" ? (
                                  <i className="fas fa-sort-amount-up" />
                                ) : (
                                  <i className="fas fa-sort-amount-down" />
                                )}
                              </span>
                            ) : (
                              <span className="sort-inactive">
                                <i className="fas fa-sort-amount-up" />
                              </span>
                            )}
                          </th>
                          <th
                            width="10%"
                            onClick={() =>
                              props.handleSort(
                                "added_by",
                                props.propsState.sortOrder
                              )
                            }
                          >
                            Added By
                            {props.propsState.sortName === "added_by" ? (
                              <span className="sort-active">
                                {props.propsState.sortOrder === "asc" ? (
                                  <i className="fas fa-sort-amount-up" />
                                ) : (
                                  <i className="fas fa-sort-amount-down" />
                                )}
                              </span>
                            ) : (
                              <span className="sort-inactive">
                                <i className="fas fa-sort-amount-up" />
                              </span>
                            )}
                          </th>
                          <th width="2%" className="text-center">
                            Image
                          </th>
                          <th width="2%" className="text-center">
                            Video
                          </th>
                          <th width="2%" className="text-center">
                            Pdf
                          </th>
                          <th width="10%" className="text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.propsState.isLoading === false ? (
                          props.propsState.question_data.length > 0 ? (
                            props.propsState.question_data.map(
                              (question, index) => (
                                <tr key={index} id={question.question_id}>
                                  <td> {question.identity_number}</td>
                                  <td>{question.category_name}</td>
                                  <td>{question.quiz_name}</td>

                                  <td>
                                    {question.question_text ? (
                                      question.question_text
                                    ) : (
                                      <span
                                        style={{
                                          display: "block",
                                          fontSize: 28,
                                        }}
                                      >
                                        -
                                      </span>
                                    )}
                                  </td>
                                  <td>{question.user_name}</td>
                                  <td className="text-center">
                                    {question.question_image ? (
                                      question.question_image.split(",").length
                                    ) : (
                                      <span
                                        style={{
                                          display: "block",
                                          fontSize: 28,
                                        }}
                                      >
                                        -
                                      </span>
                                    )}
                                  </td>
                                  <td className="text-center">
                                    {question.question_video ? (
                                      question.question_video.split(",").length
                                    ) : (
                                      <span
                                        style={{
                                          display: "block",
                                          fontSize: 28,
                                        }}
                                      >
                                        -
                                      </span>
                                    )}
                                  </td>

                                  <td className="text-center">
                                    {question.question_pdf ? (
                                      question.question_pdf.split(",").length
                                    ) : (
                                      <span
                                        style={{
                                          display: "block",
                                          fontSize: 28,
                                        }}
                                      >
                                        -
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    <div>
                                      <button
                                        className={
                                          question.question_status === "1"
                                            ? "btn btn-success btn-sm"
                                            : "btn btn-dark btn-sm"
                                        }
                                        onClick={() =>
                                          props.changeStatus(
                                            question.question_id,
                                            question.question_status === "1"
                                              ? 2
                                              : 1
                                          )
                                        }
                                      >
                                        <i
                                          className={
                                            question.question_status === "1"
                                              ? "fas fa-check"
                                              : "fas fa-times"
                                          }
                                        />
                                      </button>

                                      <NavLink
                                        to={`/editquestions/${question.question_id}`}
                                      >
                                        <button
                                          className="btn btn-primary btn-sm"
                                          title="Edit"
                                        >
                                          <i className="fas fa-pencil-alt" />
                                        </button>
                                      </NavLink>

                                      {question.attemptQuestion > 0 ? (
                                        <button
                                          className="btn btn-danger btn-sm disabled"
                                          title="Delete"
                                        >
                                          <i className="fas fa-trash-alt" />
                                        </button>
                                      ) : (
                                        <button
                                          className="btn btn-danger btn-sm"
                                          title="Delete"
                                          onClick={() =>
                                            props.removeQuestion(
                                              question.question_id
                                            )
                                          }
                                        >
                                          <i className="fas fa-trash-alt" />
                                        </button>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              )
                            )
                          ) : (
                            <tr>
                              <td
                                className="dataTables_empty text-center"
                                colSpan="4"
                              >
                                No matching records found
                              </td>
                            </tr>
                          )
                        ) : (
                          <tr>
                            <td
                              className="dataTables_empty text-center"
                              colSpan="4"
                            >
                              Loading...
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="row mt-4">
                    <div className="col-sm-12 col-md-5">
                      <div
                        className="dataTables_info"
                        id="zero_config_info"
                        role="status"
                        aria-live="polite"
                      >
                        {props.propsState.isLoading === true
                          ? ""
                          : props.propsState.question_data.length === 0
                          ? `Showing 0 of 0 entries`
                          : `Showing  
                          ${
                            props.propsState.question_data.length <
                            props.propsState.page_size
                              ? props.propsState.resultCount
                              : parseInt(
                                  props.propsState.question_data.length *
                                    props.propsState.currentPage
                                )
                          }
                          of ${props.propsState.resultCount} entries`}
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-7">
                      <div
                        className="dataTables_paginate paging_simple_numbers float-right"
                        id="zero_config_paginate"
                      >
                        {props.propsState.question_data.length > 0 ? (
                          <Pagination
                            noOfPages={props.propsState.noOfPages}
                            currentPage={props.propsState.currentPage}
                            nextPage={props.propsState.nextPage}
                            prevPage={props.propsState.prevPage}
                            lastPage={props.propsState.lastPage}
                            gotoPage={props.gotoPage}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
QuestionListCustom.propTypes = {
  afterOpenModal: PropTypes.func,
  closeModal: PropTypes.func,
  handleChange: PropTypes.func,
  handleSort: PropTypes.func,
  openModal: PropTypes.func,
  changeStatus: PropTypes.func,
  removeQuestion: PropTypes.func,
  gotoPage: PropTypes.func,
  category: PropTypes.array,
  quiz: PropTypes.array,
};
Modal.setAppElement("body");
export default QuestionListCustom;
