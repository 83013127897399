import React from "react";
import "./App.css";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AddUser from "./container/users/add-user";
import { baseURL } from "./component/action";
import Login from "./component/dashboard/login";
import PageNotFound from "./component/dashboard/page-not-found";
import Dashboard from "./container/dashboard/dashboard";
import EditUser from "./container/users/edit-user";
import AddQuiz from "./container/quiz/add-quiz";
import QuizList from "./container/quiz/quiz-list";
import EditQuiz from "./container/quiz/edit-quiz";
import AddQuestion from "./component/question/add-question";
import QuestionList from "./container/question/question-list";
import EditQuestion from "./component/question/edit-question";
import QuizDetailReport from "./container/reports/quiz-detail-report";
import UserReports from "./container/reports/user-report";
import ChangePassword from "./container/dashboard/change-password";
import UserList from "./container/users/user-list";
import QuizReport from "./container/reports/quiz-report";
import QuestionReport from "./container/reports/question-report";
import Overallreport from "./container/reports/overall-report";
import AddCategory from "./container/category/add-category";
import Layout from "./component/dashboard/Layout";
import CategoryList from "./container/category/category-list";
import EditCategory from "./container/category/edit-category";
import { ToastContainer } from "react-toastify";
import Notification from "./container/notification/add-notification";
import "react-toastify/dist/ReactToastify.css";
import Feedbackreport from "./container/reports/feedback-report";

import AdminChangeLogreport from "./container/reports/admin-change-log-report";

function App() {
  return (
    <div >
      <BrowserRouter basename={baseURL}>
        <ToastContainer />
        <Switch>
          <Route
            path="/notification"
            render={props => {
              return localStorage.getItem("token") ? (
                <Layout>
                  <Notification {...props} />
                </Layout>
              ) : (
                <Redirect to="/login" />
              );
            }}
          />

          <Route
            path="/"
            exact
            render={props => {
              return localStorage.getItem("token") ? (
                <Redirect to="/categorylist" />
              ) : (
                <Login {...props} />
              );
            }}
          />
          <Route
            path="/login"
            render={props => {
              return localStorage.getItem("token") ? (
                <Redirect to="/categorylist" />
              ) : (
                <Login {...props} />
              );
            }}
          />
          <Route
            path="/adduser"
            render={props => {
              return localStorage.getItem("token") ? (
                <Layout>
                  <AddUser {...props} />
                </Layout>
              ) : (
                <Redirect to="/login" />
              );
            }}
          />
          <Route
            path="/dashboard"
            render={props => {
              return localStorage.getItem("token") ? (
                <Layout>
                  <Dashboard {...props} />
                </Layout>
              ) : (
                <Redirect to="/login" />
              );
            }}
          />
          {/* <Route
            path="/userslist"
            render={props => {
              return localStorage.getItem("token") ? (
                <Layout>
                  <UserList {...props} />
                </Layout>
              ) : (
                <Redirect to="/login" />
              );
            }}
          /> */}
          <Route
            path="/addcategory"
            render={props => {
              return localStorage.getItem("token") ? (
                <Layout>
                  <AddCategory {...props} />
                </Layout>
              ) : (
                <Redirect to="/login" />
              );
            }}
          />
          <Route
            path="/categorylist"
            render={props => {
              return localStorage.getItem("token") ? (
                <Layout>
                  <CategoryList {...props} />
                </Layout>
              ) : (
                <Redirect to="/login" />
              );
            }}
          />
          <Route
            path="/addquiz"
            render={props => {
              return localStorage.getItem("token") ? (
                <Layout>
                  <AddQuiz {...props} />
                </Layout>
              ) : (
                <Redirect to="/login" />
              );
            }}
          />
          <Route
            path="/quizlist"
            render={props => {
              return localStorage.getItem("token") ? (
                <Layout>
                  <QuizList {...props} />
                </Layout>
              ) : (
                <Redirect to="/login" />
              );
            }}
          />

          <Route
            path="/addquestion"
            render={props => {
              return localStorage.getItem("token") ? (
                <Layout>
                  <AddQuestion {...props} />
                </Layout>
              ) : (
                <Redirect to="/login" />
              );
            }}
          />
          <Route
            path="/questionlist"
            render={props => {
              return localStorage.getItem("token") ? (
                <Layout>
                  <QuestionList {...props} />
                </Layout>
              ) : (
                <Redirect to="/login" />
              );
            }}
          />
          <Route
            path="/quizdetailreport"
            render={props => {
              return localStorage.getItem("token") ? (
                <Layout>
                  <QuizDetailReport {...props} />
                </Layout>
              ) : (
                <Redirect to="/login" />
              );
            }}
          />
          <Route
            path="/userreport"
            render={props => {
              return localStorage.getItem("token") ? (
                <Layout>
                  <UserReports {...props} />
                </Layout>
              ) : (
                <Redirect to="/login" />
              );
            }}
          />
          <Route
            path="/quizreport"
            render={props => {
              return localStorage.getItem("token") ? (
                <Layout>
                  <QuizReport {...props} />
                </Layout>
              ) : (
                <Redirect to="/login" />
              );
            }}
          />
          <Route
            path="/questionreport"
            render={props => {
              return localStorage.getItem("token") ? (
                <Layout>
                  <QuestionReport {...props} />
                </Layout>
              ) : (
                <Redirect to="/login" />
              );
            }}
          />
          <Route
            path="/overallreport"
            render={props => {
              return localStorage.getItem("token") ? (
                <Layout>
                  <Overallreport {...props} />
                </Layout>
              ) : (
                <Redirect to="/login" />
              );
            }}
          />
          <Route
            path="/changepassword"
            render={props => {
              return localStorage.getItem("token") ? (
                <Layout>
                  <ChangePassword {...props} />
                </Layout>
              ) : (
                <Redirect to="/login" />
              );
            }}
          />
          <Route
            path="/edituser/:id"
            render={props => {
              return localStorage.getItem("token") ? (
                <Layout>
                  <EditUser {...props} />
                </Layout>
              ) : (
                <Redirect to="/login" />
              );
            }}
          />
          <Route
            path="/editquestions/:id"
            render={props => {
              return localStorage.getItem("token") ? (
                <Layout>
                  <EditQuestion {...props} />
                </Layout>
              ) : (
                <Redirect to="/login" />
              );
            }}
          />
          <Route
            path="/editquiz/:id"
            render={props => {
              return localStorage.getItem("token") ? (
                <Layout>
                  <EditQuiz {...props} />
                </Layout>
              ) : (
                <Redirect to="/login" />
              );
            }}
          />
          <Route
            path="/editcategory/:id"
            render={props => {
              return localStorage.getItem("token") ? (
                <Layout>
                  <EditCategory {...props} />
                </Layout>
              ) : (
                <Redirect to="/login" />
              );
            }}
          />
          <Route
            path="/feedback-report"
            render={props => {
              return localStorage.getItem("token") ? (
                <Layout>
                  <Feedbackreport {...props} />
                </Layout>
              ) : (
                <Redirect to="/login" />
              );
            }}
          />



          <Route
            path="/admin-change-log-report"
            render={props => {
              return localStorage.getItem("token") ? (
                <Layout>
                  <AdminChangeLogreport {...props} />
                </Layout>
              ) : (
                <Redirect to="/login" />
              );
            }}
          />

          {/* <Route path="/editquestions/:id" component={EditQuestion} /> */}
          {/* <Route path="/edituser/:id" component={EditUser} /> */}
          {/* <Route path="/editquiz/:id" component={EditQuiz} /> */}
          {/* <Route path="/editcategory/:id" component={EditCategory} /> */}
          <Route path="" component={PageNotFound} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
