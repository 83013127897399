import React, { Component } from "react";
import AddQuizForm from "../../component/quiz/add-quiz-form";
import API from "../../api";
import logout from "../../component/dashboard/logout";
import { toast } from "react-toastify";
import { GetError } from "../../component/action";
import { GetAllCategory } from "../../action/category-action";
import { connect } from "react-redux";

class AddQuiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      IsLoading: false,
      IsSubmit: false,
    };
  }
  componentWillMount() {
    this.props.dispatch(GetAllCategory());
  }
  submitQuiz = (data) => {
    let self = this;
    this.setState({
      IsLoading: true,
      IsSubmit: true,
    });
    console.log(data);
    let quizParam = new FormData();
    quizParam.append("quiz_name", data.quiz_name);
    quizParam.append("category_id", data.category_name);
    quizParam.append("is_required", data.is_required);
    quizParam.append("start_date", data.start_date);
    quizParam.append("end_date", data.end_date);
    quizParam.append("is_required", data.is_required);

    API.post("quiz/quiz_add", quizParam)
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({
            IsLoading: false,
            IsSubmit: false,
            successfull: true,
          });
          this.props.history.push("/quizlist");
          toast.success(res.data.message);
        }
      })
      .catch(function (error) {
        let errorobj = GetError(error);
        if (errorobj.status === 400) {
          toast.error(errorobj.data.message);
        } else if (errorobj.status === 401) {
          toast.error(errorobj.data.message);
          setTimeout(function () {
            logout(this.props);
          }, 2000);
        } else if (errorobj.status === 403) {
          toast.error(errorobj.data.message);
          setTimeout(function () {
            logout(self.props);
          }, 2000);
        } else {
          toast.error("Something went wrong on server end.");
        }
        self.setState({
          IsLoading: false,
          IsSubmit: false,
        });
      });
  };
  render() {
    return (
      <AddQuizForm
        submitQuiz={this.submitQuiz}
        propsState={this.state}
        category={this.props.load_category}
      />
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    load_category: state.LoadCategory,
  };
};
export default connect(mapStateToProps)(AddQuiz);
