import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import API from '../../api';
import { toast } from 'react-toastify';
import Loader from '../dashboard/loader';
import PropTypes from 'prop-types';
class EditUserForm extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      user_id: '',
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      armdsid: '',
      Issubmit: this.props.Issubmit,
      isLoad: this.props.isLoad
    };
    this.validator = new SimpleReactValidator({
      validators: {
        validPassword: {
          message: 'Password must be 8 characters long.',
          rule: (val, params, validator) => {
            return val.length >= 8;
          },
          required: false // optional
        }
      }
    });
  }

  componentWillMount() {
    let self = this;
    this.setState({
      isLoad: true
    });
    API.get(`user/user/${this.props.id}`)
      .then(res => {
        this.setState({
          user_id: res.data.data.user_id,
          firstname: res.data.data.firstname,
          lastname: res.data.data.lastname,
          email: res.data.data.email,
          password:
            res.data.data.password !== false ? res.data.data.password : '',
          armdsid: res.data.data.ardms_id,
          isLoad: false
        });
      })
      .catch(function(error) {
        toast.error('Something went wrong on server end.');
        self.setState({
          isLoad: false
        });
      });
  }

  UpdateUser(e, user_id) {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.props.UpdateUser(user_id, this.state);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({
        isLoad: false,
        Issubmit: false
      });
    }
  }

  handlechange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  render() {
    return (
      <div>
        <Loader isLoading={this.state.isLoad} />
        <div className='page-wrapper'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='card'>
                  <h4 className='card-title'>Edit User</h4>
                  <div className='col-md-10'>
                    <form className='form-horizontal'>
                      <div className='card-body'>
                        <div className='form-group row'>
                          <div className='col-sm-4'>
                            <label className='text-right control-label col-form-label label-text'>
                              First Name<sup className='req-color'>*</sup>
                            </label>

                            <input
                              type='text'
                              className='form-control custom-input'
                              id='fname'
                              value={this.state.firstname}
                              name='firstname'
                              onChange={e => this.handlechange(e)}
                              placeholder='Enter First Name'
                            />
                            {this.validator.message(
                              'First Name',
                              this.state.firstname,
                              'required|alpha_space|min:1|max:100'
                            )}
                          </div>
                          <div className='col-sm-4'>
                            <label className='control-label col-form-label label-text'>
                              Last Name<sup className='req-color'>*</sup>
                            </label>

                            <input
                              type='text'
                              className='form-control custom-input'
                              id='lname'
                              value={this.state.lastname}
                              name='lastname'
                              onChange={e => this.handlechange(e)}
                              placeholder='Enter Last Name'
                            />
                            {this.validator.message(
                              'Last Name',
                              this.state.lastname,
                              'required|alpha_space|min:2|max:100'
                            )}
                          </div>
                          <div className='col-sm-4'>
                            <label className='control-label col-form-label label-text'>
                              Email<sup className='req-color'>*</sup>
                            </label>

                            <input
                              type='email'
                              className='form-control custom-input'
                              id='email'
                              value={this.state.email}
                              name='email'
                              onChange={e => this.handlechange(e)}
                              placeholder='Enter Email'
                              readOnly
                              autoComplete='off'
                            />
                            {this.validator.message(
                              'Email',
                              this.state.email,
                              'required|email|min:2|max:100'
                            )}
                          </div>
                        </div>
                        <div className='form-group row'>
                          <div className='col-sm-4'>
                            <label className='control-label col-form-label label-text'>
                              Password
                            </label>

                            <input
                              type='password'
                              className='form-control custom-input'
                              id='password'
                              value={this.state.password}
                              name='password'
                              onChange={e => this.handlechange(e)}
                              placeholder='Enter Password'
                              autoComplete='off'
                            />
                            {this.state.password
                              ? this.validator.message(
                                  'Password must be eight characters long.',
                                  this.state.password,
                                  'validPassword'
                                )
                              : ''}
                          </div>
                          <div className='col-sm-4'>
                            <label className='control-label col-form-label label-text'>
                              ARDMS ID
                            </label>

                            <input
                              type='text'
                              className='form-control custom-input'
                              id='ardmsid'
                              placeholder='Enter ARDMS ID'
                              value={this.state.armdsid}
                              name='armdsid'
                              onChange={e => this.handlechange(e)}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                      <div className='border-top'>
                        <div className='card-body'>
                          <button
                            type='submit'
                            onClick={e =>
                              this.UpdateUser(e, this.state.user_id)
                            }
                            className='btn btn-primary-newui btn-md-newui'
                            disabled={this.state.Issubmit}
                          >
                            Save
                          </button>
                          <NavLink
                            to='/userslist'
                            type='button'
                            className='btn btn-primary-newui btn-md-newui'
                          >
                            Cancel
                          </NavLink>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
EditUserForm.propTypes = {
  id: PropTypes.string,
  UpdateUser: PropTypes.func,
  Issubmit: PropTypes.bool,
  isLoad: PropTypes.bool
};
export default EditUserForm;
