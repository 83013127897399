import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Loader from "../dashboard/loader";
import SimpleReactValidator from "simple-react-validator";
import PropTypes from "prop-types";
// import DateRangePicker from "react-bootstrap-daterangepicker";

// import "../../../node_modules/bootstrap/dist/css/bootstrap.css";
// import "../../../node_modules/bootstrap-css-only/css/bootstrap.css";
// import "../../../node_modules/bootstrap-daterangepicker/daterangepicker.css";
class AddQuizForm extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      quiz_name: "",
      category_name: "",
      start_date: "",
      end_date: "",
      is_required: false,
      IsLoading: false,
      IsSubmit: false,
    };
    this.validator = new SimpleReactValidator();
  }

  createQuiz(e) {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({ IsLoading: true, IsSubmit: true });
      this.props.submitQuiz(this.state);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  handlecheckboxChange(e) {
    this.setState({ [e.target.name]: e.target.checked });
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  render() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    const Todaydate = yyyy + "-" + mm + "-" + dd;
    return (
      <div>
        <Loader IsLoading={this.props.propsState.IsLoading} />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <h4 className="card-title">Add Quiz</h4>
                  <div className="col-md-6">
                    <form className="form-horizontal">
                      {/* <DateRangePicker
                        onEvent={this.handleEvent}
                        onCallback={this.handleCallback}
                      >
                        <input />
                      </DateRangePicker> */}

                      <div className="card-body">
                        <div className="form-group row">
                          <div className="col-sm-9">
                            <div className="form-check form-check-inline">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="is_required"
                                value={this.state.is_required}
                                name="is_required"
                                onChange={(e) => this.handlecheckboxChange(e)}
                              />
                            </div>
                            <label className="control-label col-form-label label-text">
                              Quiz Required
                            </label>
                            <label className="form-check-label"></label>
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-9" data-select2-id="11">
                            <label className="control-label col-form-label label-text">
                              Category Title <sup className="req-color">*</sup>
                            </label>
                            <select
                              className="select2 form-control custom-select select2-hidden-accessible"
                              data-select2-id="1"
                              aria-hidden="true"
                              name="category_name"
                              onChange={(e) => this.handleChange(e)}
                            >
                              <option value="">Select Category Title</option>
                              {this.props.category.length > 0
                                ? this.props.category.map((category, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={category.category_id}
                                      >
                                        {category.category_name}
                                      </option>
                                    );
                                  })
                                : ""}
                            </select>
                            {this.validator.message(
                              "Category Title",
                              this.state.category_name,
                              "required"
                            )}
                          </div>
                        </div>

                        <div className="form-group row">
                          <div className="col-sm-9">
                            <label
                              className="control-label col-form-label label-text"
                              htmlFor="quiz_name"
                            >
                              Quiz Title<sup className="req-color">*</sup>
                            </label>
                            <input
                              type="text"
                              className="form-control custom-input"
                              id="quiz_name"
                              placeholder="Enter Quiz Title"
                              value={this.state.quiz_name}
                              name="quiz_name"
                              onChange={(e) => this.handleChange(e)}
                            />
                            {this.validator.message(
                              "Quiz Title",
                              this.state.quiz_name,
                              "required|alpha_num_dash_space|min:2|max:100"
                            )}
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-9">
                            <label className="control-label col-form-label label-text">
                              Quiz Start date
                              {/* <sup className="req-color">*</sup> */}
                            </label>
                            <input
                              type="date"
                              className="form-control custom-input"
                              id="start_date"
                              placeholder="Enter start date"
                              value={this.state.start_date}
                              name="start_date"
                              min={Todaydate}
                              onChange={(e) => this.handleChange(e)}
                            />
                            {/* {this.validator.message(
                              "Start Date",
                              this.state.start_date,
                              "required"
                            )} */}
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-9">
                            <label className="control-label col-form-label label-text">
                              Quiz End date
                              {/* <sup className="req-color">*</sup> */}
                            </label>
                            <input
                              type="date"
                              className="form-control custom-input"
                              id="end_date"
                              placeholder="Enter End date"
                              value={this.state.end_date}
                              name="end_date"
                              min={this.state.start_date}
                              onChange={(e) => this.handleChange(e)}
                            />
                            {/* {this.validator.message(
                              "End Date",
                              this.state.end_date,
                              "required"
                            )} */}
                          </div>
                        </div>
                        <div className="">
                          <button
                            type="button"
                            className="btn btn-primary-newui btn-md-newui"
                            disabled={this.state.IsSubmit}
                            onClick={(e) => this.createQuiz(e)}
                          >
                            Save
                          </button>
                          <NavLink
                            to="/quizlist"
                            type="button"
                            className="btn btn-primary-newui btn-md-newui"
                          >
                            Cancel
                          </NavLink>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
AddQuizForm.propTypes = {
  submitQuiz: PropTypes.func,
  category: PropTypes.array,
};
export default AddQuizForm;
