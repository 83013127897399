import React from "react";
import logout from "./logout";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
const Topbar = (props) => {
  return (
    <header className="topbar" data-navbarbg="skin5">
      <nav className="navbar top-navbar navbar-expand-md navbar-dark">
        <div
          className={
            props.hovervalue ? "navbar-header expand-logo" : "navbar-header"
          }
          data-logobg="skin5"
        >
          <a className="nav-toggler waves-effect waves-light d-block d-md-none">
            <i className="ti-menu ti-close" />
          </a>
          <NavLink className="navbar-brand" to="/">
            <b className="logo-icon p-l-10">
              {
                <img
                  src="assets/images/logo-icon.png"
                  alt="homepage"
                  className="light-logo"
                />
              }
            </b>
            <span className="logo-text">
              <img
                src="assets/images/logo.png"
                alt="homepage"
                className="light-logo"
              />
            </span>
          </NavLink>

          <a
            className="topbartoggler d-block d-md-none waves-effect waves-light"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="ti-more" />
          </a>
        </div>

        <div
          className="navbar-collapse collapse"
          id="navbarSupportedContent"
          data-navbarbg="skin5"
        >
          <ul className="navbar-nav float-left mr-auto">
            <li className="nav-item d-none d-md-block">
              <a
                className="nav-link sidebartoggler waves-effect waves-light"
                data-sidebartype="mini-sidebar"
                onClick={() => props.NavStatus(props.status === 1 ? 0 : 1)}
              >
                <i className="mdi mdi-menu font-24" />
              </a>
            </li>
          </ul>

          <ul className="navbar-nav float-right">
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic"
                href=""
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  src="assets/images/users/1.jpg"
                  alt="user"
                  className="rounded-circle"
                  width="31"
                />
              </a>
              <div className="dropdown-menu dropdown-menu-right user-dd animated">
                {/* <a className="dropdown-item" href="javascript:void(0)">
                    <i className="ti-user m-r-5 m-l-5" /> My Profile
                  </a> */}

                <NavLink to="/changepassword" className="dropdown-item">
                  <i className="ti-settings m-r-5 m-l-5" /> Change Password
                </NavLink>

                <a className="dropdown-item" onClick={() => logout(props)}>
                  <i className="fa fa-power-off m-r-5 m-l-5" /> Logout
                </a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};
Topbar.propTypes = {
  status: PropTypes.number,
};
export default Topbar;
