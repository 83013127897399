import API from "../api";
import { GetError } from "../component/action";
export const GET_ALL_CATEGORY = "GetAllCategory";
export function GetAllCategory() {
  return async function (dispatch, getState) {
    await API.get("category/category_alladmin")
      .then((res) => {
        dispatch({ type: GET_ALL_CATEGORY, payload: res });
      })
      .catch(function (error) {
        let errorObj = GetError(error);
        if (errorObj.status === 400) {
          console.log(errorObj.data.message);
          // toast.error(errorObj.data.message);
        } else if (errorObj.status === 401) {
          console.log(errorObj.data.message);
          // toast.error(errorObj.data.message);
        } else {
          console.log(errorObj.data.message);
          // toast.error("Something went wrong on server end.");
        }
      });
  };
}
