import React, { Component } from 'react';
import UsersReportsCustom from '../../component/reports/users-report-custom';
import API from '../../api';
import { toast } from 'react-toastify';
import { GetError } from '../../component/action';
import { connect } from 'react-redux';
import logout from '../../component/dashboard/logout';
import { GetAllCategory } from '../../action/category-action';

class UserReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reports_data: [],
      excel_data: [],
      page_size: 10,
      resultCount: null,
      noOfPages: 1,
      currentPage: 1,
      lastPage: 0,
      prevPage: null,
      nextPage: null,
      pagination: '',
      searchTerm: '',
      sortName: 'fullname',
      sortOrder: 'asc',
      IsLoading: false,
      modalIsOpen: false,
      modelContent: '',
      modelContentType: '',
      quizlistoption: [],
      filter_user_type: '',
      filter_category: 0,
      isLoadingExport: false
    };
    this.onClick = this.exportReport.bind(this);
  }
  componentWillMount() {
    this.props.dispatch(GetAllCategory());
    this.loadReports();
  }
  loadReports() {
    let self = this;
    this.setState({
      isLoading: true,
      reports_data: [],
      excel_data: [],
      resultCount: null,
      noOfPages: 1,
      nextPage: null,
      prevPage: null,
      lastPage: 0
    });

    setTimeout(() => {
      API.get(
        `dashboard/user_quiz_report/${
          this.state.filter_user_type ? this.state.filter_user_type : 0
        }/${
          this.state.filter_category ? this.state.filter_category : 0
        }?order_by=${this.state.sortName}&order=${this.state.sortOrder}&limit=${
          this.state.page_size
        }&searchTerm=${this.state.searchTerm}&page=${this.state.currentPage}`
      )
        .then(res => {
          if (res.status === 200) {
            let nextUrl =
              res.data.data.nextlink !== '' ? res.data.data.nextlink : null;
            let prevUrl =
              res.data.data.prevlink !== ''
                ? res.data.data.prevlink === ''
                  ? 1
                  : res.data.data.prevlink
                : null;
            this.setState({
              reports_data: res.data.data.report_data,
              resultCount: res.data.data.total_rows,
              isLoading: false,
              noOfPages: Math.ceil(
                res.data.data.total_rows / this.state.page_size
              ),
              nextPage: nextUrl,
              prevPage: prevUrl,
              lastPage: Math.ceil(
                res.data.data.total_rows / this.state.page_size
              )
            });
          }
        })
        .catch(function(erorr) {
          let errorobj = GetError(erorr);
          self.setState({
            isLoading: false,
            reports_data: [],
            excel_data: []
          });
          if (errorobj.status === 400) {
            if (self.state.reports_data.length > 0) {
              toast.error(errorobj.data.message);
            }
          } else if (errorobj.status === 403) {
            toast.error(errorobj.data.message);
            setTimeout(function() {
              logout(self.props);
            }, 1000);
          } else {
            toast.error('Something went wrong on server end.');
          }
        });
    }, 100);
  }
  handleSort = (sortName, prevSortOrder) => {
    let sortOrder = prevSortOrder === 'asc' ? 'desc' : 'asc';
    this.setState({ sortName, sortOrder }, () => {
      this.loadReports();
    });
  };
  handleChange = event => {
    this.setState(
      {
        [event.target.name]: event.target.value,
        currentPage: 1,
        prevPage: null,
        nextPage: this.state.noOfPages > 1 ? 2 : null
      },
      () => {
        this.loadReports();
      }
    );
  };
  gotoPage = (page, totalPages) => {
    if (page && page !== this.state.currentPage) {
      this.setState(
        {
          currentPage: page
        },
        () => {
          this.loadReports();
        }
      );
    }
  };

  exportReport = event => {
    let self = this;
    this.setState({
      isLoadingExport: true,
    });
    setTimeout(() => {
      API.get(
        `dashboard/user_quiz_report/${
          this.state.filter_user_type ? this.state.filter_user_type : 0
        }/${
          this.state.filter_category ? this.state.filter_category : 0
        }?order_by=${this.state.sortName}&order=${this.state.sortOrder}&limit=${
          this.state.page_size
        }&searchTerm=${this.state.searchTerm}&export_report=1`
      )
        .then(res => {
          if (res.status === 200) {
            let url = res.data.excel_url;
            let a = document.createElement('a');
            a.href = url;
            a.download = res.data.file_name;
            a.click();
            this.setState({
              isLoadingExport: false
            });
          }
        })
        .catch(function(erorr) {
          let errorobj = GetError(erorr);
          self.setState({
            isLoadingExport: false
          });

          if (errorobj.status === 400) {
            if (self.state.reports_data.length > 0) {
              toast.error(errorobj.data.message);
            }
          } else if (errorobj.status === 401) {
            toast.error(errorobj.data.message);
            setTimeout(function() {
              logout(this.props);
            }, 2000);
          } else if (errorobj.status === 403) {
            toast.error(errorobj.data.message);
            setTimeout(function() {
              logout(self.props);
            }, 2000);
          } else {
            toast.error('Something went wrong on server end.');
          }
        });
    }, 100);


  };

  render() {
    return (
      <UsersReportsCustom
        propsState={this.state}
        gotoPage={this.gotoPage}
        handleChange={this.handleChange}
        handleSort={this.handleSort}
        category={this.props.load_category}
        props={this.props}
        exportReport={this.exportReport}
      />
    );
  }
  
}

const mapStateToProps = (state, props) => {
  return {
    load_category: state.LoadCategory
  };
};

export default connect(mapStateToProps)(UserReports);
