import React from "react";
import { NavLink } from "react-router-dom";
const baseURL = "/";
let serverURL = "";
let assetsURL = "";
let awsURL = "https://inteleos-galaxy-env.s3.amazonaws.com/upload/";

var getLocation = function (href) {
  var l = document.createElement("a");
  l.href = href;
  return l;
};
var l = getLocation(window.location.href);
//var ls = getLocation('https://ardms.galaxyweblinks.com/api/v2/');
if (l.hostname == "localhost") {
  serverURL = "http://localhost/ardms/api/v4/";
  assetsURL = "http://localhost/ardms/";
} else if (l.hostname == "54.161.175.238") {
  serverURL = "http://54.161.175.238/api/v4/";
  assetsURL = "http://54.161.175.238/";
} else if (l.hostname == "www.ardmsskills.org") {
  serverURL = "https://www.ardmsskills.org/api/v4/";
  assetsURL = "https://www.ardmsskills.org/";
} else if (l.hostname == "ardms-alb-1266696488.us-east-1.elb.amazonaws.com") {
  serverURL =
    "https://ardms-alb-1266696488.us-east-1.elb.amazonaws.com/api/v4/";
  assetsURL = "https://ardms-alb-1266696488.us-east-1.elb.amazonaws.com/";
} else if (l.hostname == "ardms-server-load-test.galaxyweblinks.com") {
  serverURL = "https://ardms-server-load-test.galaxyweblinks.com/api/v4/";
  assetsURL = "https://ardms-server-load-test.galaxyweblinks.com/";
} else if (l.hostname == "ardmsskills.galaxysofttech.co.in") {
  serverURL = "http://ardmsskills.galaxysofttech.co.in/api/v4/";
  assetsURL = "http://ardmsskills.galaxysofttech.co.in/";  
} else {
  serverURL = "http://php74.gwl.com/ardms/api/v4/";
  assetsURL = "http://php74.gwl.com/ardms/";
}

let user = localStorage.getItem("userdata");
const userData = user ? JSON.parse(user) : "";
const authToken = localStorage.getItem("token");
const appVersion = "1.1";

const GetError = function getError(error) {
  if (error.response) {
    let response = {
      type: "response",
      status: error.response.status,
      headers: error.response.headers,
      data: error.response.data,
    };
    return response;
  } else if (error.request) {
    return { type: "request", request: error.request };
  } else {
    return { type: "message", message: error.message };
  }
};

const QuestionRequired = function QuestionRequired(value) {
  if (
    value.question === "" &&
    value.questionImages.length === 0 &&
    value.questionVideos.length === 0 &&
    value.questionpdf.length === 0
  ) {
    return false;
  } else {
    return true;
  }
};
const TextAnswerRequried = function TextAnswerRequried(value) {
  var filtered = value.optionTexts.filter(function (el) {
    return el !== "";
  });
  
  if (value.category_id === "9") {
    if (value.question_type === "2") {
      return true;
    } else if (value.optionTextContent && filtered.length < 2) {
      return false;
    } else {
      return true;
    }

  } else {
    if (value.optionTextContent && filtered.length < 3) {
      return false;
    } else {
      return true;
    }

  }
};
const ImageAnswerRequried = function ImageAnswerRequried(value) {
  var filtered = value.optionImages.filter(function (el) {
    return el !== "";
  });
  if (value.category_id === "9") {
    if (value.optionImageMedia && filtered.length < 2) {
      return false;
    } else {
      return true;
    }
  } else {
    if (value.optionImageMedia && filtered.length < 3) {
      return false;
    } else {
      return true;
    }
  }
};
const VideoAnswerRequried = function VideoAnswerRequried(value) {
  var filtered = value.optionVideos.filter(function (el) {
    return el !== "";
  });
  if (value.category_id === "9") {
    if (value.optionVideoMedia && filtered.length < 2) {
      return false;
    } else {
      return true;
    }
  } else {
    if (value.optionVideoMedia && filtered.length < 3) {
      return false;
    } else {
      return true;
    }
  }
};
const PdfAnswerRequried = function PdfAnswerRequried(value) {
  var filtered = value.optionPdfs.filter(function (el) {
    return el !== "";
  });
  
  if (value.category_id === "9") {
    if (value.optionPdfMedia && filtered < 3) {
      return false;
    } else {
      return true;
    }
  } else {
    if (value.optionPdfMedia && filtered.length < 3) {
      return false;
    } else {
      return true;
    }
  }
};
const validImage = function validImage(value) {
  if (value.explantion_Image !== "" && value.explantion_Image !== undefined) {
    if (/\.(jpe?g|png|PNG)$/i.test(value.explantion_Image.name) === false) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};
const validVideo = function validVideo(value) {
  if (value.explantion_video !== "" && value.explantion_video !== undefined) {
    if (/\.(mp4|MP4)$/i.test(value.explantion_video.name) === false) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};
const validPdf = function validPdf(value) {
  if (value.explantion_pdf !== "" && value.explantion_pdf !== undefined) {
    if (/\.(pdf)$/i.test(value.explantion_pdf.name) === false) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};
const validUrl = function validUrl(value) {
  var re =
    /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
  if (value.explantion_url !== "" && value.explantion_url !== undefined) {
    if (!re.test(value.explantion_url)) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};

const mediaSizeValidation = function mediaSizeValidation(value) {
  // 2MB
  var maxSizeup = 2097152;
  
  if(value > maxSizeup) {
    return true;
  } else {
    return false;
  }
  
};


const Pagination = function Pagination(props) {
  let Pages = Array(props.noOfPages).fill(null);
  let links =
    props.noOfPages > 2 && props.currentPage < 2 ? (
      <React.Fragment>
        <li
          className={
            props.currentPage === 1
              ? "paginate_button page-item active"
              : "paginate_button page-item"
          }
        >
          <NavLink to="#" onClick={() => props.gotoPage(1, props.noOfPages)}>
            1
          </NavLink>
        </li>
        <li
          className={
            props.currentPage === 2
              ? "paginate_button page-item active"
              : "paginate_button page-item"
          }
        >
          <NavLink to="#" onClick={() => props.gotoPage(2, props.noOfPages)}>
            2
          </NavLink>
        </li>
        {props.noOfPages > 3 ? (
          <li className="paginate_button page-item">...</li>
        ) : (
          ""
        )}

        <li
          className={
            props.currentPage === props.lastPage
              ? "paginate_button page-item active"
              : "paginate_button page-item"
          }
        >
          <NavLink
            to="#"
            onClick={() => props.gotoPage(props.lastPage, props.noOfPages)}
          >
            {props.lastPage}
          </NavLink>
        </li>
      </React.Fragment>
    ) : props.noOfPages <= 2 ? (
      Pages.map((key, index) => {
        return (
          <li
            className={
              props.currentPage === index + 1
                ? "paginate_button page-item active"
                : "paginate_button page-item"
            }
            key={index + 1}
          >
            <NavLink
              to="#"
              onClick={() => props.gotoPage(index + 1, props.noOfPages)}
            >
              {index + 1}
            </NavLink>
          </li>
        );
      })
    ) : props.currentPage >= 2 ? (
      <React.Fragment>
        {props.currentPage > 2 ? (
          <li
            className={
              props.currentPage === 1
                ? "paginate_button page-item active"
                : "paginate_button page-item"
            }
          >
            <NavLink to="#" onClick={() => props.gotoPage(1, props.noOfPages)}>
              1
            </NavLink>
          </li>
        ) : (
          ""
        )}
        {props.currentPage > 3 ? (
          <li className="paginate_button page-item">...</li>
        ) : (
          ""
        )}

        <li
          className={
            props.currentPage === props.currentPage - 1
              ? "paginate_button page-item active"
              : "paginate_button page-item"
          }
        >
          <NavLink
            to="#"
            onClick={() =>
              props.gotoPage(props.currentPage - 1, props.noOfPages)
            }
          >
            {props.currentPage - 1}
          </NavLink>
        </li>
        <li
          className={
            props.currentPage === props.currentPage
              ? "paginate_button page-item active"
              : "paginate_button page-item"
          }
        >
          <NavLink
            to="#"
            onClick={() => props.gotoPage(props.currentPage, props.noOfPages)}
          >
            {props.currentPage}
          </NavLink>
        </li>
        {props.currentPage < props.noOfPages ? (
          <li
            className={
              props.currentPage === props.currentPage + 1
                ? "paginate_button page-item active"
                : "paginate_button page-item"
            }
          >
            <NavLink
              to="#"
              onClick={() =>
                props.gotoPage(props.currentPage + 1, props.noOfPages)
              }
            >
              {props.currentPage + 1}
            </NavLink>
          </li>
        ) : (
          ""
        )}

        {props.currentPage + 2 >= props.noOfPages ? (
          ""
        ) : (
          <li className="paginate_button page-item">...</li>
        )}
        {props.currentPage + 1 >= props.noOfPages ? (
          ""
        ) : (
          <li
            className={
              props.currentPage === props.lastPage
                ? "paginate_button page-item active"
                : "paginate_button page-item"
            }
          >
            <NavLink
              to="#"
              onClick={() => props.gotoPage(props.lastPage, props.noOfPages)}
            >
              {props.lastPage}
            </NavLink>
          </li>
        )}
      </React.Fragment>
    ) : (
      ""
    );
  return (
    <ul className="pagination">
      <li
        className={
          props.prevPage == null
            ? "paginate_button page-item previous disabled"
            : "paginate_button page-item previous"
        }
      >
        <NavLink
          to="#"
          onClick={() => props.gotoPage(props.prevPage, props.noOfPages)}
        >
          Prev
        </NavLink>
      </li>
      {links}
      <li
        className={
          props.nextPage == null
            ? "paginate_button page-item next disabled"
            : "paginate_button page-item next"
        }
      >
        <NavLink
          to="#"
          onClick={() => props.gotoPage(props.nextPage, props.noOfPages)}
        >
          Next
        </NavLink>
      </li>
    </ul>
  );
};
const HandleError = (props) => {
  const { ...rest } = props;
};
export {
  baseURL,
  serverURL,
  GetError,
  userData,
  authToken,
  appVersion,
  assetsURL,
  awsURL,
  QuestionRequired,
  TextAnswerRequried,
  ImageAnswerRequried,
  VideoAnswerRequried,
  PdfAnswerRequried,
  validImage,
  validVideo,
  validPdf,
  validUrl,
  Pagination,
  mediaSizeValidation
};