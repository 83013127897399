import React, { Component } from "react";
import AddUserForm from "../../component/users/add-user-form";
import API from "../../api";
import { toast } from "react-toastify";
import { GetError } from "../../component/action";
import logout from "../../component/dashboard/logout";
class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Issubmit: false,
      isLoad: false
    };
  }

  SubmitUser = (e, data) => {
    this.setState({
      isLoad: true,
      Issubmit: true
    });
    let self = this;
    let userFormData = new FormData();
    userFormData.append("first_name", data.firstname);
    userFormData.append("last_name", data.lastname);
    userFormData.append("email", data.email);
    userFormData.append("password", data.password);
    userFormData.append("ardms_id", data.armdsid);

    API.post("user/registration", userFormData)
      .then(res => {
        if (res.data.status === 200) {
          this.setState({
            Issubmit: false,
            isLoad: false
          });

          this.props.history.push("/userslist");
          toast.success(res.data.message);
        } else {
          toast.error("Something went wrong on server end.");
        }
      })
      .catch(function(error) {
        var errorr = GetError(error);
        if (errorr.status === 400) {
          toast.error(errorr.data.message);
        } else if (errorr.data.status === 401) {
          toast.error(errorr.data.message);
          setTimeout(function() {
            logout(this.props);
          }, 2000);
        } else {
          toast.error("Something went wrong on server end.");
        }
        self.setState({
          Issubmit: false,
          isLoad: false
        });
      });
  };
  render() {
    return <AddUserForm SubmitUser={this.SubmitUser} propsState={this.state} />;
  }
}

export default AddUser;
