import React from "react";
import Loader from "../dashboard/loader";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { NavLink } from "react-router-dom";
import { Pagination } from "../action";
import PropTypes from "prop-types";

const QuizListCustom = (props) => {
  return (
    <div>
      <Loader isLoading={props.propsState.isLoading} />
      <div className="page-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <h5 className="card-title m-b-0 pl20">Manage Quiz</h5>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4" data-select2-id="11">
                      <b className="filter_head">Filter by category title</b>
                      <select
                        className="select2 form-control custom-select select2-hidden-accessible"
                        data-select2-id="1"
                        aria-hidden="true"
                        name="filter_category_name"
                        onChange={(e) => props.handleChange(e)}
                      >
                        <option value="">Select category title</option>
                        {props.category.length > 0
                          ? props.category.map((category, index) => {
                              return (
                                <option
                                  key={index}
                                  value={category.category_id}
                                >
                                  {category.category_name}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-sm-12 col-md-6">
                      <div
                        className="dataTables_length"
                        id="zero_config_length"
                      >
                        <label className="table-label">
                          <span className="table-span">Show entries</span>
                          <select
                            name="page_size"
                            aria-controls="zero_config"
                            className="form-control form-control-sm"
                            value={props.propsState.page_size}
                            onChange={(e) => props.handleChange(e)}
                          >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 text-right">
                      <div
                        id="zero_config_filter"
                        className="dataTables_filter"
                      >
                        <label className="text-left">
                          <span>Search:</span>
                          <input
                            type="search"
                            className="form-control form-control-sm"
                            placeholder="Search"
                            aria-controls="zero_config"
                            name="searchTerm"
                            id="searchTerm"
                            value={props.propsState.searchTerm}
                            onChange={(e) => props.handleChange(e)}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table id="zero_config" className="table">
                      <thead>
                        <tr>
                          <th
                            width={"10%"}
                            onClick={() =>
                              props.handleSort(
                                "category_id",
                                props.propsState.sortOrder
                              )
                            }
                          >
                            Category Title
                            {props.propsState.sortName === "category_id" ? (
                              <span className="sort-active">
                                {props.propsState.sortOrder === "asc" ? (
                                  <i className="fas fa-sort-amount-up" />
                                ) : (
                                  <i className="fas fa-sort-amount-down" />
                                )}
                              </span>
                            ) : (
                              <span className="sort-inactive">
                                <i className="fas fa-sort-amount-up" />
                              </span>
                            )}
                          </th>
                          <th
                            width={"45%"}
                            onClick={() =>
                              props.handleSort(
                                "quiz_name",
                                props.propsState.sortOrder
                              )
                            }
                          >
                            Quiz Title
                            {props.propsState.sortName === "quiz_name" ? (
                              <span className="sort-active">
                                {props.propsState.sortOrder === "asc" ? (
                                  <i className="fas fa-sort-amount-up" />
                                ) : (
                                  <i className="fas fa-sort-amount-down" />
                                )}
                              </span>
                            ) : (
                              <span className="sort-inactive">
                                <i className="fas fa-sort-amount-up" />
                              </span>
                            )}
                          </th>
                          <th
                            width={"7%"}
                            onClick={() =>
                              props.handleSort(
                                "is_required",
                                props.propsState.sortOrder
                              )
                            }
                          >
                            Required
                            {props.propsState.sortName === "is_required" ? (
                              <span className="sort-active">
                                {props.propsState.sortOrder === "asc" ? (
                                  <i className="fas fa-sort-amount-up" />
                                ) : (
                                  <i className="fas fa-sort-amount-down" />
                                )}
                              </span>
                            ) : (
                              <span className="sort-inactive">
                                <i className="fas fa-sort-amount-up" />
                              </span>
                            )}
                          </th>
                          <th
                            className="text-center"
                            width={"10%"}
                            onClick={() =>
                              props.handleSort(
                                "added_by",
                                props.propsState.sortOrder
                              )
                            }
                          >
                            Added By
                            {props.propsState.sortName === "added_by" ? (
                              <span className="sort-active">
                                {props.propsState.sortOrder === "asc" ? (
                                  <i className="fas fa-sort-amount-up" />
                                ) : (
                                  <i className="fas fa-sort-amount-down" />
                                )}
                              </span>
                            ) : (
                              <span className="sort-inactive">
                                <i className="fas fa-sort-amount-up" />
                              </span>
                            )}
                          </th>

                          <th width={"10%"} className="text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.propsState.isLoading === false ? (
                          props.propsState.quiz_data.length > 0 ? (
                            props.propsState.quiz_data.map((quiz, index) => (
                              <tr key={index} id={quiz.quiz_id}>
                                <td>{quiz.category_name}</td>
                                <td>{quiz.quiz_name}</td>
                                <td>
                                  {quiz.is_required == "1" ? "Yes" : "No"}
                                </td>
                                <td>{quiz.user_name}</td>
                                <td className="center">
                                  <div>
                                    <button
                                      className={
                                        quiz.status === "1"
                                          ? "btn btn-success btn-sm"
                                          : "btn btn-dark btn-sm"
                                      }
                                      onClick={() =>
                                        props.changeStatus(
                                          quiz.quiz_id,
                                          quiz.status === "1" ? "2" : "1"
                                        )
                                      }
                                    >
                                      <i
                                        className={
                                          quiz.status === "1"
                                            ? "fas fa-check"
                                            : "fas fa-times"
                                        }
                                      />
                                    </button>

                                    <NavLink to={`/editquiz/${quiz.quiz_id}`}>
                                      <button className="btn btn-primary btn-sm">
                                        <i className="fas fa-pencil-alt" />
                                      </button>
                                    </NavLink>
                                    <button
                                      className="btn btn-danger btn-sm"
                                      onClick={() =>
                                        props.removeQuiz(quiz.quiz_id)
                                      }
                                    >
                                      <i className="fas fa-trash-alt" />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                className="dataTables_empty text-center"
                                colSpan="4"
                              >
                                No matching records found
                              </td>
                            </tr>
                          )
                        ) : (
                          <tr>
                            <td
                              className="dataTables_empty text-center"
                              colSpan="4"
                            >
                              Loading...
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="row mt-4">
                    <div className="col-sm-12 col-md-5">
                      <div
                        className="dataTables_info"
                        id="zero_config_info"
                        role="status"
                        aria-live="polite"
                      >
                        {props.propsState.isLoading === true
                          ? ""
                          : props.propsState.quiz_data.length === 0
                          ? `Showing 0 of 0 entries`
                          : `Showing  
                            ${
                              props.propsState.quiz_data.length <
                              props.propsState.page_size
                                ? props.propsState.resultCount
                                : parseInt(
                                    props.propsState.quiz_data.length *
                                      props.propsState.currentPage
                                  )
                            }
                            of ${props.propsState.resultCount} entries`}
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-7">
                      <div
                        className="dataTables_paginate paging_simple_numbers float-right"
                        id="zero_config_paginate"
                      >
                        {props.propsState.quiz_data.length > 0 ? (
                          <Pagination
                            noOfPages={props.propsState.noOfPages}
                            currentPage={props.propsState.currentPage}
                            nextPage={props.propsState.nextPage}
                            prevPage={props.propsState.prevPage}
                            lastPage={props.propsState.lastPage}
                            gotoPage={props.gotoPage}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

QuizListCustom.propTypes = {
  removeQuiz: PropTypes.func,
  changeStatus: PropTypes.func,
  handleSort: PropTypes.func,
  handleChange: PropTypes.func,
  gotoPage: PropTypes.func,
  category: PropTypes.array,
};

export default QuizListCustom;
