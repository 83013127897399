import React, { Component } from "react";
import { connect } from "react-redux";
import { LoginUser } from "../../action/user-action";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import { GetError } from "../action";
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = { email: "", password: "", Issubmit: false, IsLogin: false };
    this.validator = new SimpleReactValidator();
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  loginuser = (e) => {
    let self = this;
    e.preventDefault();
    this.setState({
      Issubmit: true,
    });

    if (this.validator.allValid()) {
      let logindata = new FormData();
      logindata.append("email", this.state.email);
      logindata.append("password", this.state.password);
      logindata.append("user_type", 1);
      // let response = this.props.login(logindata, this.props.history);
      this.props
        .login(logindata)
        .then((res) => {
          this.setState({
            Issubmit: false,
            IsLogin: true,
          });
          // console.log(res);
          // toast.success(res.data.message);
          this.props.history.push("/categorylist");
        })
        .catch(function (error) {
          let errorobj = GetError(error);
          if (errorobj.status === 400) {
            toast.error(errorobj.data.message);
          } else {
            // toast.error("Something went wrong on server end.");
          }
          self.setState({
            Issubmit: false,
          });
        });
    } else {
      this.setState({
        Issubmit: false,
      });
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  componentWillUnmount() {
    this._isMounted = false;
  }
  render() {
    return (
      <div className="auth-wrapper d-flex no-block justify-content-center align-items-center">
        <div className="auth-box ">
          <div id="loginform">
            <div className="text-center p-t-20 p-b-20">
              <span className="db">
                <img src="assets/images/logo.png" alt="logo" />
              </span>
            </div>

            <form
              className="form-horizontal m-t-20"
              id="loginform"
              action="index.html"
            >
              <div className="row p-b-30">
                <div className="col-12">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text bg-success text-white"
                        id="basic-addon1"
                      >
                        <i className="ti-user" />
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      placeholder="Email"
                      aria-label="Email"
                      aria-describedby="basic-addon1"
                      value={this.state.email}
                      onChange={(e) => this.handleChange(e)}
                      name="email"
                    />
                  </div>
                  {this.validator.message(
                    "Email",
                    this.state.email,
                    "required|email"
                  )}
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text bg-warning text-white"
                        id="basic-addon2"
                      >
                        <i className="ti-pencil" />
                      </span>
                    </div>
                    <input
                      type="password"
                      className="form-control form-control-lg"
                      placeholder="Password"
                      aria-label="Password"
                      aria-describedby="basic-addon1"
                      value={this.state.password}
                      onChange={(e) => this.handleChange(e)}
                      name="password"
                    />
                  </div>
                  {this.validator.message(
                    "Password",
                    this.state.password,
                    "required"
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <div className="">
                      {/* <button
                        className="btn btn-info"
                        id="to-recover"
                        type="button"
                      >
                        <i className="fa fa-lock m-r-5" /> Lost password?
                      </button> */}
                      <button
                        style={{
                          fontSize: "14px",
                          color: "#444",
                          letterSpacing: "1px",
                        }}
                        className="btn btn-white btn-md float-right"
                        type="submit"
                        disabled={this.state.Issubmit}
                        onClick={(e) => this.loginuser(e)}
                      >
                        Login
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateProps = (state, props) => {
  return {
    userdata: state.UserAction,
  };
};

const mapActionToPros = {
  login: LoginUser,
};

export default connect(mapStateProps, mapActionToPros)(Login);
