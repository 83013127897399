import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../dashboard/loader";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Pagination, awsURL } from "../action";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
class CategoryListCustom extends Component {
  render() {
    return (
      <div>
        <Loader isLoading={this.props.propsState.isLoading} />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <h5 className="card-title m-b-0 pl20">Manage Category</h5>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div
                          className="dataTables_length"
                          id="zero_config_length"
                        >
                          <label className="table-label">
                            <span className="table-span">Show entries</span>
                            <select
                              name="page_size"
                              aria-controls="zero_config"
                              className="form-control form-control-sm"
                              value={this.props.propsState.page_size}
                              onChange={(e) => this.props.handleChange(e)}
                            >
                              <option value="10">10</option>
                              <option value="25">25</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                            </select>
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 text-right">
                        <div
                          id="zero_config_filter"
                          className="dataTables_filter"
                        >
                          <label className="text-left">
                            <span>Search:</span>
                            <input
                              type="search"
                              className="form-control form-control-sm"
                              placeholder="Search"
                              aria-controls="zero_config"
                              name="searchTerm"
                              id="searchTerm"
                              value={this.props.propsState.searchTerm}
                              onChange={(e) => this.props.handleChange(e)}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table id="zero_config" className="table">
                        <thead>
                          <tr>
                            <th
                              width={"80%"}
                              onClick={() =>
                                this.props.handleSort(
                                  "category_name",
                                  this.props.propsState.sortOrder
                                )
                              }
                            >
                              Category Title
                              {this.props.propsState.sortName ===
                                "category_name" ? (
                                <span className="sort-active">
                                  {this.props.propsState.sortOrder === "asc" ? (
                                    <i className="fas fa-sort-amount-up" />
                                  ) : (
                                    <i className="fas fa-sort-amount-down" />
                                  )}
                                </span>
                              ) : (
                                <span className="sort-inactive">
                                  <i className="fas fa-sort-amount-up" />
                                </span>
                              )}
                            </th>
                            <th width={"10%"} className='text-center'>Icon</th>
                            <th width={"10%"} className='text-center'>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.propsState.isLoading === false ? (
                            this.props.propsState.category_data.length > 0 ? (
                              this.props.propsState.category_data.map(
                                (category, index) => (
                                  <tr key={index} id={category.category_id}>
                                    <td>{category.category_name}</td>
                                    <td className='text-center'>
                                      {/*<img
                                        src={`${awsURL}cat-image/${category.category_image}`}
                                        alt=""
                                        className="fixed_img"
                                        height="90px"
                                        width="90px"
                                      />*/}
                                      <img
                                        src={category.category_image}
                                        alt=""
                                        className="fixed_img"
                                        height="90px"
                                        width="90px"
                                      />
                                    </td>

                                    <td className='text-center' width="200">
                                      <div>
                                        <ReactTooltip />
                                        <button
                                          data-tip="Status"
                                          data-place="top"
                                          data-effect="solid"
                                          className={
                                            category.status === "1"
                                              ? "btn btn-success btn-sm"
                                              : "btn btn-dark btn-sm"
                                          }
                                          onClick={() =>
                                            this.props.changeStatus(
                                              category.category_id,
                                              category.status === "1"
                                                ? "2"
                                                : "1"
                                            )
                                          }
                                        >
                                          <i
                                            className={
                                              category.status === "1"
                                                ? "fas fa-check"
                                                : "fas fa-times"
                                            }
                                          />
                                        </button>

                                        <NavLink
                                          to={`/editcategory/${category.category_id}`}
                                        >
                                          <ReactTooltip />
                                          <button
                                            className="btn btn-primary btn-sm"
                                            data-tip="Edit"
                                            data-place="top"
                                            data-effect="solid"
                                          >
                                            <i className="fas fa-pencil-alt" />
                                          </button>
                                        </NavLink>
                                        {/* <button
                                          className="btn btn-danger btn-sm"
                                          onClick={() =>
                                            this.props.removeCategory(
                                              category.category_id
                                            )
                                          }
                                        >
                                          <i className="fas fa-trash-alt" />
                                        </button> */}
                                      </div>
                                    </td>
                                  </tr>
                                )
                              )
                            ) : (
                              <tr>
                                <td
                                  className="dataTables_empty text-center"
                                  colSpan="4"
                                >
                                  No matching records found
                                </td>
                              </tr>
                            )
                          ) : (
                            <tr>
                              <td
                                className="dataTables_empty text-center"
                                colSpan="4"
                              >
                                Loading...
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="row mt-4">
                      <div className="col-sm-12 col-md-5">
                        <div
                          className="dataTables_info"
                          id="zero_config_info"
                          role="status"
                          aria-live="polite"
                        >
                          {this.props.propsState.isLoading === true
                            ? ""
                            : this.props.propsState.category_data.length === 0
                              ? `Showing 0 of 0 entries`
                              : `Showing  
                                ${this.props.propsState.category_data.length <
                                this.props.propsState.page_size
                                ? this.props.propsState.resultCount
                                : parseInt(
                                  this.props.propsState.category_data
                                    .length *
                                  this.props.propsState.currentPage
                                )
                              }
                                of ${this.props.propsState.resultCount
                              } entries`}
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <div
                          className="dataTables_paginate paging_simple_numbers float-right"
                          id="zero_config_paginate"
                        >
                          {this.props.propsState.category_data.length > 0 ? (
                            <Pagination
                              noOfPages={this.props.propsState.noOfPages}
                              currentPage={this.props.propsState.currentPage}
                              nextPage={this.props.propsState.nextPage}
                              prevPage={this.props.propsState.prevPage}
                              lastPage={this.props.propsState.lastPage}
                              gotoPage={this.props.gotoPage}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
CategoryListCustom.propTypes = {
  removeCategory: PropTypes.func,
  changeStatus: PropTypes.func,
  handleChange: PropTypes.func,
  handleSort: PropTypes.func,
  gotoPage: PropTypes.func,
};
export default CategoryListCustom;
