import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import PropTypes from 'prop-types';
import Loader from '../dashboard/loader';
class AddUserForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: '',
      lastname: '',
      email: '',
      armdsid: ''
    };
    this.validator = new SimpleReactValidator();
  }

  SubmitUser(e) {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.props.SubmitUser(e, this.state);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  handlechange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  render() {
    return (
      <div>
        <Loader isLoading={this.props.propsState.isLoad} />
        <div className='page-wrapper'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='card'>
                  <h4 className='card-title'> Add User</h4>
                  <div className='col-md-10'>
                    <form className='form-horizontal'>
                      <div className='card-body'>
                        <div className='form-group row'>
                          <div className='col-sm-4'>
                            <label className='text-left control-label col-form-label label-text'>
                              First Name<sup className='req-color'>*</sup>
                            </label>
                            <input
                              type='text'
                              className='form-control custom-input'
                              id='fname'
                              value={this.state.firstname}
                              name='firstname'
                              onChange={e => this.handlechange(e)}
                              placeholder='Enter First Name'
                            />
                            {this.validator.message(
                              'First Name',
                              this.state.firstname,
                              'required|alpha_space|min:1|max:100'
                            )}
                          </div>

                          <div className='col-sm-4'>
                            <label className='control-label col-form-label label-text'>
                              Last Name<sup className='req-color'>*</sup>
                            </label>
                            <input
                              type='text'
                              className='form-control custom-input'
                              id='lname'
                              value={this.state.lastname}
                              name='lastname'
                              onChange={e => this.handlechange(e)}
                              placeholder='Enter Last Name'
                            />
                            {this.validator.message(
                              'Last Name',
                              this.state.lastname,
                              'required|alpha_space|min:2|max:100'
                            )}
                          </div>

                          <div className='col-sm-4'>
                            <label className='control-label col-form-label label-text'>
                              Email<sup className='req-color'>*</sup>
                            </label>
                            <input
                              type='email'
                              className='form-control custom-input'
                              id='email'
                              value={this.state.email}
                              name='email'
                              onChange={e => this.handlechange(e)}
                              placeholder='Enter Email Id'
                            />
                            {this.validator.message(
                              'Email',
                              this.state.email,
                              'required|email|min:2|max:100'
                            )}
                          </div>
                        </div>
                        <div className='form-group row'>
                          <div className='col-sm-4'>
                            <label className='control-label col-form-label label-text'>
                              ARDMS ID
                            </label>
                            <input
                              type='text'
                              className='form-control custom-input'
                              id='ardmsid'
                              placeholder='Enter ARDMS ID'
                              value={this.state.armdsid}
                              name='armdsid'
                              onChange={e => this.handlechange(e)}
                            />
                            {this.validator.message(
                              ' Ardms Id',
                              this.state.armdsid,
                              'required'
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='border-top'>
                        <div className='card-body'>
                          <button
                            type='submit'
                            onClick={e => this.SubmitUser(e)}
                            className='btn btn-primary-newui btn-md-newui'
                            disabled={this.props.propsState.Issubmit}
                          >
                            Save
                          </button>

                          <NavLink
                            to='/userslist'
                            type='button'
                            className='btn btn-primary-newui btn-md-newui'
                          >
                            Cancel
                          </NavLink>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
AddUserForm.propTypes = {
  SubmitUser: PropTypes.func
};
export default AddUserForm;
