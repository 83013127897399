import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../dashboard/loader";
// import Modal from "react-modal";
import SimpleReactValidator from "simple-react-validator";
import API from "../../api";
import AddExplantionForm from "./add-explantion-form";
import { connect } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import logout from "../dashboard/logout";
import { GetAllCategory } from "../../action/category-action";
import { GetAllQuiz } from "../../action/quiz-action";
import { truncateFileName } from "./questionUtils";
import { Prompt } from "react-router";
import Spinner from "../dashboard/spinner";
import { Modal } from "react-bootstrap";

import {
  GetError,
  assetsURL,
  QuestionRequired,
  TextAnswerRequried,
  ImageAnswerRequried,
  VideoAnswerRequried,
  PdfAnswerRequried,
  authToken,
  mediaSizeValidation
} from "../action";
import { FileSizeErrorMessage } from "../global";

function AddInput(props) {
  return (
    <div key={props.handleIndex} className="fieldWrapper">
      <label
        htmlFor="upload"
        className="btn btn-newui"
      >{`Upload ${props.label}`}</label>
      <input
        type="file"
        id={props.handleIndex}
        className={`form-control custom_file files_${props.handleIndex}`}
        name={props.name}
        onChange={props.handlefile}
      />
      {props.spinner === props.handleIndex ? (
        <Spinner isLoading={true}></Spinner>
      ) : (
        ""
      )}
      <div className="clear-1">&nbsp;</div>
      <span
        className="fileNameMedia"
        htmlFor="file_name"
        dangerouslySetInnerHTML={{
          __html: truncateFileName(props.filename),
        }}
      ></span>
    </div>
  );
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

class EditQuestion extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    localStorage.setItem("uploadSize", 0);

    this.state = {
      show: false,
      explanationArr: [],
      show1: false,
      quiz_status: "",
      modalIsOpen: false,
      modelContent: "",
      modelContentType: "",
      IsLoading: false,
      IsSubmit: false,
      quiz_name: "",
      question: "",
      questionMedia: false,
      questionMediaImage: false,
      questionMediaVideo: false,
      questionMediaPdf: false,
      // questionImages: "",
      // questionVideos: "",
      // questionpdf: "",
      questionImages: Array(5).fill(""),
      questionVideos: Array(5).fill(""),
      questionpdf: Array(5).fill(""),
      multipleImage: [],
      multipleVideo: [],
      multiplePdf: [],
      existquestionImages: "",
      existquestionVideos: "",
      existquestionpdf: "",
      existquestionImagesURL: "",
      existquestionVideosURL: "",
      existquestionpdfURL: "",
      optionTextContent: false,
      optionImageMedia: false,
      optionVideoMedia: false,
      optionPdfMedia: false,
      optionTexts: Array(3).fill(""),
      optionImages: Array(4).fill(""),
      optionVideos: Array(4).fill(""),
      optionPdfs: Array(4).fill(""),
      ptionImagesPath: Array(4).fill(""),
      optionVideosPath: Array(4).fill(""),
      optionPdfsPath: Array(4).fill(""),
      optionCorrectAnswer: Array(3).fill(""),
      optionImagesPreview: Array(4).fill(""),
      optionVideosPreview: Array(4).fill(""),
      correctAnswer: "",
      optionIds: Array(4).fill(""),
      optionType: 0,
      QustionError: true,
      TextAnswerRequried: true,
      ImageAnswerRequried: true,
      VideoAnswerRequried: true,
      PdfAnswerRequried: true,
      explanationObj: [],
      explanationType: {
        1: "Text",
        2: "Image",
        3: "Video",
        4: "Pdf",
        5: "Refrence url",
      },
      validImage_error: true,
      validVideo_error: true,
      validPdf_error: true,
      validUrl_error: true,
      explantionHtml: [],
      question_id: 0,
      existquestionImages_temp: "",
      category_id: "",
      questionImagesIn: Array(5).fill(""),
      questionVideosIn: Array(5).fill(""),
      questionpdfIn: Array(5).fill(""),

      optionImagesIn: Array(4).fill(""),
      optionVideosIn: Array(4).fill(""),
      optionPdfsIn: Array(4).fill(""),
      shouldBlockNavigation: false,
      mediaArr: Array(),
      questionLoaderImagesIn: Array(5).fill(""),
      questionLoaderVideosIn: Array(5).fill(""),
      questionLoaderpdfIn: Array(5).fill(""),
      optionLoaderImagesIn: Array(3).fill(""),
      optionLoaderVideosIn: Array(3).fill(""),
      optionLoaderpdfIn: Array(3).fill(""),
      isFormSubmit: false,
      uploadQuestionImageCount:"",
      uploadQuestionVideoCount:"",
      uploadQuestionPdfCount:"",
    };

    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      validators: {
        validImage: {
          message: "The element has allowed only jpg , jpeg , png  file type.",
          rule: (val, params, validator) => {
            let arr = ["jpg", "jpeg", "png", "PNG"];
            let flag = true;
            if (val.length > 0) {
              for (let i = 0; i < val.length; i++) {
                if (val[i].name !== "") {
                  let ext = /^.+\.([^.]+)$/.exec(val[i].name);
                  if (ext) {
                    if (arr.includes(ext[1])) {
                      //flag = true;
                    } else {
                      flag = false;
                    }
                  }
                }
              }
              return flag;
            } else {
              let ext = /^.+\.([^.]+)$/.exec(val.name);
              if (ext) {
                if (arr.includes(ext[1])) {
                  return true;
                } else {
                  return false;
                }
              }
            }
          },
        },
        validVideo: {
          message: "The element has allowed only mp4, MP4 file type.",
          rule: (val, params, validator) => {
            let arr = ["mp4", "MP4"];
            let flag = true;
            if (val.length > 0) {
              for (let i = 0; i < val.length; i++) {
                if (val[i].name !== "") {
                  let ext = /^.+\.([^.]+)$/.exec(val[i].name);
                  if (ext) {
                    if (arr.includes(ext[1])) {
                      //flag = true;
                    } else {
                      flag = false;
                    }
                  }
                }
              }
              return flag;
            } else {
              let ext = /^.+\.([^.]+)$/.exec(val.name);
              if (ext) {
                if (arr.includes(ext[1])) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return true;
              }
            }
          },
        },
        validPdf: {
          message: "The element has allowed only pdf file type.",
          rule: (val, params, validator) => {
            let arr = ["pdf"];
            let flag = true;
            if (val.length > 0) {
              for (let i = 0; i < val.length; i++) {
                if (val[i].name !== "") {
                  let ext = /^.+\.([^.]+)$/.exec(val[i].name);
                  if (ext) {
                    if (arr.includes(ext[1])) {
                      // flag = true;
                    } else {
                      flag = false;
                    }
                  }
                }
              }
              return flag;
            } else {
              let ext = /^.+\.([^.]+)$/.exec(val.name);
              if (ext) {
                if (arr.includes(ext[1])) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return true;
              }
            }
          },
        },
      },
    });
  }
  
  handleModal() {
    this.setState({ show: !this.state.show });
    this._isMounted = true;
    this.setState({}, () => {
      console.log(this.state);
      console.log("explanation", this.props.explanation);
    });
  }
  handleimageModal(e) {
    window.open(
      e,
      "PopupWindow",
      "width=600,height=600,scrollbars=yes,resizable=no"
    );
  }

  componentWillUnmount() {
    if (this.state.isFormSubmit === false) {
      if (this.state.mediaArr || this.props.explanation.length) {
        if (this.props.explanation.length) {
          for (const explantion of this.props.explanation) {
            if (
              explantion.explanation_type === "2" ||
              explantion.explanation_type === "3" ||
              explantion.explanation_type === "4"
            ) {
              let mediaformdataremove = new FormData();
              mediaformdataremove.append("filepath", explantion.url_media);
              API.post("quiz/remove_question_media", mediaformdataremove).then(
                (res) => { }
              );
            }
          }
        }

        if (this.state.mediaArr.length) {
          let mediaformdataremove = new FormData();
          mediaformdataremove.append("filepath", this.state.mediaArr);
          API.post("quiz/remove_question_media", mediaformdataremove).then(
            (res) => { }
          );
        }
      }
      this._isMounted = false;
    }
  }

  componentWillMount() {
    this.getQuestionDetail();
    this.props.dispatch({ type: "DEFAULT_STATE" });
    this.props.dispatch(GetAllCategory());
    this.props.dispatch(GetAllQuiz(this.state.category_id));
    this.getDefaultQuestionMultimedia();
  }
  getDefaultQuestionMultimedia() { }
  addExplantion = (e) => {
    this.setState({
      explantionHtml: [...this.state.explantionHtml, 1],
    });
  };
  validImage_error = (value) => {
    this.setState({
      validImage_error: value,
    });
  };
  validVideo_error = (value) => {
    this.setState({
      validVideo_error: value,
    });
  };
  validPdf_error = (value) => {
    this.setState({
      validPdf_error: value,
    });
  };
  validUrl_error = (value) => {
    this.setState({
      validUrl_error: value,
    });
  };
  getQuestionDetail() {
    let self = this;
    this.setState({
      IsLoading: true,
    });
    API.get(`quiz/question/${this.props.match.params.id}`)
      .then((res) => {
        if (res.status === 200) {

          let questionData = res.data.data.question;
          let question_option = res.data.data.question_option;
          let explanation = res.data.data.explanation;
          let optiontext = [];
          let optionimage = [];
          let optionvideo = [];
          let optionpdf = [];
          let optionimagePath = [];
          let optionvideoPath = [];
          let optionpdfPath = [];
          let option_ids = [];
          // let optionAnswer = [];
          let explantionHtmlArr = [];
          let count = 0;
          let answer = "";
          this.props.dispatch(GetAllQuiz(questionData.category_id));
         
          for (const option of question_option) {
            optiontext.push(option.option_description);
            /*optionimage.push(option.option_image);
            optionvideo.push(option.option_video);
            optionpdf.push(option.option_pdf); */
            optionimage.push(option.option_image_url);
            optionvideo.push(option.option_video_url);
            optionpdf.push(option.option_pdf_url);
            optionimagePath.push(option.option_image);
            optionvideoPath.push(option.option_video);
            optionpdfPath.push(option.option_pdf);
            if (option.is_correct_ans === "1") {
              answer = option.option_id;
            }
            option_ids.push(option.option_id);
            count++;
          }
          
          for (const explan of explanation) {
            explantionHtmlArr.push(1);
          }

          let filterText = optiontext.filter(function (el) {
            return el !== "";
          });
          let filterImage = optionimage.filter(function (el) {
            return el !== "";
          });
          let filterVideo = optionvideo.filter(function (el) {
            return el !== "";
          });
          let filterPdf = optionpdf.filter(function (el) {
            return el !== "";
          });
          let filteroption_ids = option_ids.filter(function (el) {
            return el !== "";
          });

          this.setState(
            {
              IsLoading: false,
              question_id: questionData.question_id,
              category_id: questionData.category_id,
              quiz_name: questionData.quiz_id,
              question: questionData.question_text,
              quiz_status: questionData.quiz_status,
              existquestionImages:
                questionData.question_image.length > 0
                  ? questionData.question_image
                  : Array(),
              existquestionVideos:
                questionData.question_video.length > 0
                  ? questionData.question_video
                  : Array(),
              existquestionpdf:
                questionData.question_pdf.length > 0
                  ? questionData.question_pdf
                  : Array(),
              existquestionImagesURL:
                questionData.question_image_url.length > 0
                  ? questionData.question_image_url
                  : Array(),
              existquestionVideosURL:
                questionData.question_video_url.length > 0
                  ? questionData.question_video_url
                  : Array(),
              existquestionpdfURL:
                questionData.question_pdf_url.length > 0
                  ? questionData.question_pdf_url
                  : Array(),        
              // questionImages: questionData.question_image.length > 0 ? questionData.question_image : Array(),
              // questionVideos: questionData.question_video.length > 0 ? questionData.question_video : Array(),
              // questionpdf: questionData.question_pdf.length > 0 ? questionData.question_image : Array(),
              // questionImages: [],
              // questionVideos: [],
              // questionpdf: [],
              questionMediaImage:
                questionData.question_image.length > 0 ? true : false,
              questionMediaVideo:
                questionData.question_video.length > 0 ? true : false,
              questionMediaPdf:
                questionData.question_pdf.length > 0 ? true : false,
              optionTexts:
                optiontext.length > 0 ? optiontext : Array(4).fill(""),
              optionImages:
                optionimage.length > 0 ? optionimage : Array(4).fill(""),
              optionVideos:
                optionvideo.length > 0 ? optionvideo : Array(4).fill(""),
              optionPdfs: optionpdf.length > 0 ? optionpdf : Array(4).fill(""),
              optionImagesPath:
                optionimagePath.length > 0 ? optionimagePath : Array(4).fill(""),
              optionVideosPath:
                optionvideoPath.length > 0 ? optionvideoPath : Array(4).fill(""),
              optionPdfsPath: optionpdfPath.length > 0 ? optionpdfPath : Array(4).fill(""),

              correctAnswer: answer,
              optionTextContent:
                filterText.length > 0
                  ? true
                  : filterImage.length > 0
                    ? false
                    : filterVideo.length > 0
                      ? false
                      : filterPdf.length > 0
                        ? false
                        : true,
              optionImageMedia: filterImage.length > 0 ? true : false,
              optionVideoMedia: filterVideo.length > 0 ? true : false,
              optionPdfMedia: filterPdf.length > 0 ? true : false,
              explanationObj: explanation,
              explantionHtml: explantionHtmlArr,
              optionIds: filteroption_ids,
              optionType:
                filterText.length > 0
                  ? 1
                  : filterImage.length > 0
                    ? 2
                    : filterVideo.length > 0
                      ? 3
                      : filterPdf.length > 0
                        ? 4
                        : 0,
            },
            () => {
              let existQuestionImage = [...this.state.existquestionImages];
              let existquestionVideos = [...this.state.existquestionVideos];
              let existquestionpdf = [...this.state.existquestionpdf];
              const multiplePdf = [...this.state.multiplePdf];
              const multipleVideo = [...this.state.multipleVideo];
              const multipleImages = [...this.state.multipleImage];
              const uploadQuestionImageCount = [...this.state.uploadQuestionImageCount];
              const uploadQuestionVideoCount = [...this.state.uploadQuestionVideoCount];
              const uploadQuestionPdfCount = [...this.state.uploadQuestionPdfCount];

              existQuestionImage.forEach((element, index) => {
                multipleImages.push(index);
              });
              
              this.setState({ multipleImage: multipleImages });
              this.setState({ uploadQuestionImageCount: existQuestionImage });
              
              existquestionVideos.forEach((element, index) => {
                multipleVideo.push(index);
              });
              
              this.setState({ multipleVideo: multipleVideo });
              this.setState({ uploadQuestionVideoCount: existquestionVideos });

              existquestionpdf.forEach((element, index) => {
                multiplePdf.push(index);
              });

              this.setState({ multiplePdf: [...multiplePdf] });
              this.setState({ uploadQuestionPdfCount: existquestionpdf });
            }
          );
  
             

        }
      })
      .catch(function (error) {
        let errorobj = GetError(error);
        if (errorobj.status === 400) {
          toast.error(errorobj.data.message);
        } else if (errorobj.status === 401) {
          toast.error(errorobj.data.message);
          setTimeout(
            function () {
              logout();
            }.bind(this),
            2000
          );
        } else {
          toast.error("Something went wrong on server end.");
        }
        self.setState({
          IsLoading: false,
        });
      });
  }
  handleChangeOption = (e, index) => {
    let optionTexts = this.state.optionTexts.slice();
    optionTexts[index] = e.target.value; // Update it with the modified email.
    this.setState({ optionTexts: optionTexts });
  };

  handleChange = (e) => {
    let targetName = e.target.name;
    let targetValue=e.target.value;
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        if (targetName === "category_id") {
          this.props.dispatch(GetAllQuiz(this.state.category_id));
        }
      }
    );

    if(targetValue == 9) {
      let optionTexts = this.state.optionTexts.slice(1);
      this.setState({ optionTexts: optionTexts });
    } 
    
  };
  handleMedia(e, InputType, index) {
    let media = e.target.files[0];
    let targetId = e.target.id;
    let mediaName = e.target.name;

    var isSubmitForm = "true";

    if (mediaName === "questionImages") {
      //check for file extension

      if (!media.name.match(/\.(jpg|jpeg|png|gif)$/)) {
        isSubmitForm = "false";
        toast.error("Allowed file types are: .jpg, .png, .gif, .jpeg ");
      }
    } else if (mediaName === "questionVideos") {
      if (!media.name.match(/\.(mp4|MP4)$/)) {
        isSubmitForm = "false";
        toast.error("Allowed file types are: .mp4 ");
      }
    } else if (mediaName === "questionpdf") {
      if (!media.name.match(/\.(pdf)$/)) {
        isSubmitForm = "false";
        toast.error("Allowed file types are: .pdf ");
      }
    }

    if(media.size != '') {
      let resultMedia = mediaSizeValidation(media.size);
      if(resultMedia) {
        e.target.value = null;
        isSubmitForm = "false";
        toast.error(FileSizeErrorMessage);
      }
    }

    if (isSubmitForm === "true") {
      let mediaformdata = new FormData();
      mediaformdata.append(e.target.name, media);
      if (this.state.questionImagesIn[index]) {
        let mediaformdataremove = new FormData();
        mediaformdataremove.append(
          "filepath",
          this.state.questionImagesIn[index]
        );
        API.post("quiz/remove_question_media", mediaformdataremove).then(
          (res) => { }
        );
        let filteredArray = this.state.mediaArr.filter(
          (item) => item !== this.state.questionImagesIn[index]
        );
        this.setState({ mediaArr: filteredArray });
      }

      if (
        mediaName === "questionVideos" &&
        this.state.questionVideosIn[index]
      ) {
        let mediaformdataremove = new FormData();
        mediaformdataremove.append(
          "filepath",
          this.state.questionVideosIn[index]
        );
        API.post("quiz/remove_question_media", mediaformdataremove).then(
          (res) => { }
        );
        let filteredArray = this.state.mediaArr.filter(
          (item) => item !== this.state.questionVideosIn[index]
        );
        this.setState({ mediaArr: filteredArray });
      }

      if (mediaName === "questionpdf" && this.state.questionpdfIn[index]) {
        let mediaformdataremove = new FormData();
        mediaformdataremove.append("filepath", this.state.questionpdfIn[index]);
        API.post("quiz/remove_question_media", mediaformdataremove).then(
          (res) => { }
        );
        let filteredArray = this.state.mediaArr.filter(
          (item) => item !== this.state.questionpdfIn[index]
        );
        this.setState({ mediaArr: filteredArray });
      }

      if (mediaName === "questionImages") {
        let questionLoaderImagesIn = this.state.questionLoaderImagesIn.slice();
        questionLoaderImagesIn[e.target.id] = index;
        this.setState({ questionLoaderImagesIn: questionLoaderImagesIn });
      } else if (mediaName === "questionVideos") {
        let questionLoaderVideosIn = this.state.questionLoaderVideosIn.slice();
        questionLoaderVideosIn[e.target.id] = index;
        this.setState({ questionLoaderVideosIn: questionLoaderVideosIn });
      } else if (mediaName === "questionpdf") {
        let questionLoaderpdfIn = this.state.questionLoaderpdfIn.slice();
        questionLoaderpdfIn[e.target.id] = index;
        this.setState({ questionLoaderpdfIn: questionLoaderpdfIn });
      }

      if (InputType === 1) {
        this.setState({
          existquestionImages_temp: URL.createObjectURL(e.target.files[0]),
        });
      }
      API.post("quiz/upload_question_media", mediaformdata)
        .then((res) => {
          if (res.status === 200) {
            if (mediaName === "questionImages") {
              let questionImagesIn = this.state.questionImagesIn.slice();
              questionImagesIn[targetId] = res.data.media_url;
              this.setState({ questionImagesIn: questionImagesIn });

              let questionImages = this.state.questionImages.slice();
              questionImages[targetId] = media;
              this.setState({ questionImages: questionImages });

              let questionLoaderImagesIn =
                this.state.questionLoaderImagesIn.slice();
              questionLoaderImagesIn[targetId] = "";
              this.setState({ questionLoaderImagesIn: questionLoaderImagesIn });
            }

            if (mediaName === "questionVideos") {
              let questionVideosIn = this.state.questionVideosIn.slice();
              questionVideosIn[targetId] = res.data.media_url;
              this.setState({ questionVideosIn: questionVideosIn });

              let questionVideos = this.state.questionVideos.slice();
              questionVideos[targetId] = media;
              this.setState({ questionVideos: questionVideos });

              let questionLoaderVideosIn =
                this.state.questionLoaderVideosIn.slice();
              questionLoaderVideosIn[targetId] = "";
              this.setState({ questionLoaderVideosIn: questionLoaderVideosIn });
            }

            if (mediaName === "questionpdf") {
              let questionpdfIn = this.state.questionpdfIn.slice();
              questionpdfIn[targetId] = res.data.media_url;
              this.setState({ questionpdfIn: questionpdfIn });

              let questionpdf = this.state.questionpdf.slice();
              questionpdf[targetId] = media;
              this.setState({ questionpdf: questionpdf });

              let questionLoaderpdfIn = this.state.questionLoaderpdfIn.slice();
              questionLoaderpdfIn[targetId] = "";
              this.setState({ questionLoaderpdfIn: questionLoaderpdfIn });
            }

            this.setState({
              mediaArr: [...this.state.mediaArr, res.data.media_url],
            });

            toast.success(res.data.message);
          } else {
            this.setState({
              IsLoading: false,
            });
            toast.error("Something went wrong on werver end.");
          }
        })
        .catch(function (error) {
          let errorobj = GetError(error);
          if (errorobj) {
            if (errorobj.status === 400) {
              toast.error(errorobj.data.message.replace(/<[^>]+>/g, ""));
            } else if (errorobj.status === 401) {
              toast.error(errorobj.data.message.replace(/<[^>]+>/g, ""));

              setTimeout(function () {
                logout();
              }, 2000);
            } else {
              toast.error("Something went wrong on server end.");
            }
          } else {
            toast.error("Something went wrong on server end.");
          }
        });
    }
  }

  handleOptionMedia(e) {
    let media = e.target.files[0];
    this.setState({
      [e.target.name]: media,
    });
  }

  handleClick(e, value, name, existMedia, questionType) {
    if (!value) {
      if (existMedia !== "") {
        confirmAlert({
          title: "Confirm",
          message:
            "Are you sure you want to hide this question type? if you hide it than it will be wiped out.",
          buttons: [
            {
              label: "Yes",
              onClick: () => {
                let formdata = new FormData();
                formdata.append("questionType", questionType);
                formdata.append("existMedia", existMedia);
                formdata.append("questionId", this.state.question_id);

                API.post("quiz/delete_question_media", formdata)
                  .then((res) => {
                    if (res.status === 200) {
                      this.setState({
                        [name]: value,
                      });
                    } else {
                      toast.error("Something went wrong on werver end.");
                    }
                  })
                  .catch(function (error) {
                    let errorobj = GetError(error);
                    if (errorobj) {
                      if (errorobj.status === 400) {
                        toast.error(errorobj.data.message);
                      } else if (errorobj.status === 401) {
                        toast.error(errorobj.data.message);
                        setTimeout(
                          function () {
                            logout();
                          }.bind(this),
                          2000
                        );
                      } else {
                        toast.error("Something went wrong on server end.");
                      }
                    } else {
                      toast.error("Something went wrong on server end.");
                    }
                  });
              },
            },
            {
              label: "No",
              onClick: () => {
                this.setState({
                  isLoading: false,
                });
              },
            },
          ],
        });
      } else {
        this.setState({
          [name]: value,
        });
      }
    } else {
      this.setState({
        [name]: value,
      });
    }
  }
  handleClickOption(e, value, option, type, category_id) {
    this.setState({
      IsLoading: true,
    });
    let self = this;

    if (value) {
      confirmAlert({
        title: "Confirm",
        message:
          "Are you sure you want to change option type? if you change your option type than previous option wiped out.",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              this.setState(
                {
                  optionTextContent: false,
                  optionImageMedia: false,
                  optionVideoMedia: false,
                  optionPdfMedia: false,
                  optionTexts: Array(3).fill(""),
                  optionImages: Array(3).fill(""),
                  optionVideos: Array(3).fill(""),
                  optionPdfs: Array(3).fill(""),
                  [option]: value,
                  optionType: type,
                  QustionError: true,
                  TextAnswerRequried: true,
                  ImageAnswerRequried: true,
                  VideoAnswerRequried: true,
                  PdfAnswerRequried: true,
                  optionImagesIn: Array(4).fill(""),
                  optionVideosIn: Array(4).fill(""),
                  optionPdfsIn: Array(4).fill(""),
                },
                () => {
                  if (this.optionType !== 2) {
                    self.setState({
                      IsLoading: false,
                    });

                    document.getElementsByClassName("imageInput")[0].value =
                      null;
                  }
                  if (this.optionType !== 3) {
                    self.setState({
                      IsLoading: false,
                    });
                    document.getElementsByClassName("videoInput")[0].value =
                      null;
                  }
                  if (this.optionType !== 4) {
                    self.setState({
                      IsLoading: false,
                    });
                    document.getElementsByClassName("pdfInput")[0].value = null;
                  }
                }
              );

              if(category_id == 9) {
      
                if(type == 1) {
                  let optionTexts = this.state.optionTexts.slice(1);
                  this.setState({ optionTexts: optionTexts });  
                } 

                if(type == 2) { 
                  let optionImages = this.state.optionImages.slice(1);
                  this.setState({ optionImages: optionImages });
                } 

                if(type == 3) {
                  let optionVideos = this.state.optionVideos.slice(1);
                  this.setState({ optionVideos: optionVideos });
                } 

                if(type == 4) { 
                  let optionPdfs = this.state.optionPdfs.slice(1);
                  this.setState({ optionPdfs: optionPdfs }); 
                }  
              }


            },
          },
          {
            label: "No",
            onClick: () => {
              self.setState({
                isLoading: false,
              });
            },
          },
        ],
      });
    }
  }

  handleOptionImages(e, index) {
    let media = e.target.files[0];
    let mediaName = "optionImage";

    if(media.size != '') {
      let resultMedia = mediaSizeValidation(media.size);
      if(resultMedia) {
        e.target.value = null;
        toast.error(FileSizeErrorMessage);
        return false;
      }
    }

    if (!media.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      toast.error("Allowed file types are: .jpg, .png, .gif, .jpeg ");
    } else {
      if (this.state.optionImagesIn[index]) {
        let mediaformdataremove = new FormData();
        mediaformdataremove.append(
          "filepath",
          this.state.optionImagesIn[index]
        );
        API.post("quiz/remove_question_media", mediaformdataremove).then(
          (res) => { }
        );
        let filteredArray = this.state.mediaArr.filter(
          (item) => item !== this.state.optionImagesIn[index]
        );
        this.setState({ mediaArr: filteredArray });
      }

      let optionLoaderImagesIn = this.state.optionLoaderImagesIn.slice();
      optionLoaderImagesIn[index] = index;
      this.setState({ optionLoaderImagesIn: optionLoaderImagesIn });

      let mediaformdataoption = new FormData();
      mediaformdataoption.append(mediaName, media);

      API.post("quiz/upload_question_media", mediaformdataoption)
        .then((res) => {
          if (res.status === 200) {
            let optionImagesIn = this.state.optionImagesIn.slice();
            optionImagesIn[index] = res.data.media_url;
            this.setState({ optionImagesIn: optionImagesIn });

            //let media = e.target.files[0];

            let optionImages = this.state.optionImages.slice();
            let optionImagesPreview = this.state.optionImagesPreview.slice();

            optionImages[index] = media; // Update it with the modified email.
            optionImagesPreview[index] = URL.createObjectURL(media);
            this.setState({
              optionImages: optionImages,
              optionImagesPreview: optionImagesPreview,
            });
            let optionLoaderImagesIn = this.state.optionLoaderImagesIn.slice();
            optionLoaderImagesIn[index] = "";
            this.setState({ optionLoaderImagesIn: optionLoaderImagesIn });

            this.setState({
              mediaArr: [...this.state.mediaArr, res.data.media_url],
            });

            toast.success(res.data.message);
          } else {
            let optionLoaderImagesIn = this.state.optionLoaderImagesIn.slice();
            optionLoaderImagesIn[index] = "";
            this.setState({ optionLoaderImagesIn: optionLoaderImagesIn });

            toast.error("Something went wrong on werver end.");
          }
        })
        .catch(function (error) {
          let errorobj = GetError(error);
          if (errorobj) {
            if (errorobj.status === 400) {
              let optionLoaderImagesIn =
                this.state.optionLoaderImagesIn.slice();
              optionLoaderImagesIn[index] = "";
              this.setState({ optionLoaderImagesIn: optionLoaderImagesIn });

              toast.error(errorobj.data.message.replace(/<[^>]+>/g, ""));
            } else if (errorobj.status === 401) {
              let optionLoaderImagesIn =
                this.state.optionLoaderImagesIn.slice();
              optionLoaderImagesIn[index] = "";
              this.setState({ optionLoaderImagesIn: optionLoaderImagesIn });

              toast.error(errorobj.data.message.replace(/<[^>]+>/g, ""));
              setTimeout(function () {
                logout();
              }, 2000);
            } else {
              let optionLoaderImagesIn =
                this.state.optionLoaderImagesIn.slice();
              optionLoaderImagesIn[index] = "";
              this.setState({ optionLoaderImagesIn: optionLoaderImagesIn });

              toast.error("Something went wrong on server end.");
            }
          } else {
            let optionLoaderImagesIn = this.state.optionLoaderImagesIn.slice();
            optionLoaderImagesIn[index] = "";
            this.setState({ optionLoaderImagesIn: optionLoaderImagesIn });

            toast.error("Something went wrong on server end.");
          }
        });
    }
  }
  handleOptionVideos(e, index) {
    let media = e.target.files[0];
    let mediaName = "optionVideos";

    if(media.size != '') {
      let resultMedia = mediaSizeValidation(media.size);
      if(resultMedia) {
        e.target.value = null;
        toast.error(FileSizeErrorMessage);
        return false;
      }
    }

    if (!media.name.match(/\.(mp4|MP4)$/)) {
      toast.error("Allowed file types are: .mp4 ");
    } else {
      if (this.state.optionVideosIn[index]) {
        let mediaformdataremove = new FormData();
        mediaformdataremove.append(
          "filepath",
          this.state.optionVideosIn[index]
        );
        API.post("quiz/remove_question_media", mediaformdataremove).then(
          (res) => { }
        );
        let filteredArray = this.state.mediaArr.filter(
          (item) => item !== this.state.optionVideosIn[index]
        );
        this.setState({ mediaArr: filteredArray });
      }

      let optionLoaderVideosIn = this.state.optionLoaderVideosIn.slice();
      optionLoaderVideosIn[index] = index;
      this.setState({ optionLoaderVideosIn: optionLoaderVideosIn });

      let mediaformdataoption = new FormData();
      mediaformdataoption.append(mediaName, media);

      API.post("quiz/upload_question_media", mediaformdataoption)
        .then((res) => {
          if (res.status === 200) {
            let optionVideosIn = this.state.optionVideosIn.slice();
            optionVideosIn[index] = res.data.media_url;
            this.setState({ optionVideosIn: optionVideosIn });

            let optionVideos = this.state.optionVideos.slice();
            let optionVideosPreview = this.state.optionVideosPreview.slice();
            optionVideos[index] = media; // Update it with the modified email.
            optionVideosPreview[index] = URL.createObjectURL(media);
            this.setState({
              optionVideos: optionVideos,
              optionVideosPreview: optionVideosPreview,
            });

            let optionLoaderVideosIn = this.state.optionLoaderVideosIn.slice();
            optionLoaderVideosIn[index] = "";
            this.setState({ optionLoaderVideosIn: optionLoaderVideosIn });

            this.setState({
              mediaArr: [...this.state.mediaArr, res.data.media_url],
            });

            toast.success(res.data.message);
          } else {
            let optionLoaderVideosIn = this.state.optionLoaderVideosIn.slice();
            optionLoaderVideosIn[index] = "";
            this.setState({ optionLoaderVideosIn: optionLoaderVideosIn });
            toast.error("Something went wrong on werver end.");
          }
        })
        .catch(function (error) {
          let errorobj = GetError(error);
          if (errorobj) {
            if (errorobj.status === 400) {
              let optionLoaderVideosIn =
                this.state.optionLoaderVideosIn.slice();
              optionLoaderVideosIn[index] = "";
              this.setState({ optionLoaderVideosIn: optionLoaderVideosIn });
              toast.error(errorobj.data.message.replace(/<[^>]+>/g, ""));
            } else if (errorobj.status === 401) {
              let optionLoaderVideosIn =
                this.state.optionLoaderVideosIn.slice();
              optionLoaderVideosIn[index] = "";
              this.setState({ optionLoaderVideosIn: optionLoaderVideosIn });
              toast.error(errorobj.data.message.replace(/<[^>]+>/g, ""));
              setTimeout(function () {
                logout();
              }, 2000);
            } else {
              let optionLoaderVideosIn =
                this.state.optionLoaderVideosIn.slice();
              optionLoaderVideosIn[index] = "";
              this.setState({ optionLoaderVideosIn: optionLoaderVideosIn });
              toast.error("Something went wrong on server end.");
            }
          } else {
            let optionLoaderVideosIn = this.state.optionLoaderVideosIn.slice();
            optionLoaderVideosIn[index] = "";
            this.setState({ optionLoaderVideosIn: optionLoaderVideosIn });
            toast.error("Something went wrong on server end.");
          }
        });
    }
  }
  handleOptionPdfs(e, index) {
    let media = e.target.files[0];
    let targetId = e.target.id;
    let mediaName = "optionPdfs";

    if(media.size != '') {
      let resultMedia = mediaSizeValidation(media.size);
      if(resultMedia) {
        e.target.value = null;
        toast.error(FileSizeErrorMessage);
        return false;
      }
    }

    if (!media.name.match(/\.(pdf)$/)) {
      toast.error("Allowed file types are: .pdf ");
    } else {
      //alert(this.state.optionPdfsIn[index]);
      if (this.state.optionPdfsIn[index]) {
        let mediaformdataremove = new FormData();
        mediaformdataremove.append("filepath", this.state.optionPdfsIn[index]);
        API.post("quiz/remove_question_media", mediaformdataremove).then(
          (res) => { }
        );
        let filteredArray = this.state.mediaArr.filter(
          (item) => item !== this.state.optionPdfsIn[index]
        );
        this.setState({ mediaArr: filteredArray });
      }
      let optionLoaderpdfIn = this.state.optionLoaderpdfIn.slice();
      optionLoaderpdfIn[index] = index;
      this.setState({ optionLoaderpdfIn: optionLoaderpdfIn });

      let mediaformdataoption = new FormData();
      mediaformdataoption.append(mediaName, media);

      API.post("quiz/upload_question_media", mediaformdataoption)
        .then((res) => {
          if (res.status === 200) {
            let optionPdfsIn = this.state.optionPdfsIn.slice();
            optionPdfsIn[index] = res.data.media_url;
            this.setState({ optionPdfsIn: optionPdfsIn });

            let optionPdfs = this.state.optionPdfs.slice();
            optionPdfs[index] = media; // Update it with the modified email.
            this.setState({ optionPdfs: optionPdfs });

            let optionLoaderpdfIn = this.state.optionLoaderpdfIn.slice();
            optionLoaderpdfIn[index] = "";
            this.setState({ optionLoaderpdfIn: optionLoaderpdfIn });

            this.setState({
              mediaArr: [...this.state.mediaArr, res.data.media_url],
            });

            toast.success(res.data.message);
          } else {
            let optionLoaderpdfIn = this.state.optionLoaderpdfIn.slice();
            optionLoaderpdfIn[index] = "";
            this.setState({ optionLoaderpdfIn: optionLoaderpdfIn });
            toast.error("Something went wrong on werver end.");
          }
        })
        .catch(function (error) {
          let errorobj = GetError(error);
          if (errorobj) {
            if (errorobj.status === 400) {
              let optionLoaderpdfIn = this.state.optionLoaderpdfIn.slice();
              optionLoaderpdfIn[index] = "";
              this.setState({ optionLoaderpdfIn: optionLoaderpdfIn });
              toast.error(errorobj.data.message.replace(/<[^>]+>/g, ""));
            } else if (errorobj.status === 401) {
              let optionLoaderpdfIn = this.state.optionLoaderpdfIn.slice();
              optionLoaderpdfIn[index] = "";
              this.setState({ optionLoaderpdfIn: optionLoaderpdfIn });
              toast.error(errorobj.data.message.replace(/<[^>]+>/g, ""));
              setTimeout(function () {
                logout();
              }, 2000);
            } else {
              let optionLoaderpdfIn = this.state.optionLoaderpdfIn.slice();
              optionLoaderpdfIn[index] = "";
              this.setState({ optionLoaderpdfIn: optionLoaderpdfIn });
              toast.error("Something went wrong on server end.");
            }
          } else {
            let optionLoaderpdfIn = this.state.optionLoaderpdfIn.slice();
            optionLoaderpdfIn[index] = "";
            this.setState({ optionLoaderpdfIn: optionLoaderpdfIn });
            toast.error("Something went wrong on server end.");
          }
        });
    }
  }

  addMoreImage = (e) => {
    let uploadQuestionImageCount = [...this.state.uploadQuestionImageCount];
    let len = parseInt(uploadQuestionImageCount.length) + 1;
    
    var data = this.state.multipleImage.filter(function (number) {
      return number !== null;
    });

    this.setState({
      multipleImage: [...data, len],
    });

  };

  addMoreVideo = (e) => {
    let uploadQuestionVideoCount = [...this.state.uploadQuestionVideoCount];
    let len = parseInt(uploadQuestionVideoCount.length) + 1;
    var data = this.state.multipleVideo.filter(function (number) {
      return number !== null;
    });
    this.setState({
      multipleVideo: [...data, len - 1],
    });
  };

  addMorePdf = (e) => {
    let len = parseInt(this.state.multiplePdf.length) + 1;
    var data = this.state.multiplePdf.filter(function (number) {
      return number !== null;
    });
    this.setState({
      multiplePdf: [...data, len - 1],
    });
  };

  removeInputfile = (e, index, newArr, fileArr, removeType) => {
    var deletFromServer = "false";
    if (removeType === "multipleImage") {
      const existquestionImages = [...this.state.existquestionImages];
      
      if (existquestionImages[index] === undefined) {
        var deletFromServer = "true";
        var mediaurl = this.state.questionImagesIn[index];
      } else {
        existquestionImages.splice(index, 1);
        this.setState({ existquestionImages: existquestionImages });
      }

      newArr.splice(index, 1);
      fileArr.splice(index, 1);
      
      this.setState({
        multipleImage: newArr,
      });

      
    } else if (removeType === "multipleVideo") {
      const existquestionVideos = [...this.state.existquestionVideos];

      if (existquestionVideos[index] === undefined) {
        var mediaurl = this.state.questionVideosIn[index];
        var deletFromServer = "true";
      } else {
        existquestionVideos.splice(index, 1);
        this.setState({ existquestionVideos: existquestionVideos });
      }

      newArr.splice(index, 1);
      fileArr.splice(index, 1);
      this.setState({
        multipleVideo: newArr,
      });
    } else if (removeType === "multiplePdf") {
      const existquestionpdf = [...this.state.existquestionpdf];
      if (existquestionpdf[index] === undefined) {
        var deletFromServer = "true";
        var mediaurl = this.state.questionpdfIn[index];
      } else {
        existquestionpdf.splice(index, 1);
        this.setState({ existquestionpdf: existquestionpdf });
      }
      newArr.splice(index, 1);
      fileArr.splice(index, 1);
      this.setState({
        multiplePdf: newArr,
      });
    }

    if (deletFromServer === "true" && mediaurl !== "") {
      let mediaformdataremove = new FormData();
      mediaformdataremove.append("filepath", mediaurl);

      API.post("quiz/remove_question_media", mediaformdataremove)
        .then((res) => {
          if (res.status === 200) {
            if (this._isMounted) {
              this.setState({
                IsLoading: false,
                IsSubmit: false,
              });
            }
            let filteredArray = this.state.mediaArr.filter(
              (item) => item !== mediaurl
            );
            this.setState({ mediaArr: filteredArray });

            toast.success(res.data.message);
          } else {
            toast.error("Something went wrong on werver end.");
          }
        })
        .catch(function (error) {
          let errorobj = GetError(error);
          if (errorobj) {
            if (errorobj.status === 400) {
              toast.error(errorobj.data.message);
            } else if (errorobj.status === 401) {
              toast.error(errorobj.data.message);
              setTimeout(function () {
                logout();
              }, 2000);
            } else {
              toast.error("Something went wrong on server end.");
            }
          } else {
            toast.error("Something went wrong on server end.");
          }
        });
    }
  };

  updateQuetionAnswer = (e) => {
    let self = this;
    this.setState({
      isLoading: false,
      IsSubmit: true,
    });
    this._isMounted = true;
    e.preventDefault();
    this.setState(
      {
        QustionError: QuestionRequired(this.state),
        TextAnswerRequried: TextAnswerRequried(this.state),
        ImageAnswerRequried: ImageAnswerRequried(this.state),
        VideoAnswerRequried: VideoAnswerRequried(this.state),
        PdfAnswerRequried: PdfAnswerRequried(this.state),
      },
      () => {
        if (
          this.validator.allValid() &&
          this.state.QustionError &&
          this.state.TextAnswerRequried &&
          this.state.ImageAnswerRequried &&
          this.state.VideoAnswerRequried &&
          this.state.PdfAnswerRequried &&
          this.state.validImage_error &&
          this.state.validVideo_error &&
          this.state.validPdf_error &&
          this.state.validUrl_error
        ) {
          this.setState({
            IsLoading: true,
            IsSubmit: true,
          });

          let formdata = new FormData();
          formdata.append("question_id", this.state.question_id);
          formdata.append("quiz_name", this.state.quiz_name);
          formdata.append("category_id", this.state.category_id);
          formdata.append("question", this.state.question);
          formdata.append(
            "existquestionImages",
            this.state.existquestionImages
          );
          formdata.append(
            "existquestionVideos",
            this.state.existquestionVideos
          );
          formdata.append("existquestionpdf", this.state.existquestionpdf);

          formdata.append("questionImagesIn", this.state.questionImagesIn);
          formdata.append("questionVideosIn", this.state.questionVideosIn);
          formdata.append("questionpdfIn", this.state.questionpdfIn);
          formdata.append("optionType", this.state.optionType);
          if (this.state.optionTexts) {
            for (let text = 0; text < this.state.optionTexts.length; text++) {
              formdata.append(
                `optionTexts[${this.state.optionIds[text]}]`,
                this.state.optionTexts[text]
              );
            }
          }

          if (this.state.optionImagesPath) {
            for (let img = 0; img < this.state.optionImagesPath.length; img++) {
              formdata.append(
                `optionImages[${this.state.optionIds[img]}]`,
                this.state.optionImagesPath[img]
              );
            }
          }

          if (this.state.optionImagesIn) {
            for (let img = 0; img < this.state.optionImagesIn.length; img++) {
              if (this.state.optionImagesIn[img] !== "") {
                formdata.append(
                  `optionImagesIn[${this.state.optionIds[img]}]`,
                  this.state.optionImagesIn[img]
                );
              }
            }
          }

          if (this.state.optionVideosPath) {
            for (
              let video = 0;
              video < this.state.optionVideosPath.length;
              video++
            ) {
              formdata.append(
                `optionVideos[${this.state.optionIds[video]}]`,
                this.state.optionVideosPath[video]
              );
            }
          }

          if (this.state.optionVideosIn) {
            for (
              let video = 0;
              video < this.state.optionVideosIn.length;
              video++
            ) {
              if (this.state.optionVideosIn[video] !== "") {
                formdata.append(
                  `optionVideosIn[${this.state.optionIds[video]}]`,
                  this.state.optionVideosIn[video]
                );
              }
            }
          }

          if (this.state.optionPdfsPath) {
            for (let pdf = 0; pdf < this.state.optionPdfsPath.length; pdf++) {
              formdata.append(
                `optionPdfs[${this.state.optionIds[pdf]}]`,
                this.state.optionPdfsPath[pdf]
              );
            }
          }

          if (this.state.optionPdfsIn) {
            for (let pdf = 0; pdf < this.state.optionPdfsIn.length; pdf++) {
              if (this.state.optionPdfsIn[pdf] !== "") {
                formdata.append(
                  `optionPdfsIn[${this.state.optionIds[pdf]}]`,
                  this.state.optionPdfsIn[pdf]
                );
              }
            }
          }

          formdata.append("correctAnswer", this.state.correctAnswer);

          if (this.props.explanation) {
            for (const explantion of this.props.explanation) {
              if (explantion.explanation_type === "1") {
                if (explantion.explanation_id != 0) {
                  formdata.append(
                    `explanationArr[${explantion.explanation_id}]`,
                    "text_" + explantion.explantion_key
                  );
                  formdata.append(
                    "text_" + explantion.explantion_key,
                    explantion.explanation_media
                  );
                } else {
                  formdata.append(
                    `explanationArr[]`,
                    "text_" + explantion.explantion_key
                  );
                  formdata.append(
                    "text_" + explantion.explantion_key,
                    explantion.explanation_media
                  );
                }
              } else if (explantion.explanation_type === "2") {
                if (explantion.explanation_id != 0) {
                  formdata.append(
                    `explanationArr[${explantion.explanation_id}]`,
                    "image_" + explantion.explantion_key
                  );
                  formdata.append(
                    "image_" + explantion.explantion_key,
                    explantion.explanation_media
                  );
                } else {
                  formdata.append(
                    `explanationArr[]`,
                    "image_" + explantion.explantion_key
                  );
                  formdata.append(
                    "image_" + explantion.explantion_key,
                    explantion.explanation_media
                  );
                }
              } else if (explantion.explanation_type === "3") {
                if (explantion.explanation_id != 0) {
                  formdata.append(
                    `explanationArr[${explantion.explanation_id}]`,
                    "video_" + explantion.explantion_key
                  );
                  formdata.append(
                    "video_" + explantion.explantion_key,
                    explantion.explanation_media
                  );
                } else {
                  formdata.append(
                    `explanationArr[]`,
                    "video_" + explantion.explantion_key
                  );
                  formdata.append(
                    "video_" + explantion.explantion_key,
                    explantion.explanation_media
                  );
                }
              } else if (explantion.explanation_type === "4") {
                formdata.append(
                  `explanationArr[${explantion.explanation_id}]`,
                  "pdf_" + explantion.explantion_key
                );

                formdata.append(
                  "pdf_" + explantion.explantion_key,
                  explantion.explanation_media
                );
              } else if (explantion.explanation_type === "5") {
                formdata.append(
                  `explanationArr[${explantion.explanation_id}]`,
                  "url_" + explantion.explantion_key
                );
                formdata.append(
                  "url_" + explantion.explantion_key,
                  explantion.explanation_media
                );
              }
            }
          }
          // alert("Under production coming soon...");
          
          API.post(`quiz/update_question/${this.state.question_id}`, formdata)
            .then((res) => {
              if (res.data.status === 200) {
                if (this._isMounted) {
                  this.setState({
                    isLoading: false,
                    // IsSubmit: false
                    isFormSubmit: true,
                  });
                }
                this.props.history.push("/questionlist");
                toast.success(res.data.message);
              } else {
                toast.error("Something went wrong on server end.");
              }
            })
            .catch(function (error) {
              self.setState({
                isLoading: false,
                IsSubmit: false,
              });
              let errorobj = GetError(error);
              if (errorobj.status === 400) {
                toast.error(errorobj.data.message);
              } else if (errorobj.status === 401) {
                toast.error(errorobj.data.message);
                setTimeout(
                  function () {
                    logout();
                  }.bind(this),
                  2000
                );
              } else {
                toast.error("Something went wrong on server end.");
              }
            });
        } else {
          this.validator.showMessages();
          this.forceUpdate();
          this.setState({
            IsLoading: false,
            IsSubmit: false,
          });
        }
      }
    );
  };
  render() {
    if (authToken === "") {
      this.props.history.push("/login");
    }
    const questionImagesArr = [...this.state.questionImages];
    const questionVideosArr = [...this.state.questionVideos];
    const questionpdfArr = [...this.state.questionpdf];
    const existquestionImagesArr = [...this.state.existquestionImages];
    const existquestionVideosArr = [...this.state.existquestionVideos];
    const existquestionpdfArr = [...this.state.existquestionpdf];
    const existquestionImagesArrURL = [...this.state.existquestionImagesURL];
    const existquestionVideosArrURL = [...this.state.existquestionVideosURL];
    const existquestionpdfArrURL = [...this.state.existquestionpdfURL];
    return (
      <div>
        <Prompt
          when={
            (this.state.mediaArr.length ||
              this.state.shouldBlockNavigation === true) &&
            this.state.isFormSubmit === false
          }
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <Loader isLoading={this.state.IsLoading} />
        {/* <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
        >
          <span className="fas fa-times closepopup" onClick={this.closeModal} />
          <div>
            {this.state.modelContentType === "IMAGE" ? (
              <img
                style={{ width: 800, height: 600 }}
                src={this.state.modelContent}
                alt="Question image"
              />
            ) : (
              ""
            )}
            {this.state.modelContentType === "VIDEO" ? (
              <video style={{ width: 800, height: 600 }} controls>
                <source src={this.state.modelContent} type="video/mp4" />
                <source src={this.state.modelContent} type="video/ogg" />
              </video>
            ) : (
              ""
            )}
            {this.state.modelContentType === "PDF" ? (
              <embed
                src={this.state.modelContent}
                style={{ width: 800, height: 600 }}
              />
            ) : (
              ""
            )}
          </div>
        </Modal> */}
        <div className="page-wrapper">
          {/* <div className="page-breadcrumb">
            <div className="row">
              <div className="col-12 d-flex no-block align-items-center">
                <div className="">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <NavLink to="/">Dashboard</NavLink>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Edit Question
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div> */}
          <div className="container-fluid custom-form-design">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <h4 className="card-title">Edit Question</h4>
                  <div className="col-md-6">
                    <form className="form-horizontal">
                      <div className="card-body">
                        <div className="form-group row">
                          <div className="col-sm-12" data-select2-id="11">
                            <label className="control-label col-form-label label-text">
                              Category Title<sup className="req-color">*</sup>
                            </label>
                            <select
                              className="select2 form-control custom-select select2-hidden-accessible"
                              data-select2-id="1"
                              aria-hidden="true"
                              name="category_id"
                              onChange={(e) => this.handleChange(e)}
                              value={this.state.category_id}
                              disabled={
                                this.state.quiz_status == "2" ? "" : "disabled"
                              }
                            >
                              <option value="">Select Category Title</option>
                              {this.props.load_category.length > 0
                                ? this.props.load_category.map(
                                  (category, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={category.category_id}
                                      >
                                        {category.category_name}
                                      </option>
                                    );
                                  }
                                )
                                : ""}
                            </select>
                            {this.validator.message(
                              "Category Title",
                              this.state.category_id,
                              "required"
                            )}
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-md-12" data-select2-id="11">
                            <label className="control-label col-form-label label-text">
                              Quiz Title<sup className="req-color">*</sup>
                            </label>
                            <select
                              className="select2 form-control custom-select select2-hidden-accessible"
                              data-select2-id="1"
                              aria-hidden="true"
                              name="quiz_name"
                              onChange={(e) => this.handleChange(e)}
                              value={this.state.quiz_name}
                              disabled={
                                this.state.quiz_status == "2" ? "" : "disabled"
                              }
                            >
                              <option value="">Select Quiz Title</option>
                              {this.props.load_quiz.length > 0
                                ? this.props.load_quiz.map((quiz, index) => {
                                  //if (quiz.status === "1") {
                                  return (
                                    <option
                                      key={index}
                                      value={quiz.quiz_id}
                                    >
                                      {quiz.quiz_name}
                                    </option>
                                  );
                                  //}
                                })
                                : ""}
                            </select>
                            {this.validator.message(
                              "Quiz Title",
                              this.state.quiz_name,
                              "required"
                            )}
                          </div>
                        </div>
                        {/* Question Start */}
                        {/* <h4 className="card-title">Question</h4> */}

                        <div className="form-group row">
                          <div className="col-sm-12">
                            <label className="control-label col-form-label label-text">
                              Question<sup className="req-color">*</sup>
                            </label>
                            <textarea
                              type="text"
                              className="form-control"
                              placeholder="Enter Question"
                              value={this.state.question}
                              name="question"
                              onChange={(e) => this.handleChange(e)}
                              rows="4"
                              cols="50"
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <button
                            type="button"
                            className="btn btn-newui"
                            name="questionMedia"
                            onClick={(e) =>
                              this.handleClick(
                                e,
                                this.state.questionMedia ? false : "",
                                "questionMedia",
                                "",
                                ""
                              )
                            }
                            disabled={this.state.questionMedia === false}
                          >
                            Add Attachment
                          </button>

                          <div
                            className={
                              this.state.questionMedia
                                ? "col-sm-8 hide_question_media"
                                : "col-sm-8"
                            }
                          >
                            <button
                              type="button"
                              className={
                                this.state.questionMediaImage
                                  ? "btn btn-dark btn-sm"
                                  : "btn btn-light btn-sm"
                              }
                              title="image"
                              name="questionMediaImage"
                              onClick={(e) =>
                                this.handleClick(
                                  e,
                                  this.state.questionMediaImage ? false : true,
                                  "questionMediaImage",
                                  this.state.existquestionImages
                                    ? this.state.existquestionImages
                                    : "",
                                  1
                                )
                              }
                            >
                              <i className="fas fa-image" />
                            </button>

                            <button
                              type="button"
                              className={
                                this.state.questionMediaVideo
                                  ? "btn btn-dark btn-sm"
                                  : "btn btn-light btn-sm"
                              }
                              title="video"
                              name="questionMediaVideo"
                              onClick={(e) =>
                                this.handleClick(
                                  e,
                                  this.state.questionMediaVideo ? false : true,
                                  "questionMediaVideo",
                                  this.state.existquestionVideos
                                    ? this.state.existquestionVideos
                                    : "",
                                  2
                                )
                              }
                            >
                              <i className="fas fa-video" />
                            </button>
                            <button
                              type="button"
                              className={
                                this.state.questionMediaPdf
                                  ? "btn btn-dark btn-sm"
                                  : "btn btn-light btn-sm"
                              }
                              title="pdf"
                              name="questionMediaPdf"
                              onClick={(e) =>
                                this.handleClick(
                                  e,
                                  this.state.questionMediaPdf ? false : true,
                                  "questionMediaPdf",
                                  this.state.existquestionpdf
                                    ? this.state.existquestionpdf
                                    : "",
                                  3
                                )
                              }
                            >
                              <i className="fas fa-file-pdf" />
                            </button>
                          </div>
                        </div>

                        <div className="form-group row">
                          <div
                            className={
                              this.state.questionMediaImage
                                ? "col-sm-4"
                                : "col-sm-4 hide_question_media"
                            }
                          >
                            {/* NOTE :- This button for add more image for a question. we don't need add more functionality yet but it's completed on my end. if you can uncomment then add more button is enable .Thanks */}
                            <button
                              type="button"
                              className="btn btn-primary-newui btn-md-newui"
                              name="questionImages"
                              onClick={(e) => this.addMoreImage(e)}
                              disabled={
                                this.state.multipleImage.length < 5
                                  ? false
                                  : true
                              }
                            >
                              Add more
                            </button>
                            <div>
                              {this.state.multipleImage.map((x, index) =>
                                existquestionImagesArrURL[x] ? (
                                  <div key={index}>
                                    <i
                                      className="fas fa-times-circle removeInput"
                                      name="existquestionImages"
                                      onClick={(e) =>
                                        this.removeInputfile(
                                          e,
                                          index,
                                          this.state.multipleImage,
                                          this.state.questionImages,
                                          "multipleImage"
                                        )
                                      }
                                    />
                                    <img
                                      src={

                                        existquestionImagesArrURL[x]
                                      }
                                      alt="questionImage"
                                      width="40%"
                                      title="Click here to view question image"
                                      onClick={() =>
                                        this.handleimageModal(

                                          existquestionImagesArrURL[x],
                                          "IMAGE"
                                        )
                                      }
                                    ></img>
                                    <div>&nbsp;</div>
                                  </div>
                                  
                                ) : (
                                  <div key={index} className="fieldWrapper">
                                    <i
                                      className="fas fa-times-circle removeInput"
                                      name="multipleImage"
                                      onClick={(e) =>
                                        this.removeInputfile(
                                          e,
                                          index,
                                          this.state.multipleImage,
                                          this.state.questionImages,
                                          "multipleImage"
                                        )
                                      }
                                    />
                                    <AddInput
                                      name="questionImages"
                                      handleIndex={index}
                                      label="IMAGE"
                                      filename={
                                        questionImagesArr[index]
                                          ? questionImagesArr[index].name
                                          : ""
                                      }
                                      handlefile={(e) =>
                                        this.handleMedia(
                                          e,
                                          this.state.questionImages,
                                          index
                                        )
                                      }
                                      spinner={
                                        this.state.questionLoaderImagesIn[index]
                                      }
                                    />
                                  </div>
                                )
                              )}

                              <div className="clear-1">&nbsp;</div>
                              {this.validator.message(
                                "Question Image",
                                this.state.questionImages,
                                "validImage"
                              )}
                            </div>
                          </div>

                          <div
                            className={
                              this.state.questionMediaVideo
                                ? "col-sm-4"
                                : "col-sm-4 hide_question_media"
                            }
                          >
                            {/* NOTE :- This button for add more Video for a question. we don't need add more functionality yet but it's completed on my end. if you can uncomment then add more button is enable .Thanks */}
                            <button
                              type="button"
                              className="btn btn-primary-newui btn-md-newui"
                              name="questionVideos"
                              onClick={(e) => this.addMoreVideo(e)}
                              disabled={
                                this.state.multipleVideo.length < 5
                                  ? false
                                  : true
                              }
                            >
                              Add more
                            </button>
                            <div>
                              {this.state.multipleVideo.map((x, index) =>
                                existquestionVideosArrURL[x] ? (
                                  <div key={index}>
                                    <i
                                      className="fas fa-times-circle removeInput"
                                      name="existquestionVideos"
                                      onClick={(e) =>
                                        this.removeInputfile(
                                          e,
                                          index,
                                          this.state.multipleVideo,
                                          this.state.questionVideos,
                                          "multipleVideo"
                                        )
                                      }
                                    />

                                    <video
                                      width="200"
                                      controls
                                      title="Click here to view question video"
                                      onClick={() =>
                                        this.handleimageModal(

                                          existquestionVideosArrURL[x],
                                          "IMAGE"
                                        )
                                      }
                                    >
                                      <source
                                        src={

                                          existquestionVideosArrURL[x]
                                        }
                                        type="video/mp4"
                                      />
                                    </video>
                                  </div>
                                ) : (
                                  <div key={index} className="fieldWrapper">
                                    <i
                                      className="fas fa-times-circle removeInput"
                                      name="multipleVideo"
                                      onClick={(e) =>
                                        this.removeInputfile(
                                          e,
                                          index,
                                          this.state.multipleVideo,
                                          this.state.questionVideos,
                                          "multipleVideo"
                                        )
                                      }
                                    />
                                    <AddInput
                                      name="questionVideos"
                                      handleIndex={index}
                                      label="VIDEO"
                                      filename={
                                        questionVideosArr[index]
                                          ? questionVideosArr[index].name
                                          : ""
                                      }
                                      handlefile={(e) =>
                                        this.handleMedia(
                                          e,
                                          this.state.questionVideos,
                                          index
                                        )
                                      }
                                      spinner={
                                        this.state.questionLoaderVideosIn[index]
                                      }
                                    />
                                  </div>
                                )
                              )}
                              <div className="clear-1">&nbsp;</div>
                              {this.validator.message(
                                "Question Video",
                                this.state.questionVideos,
                                "validVideo"
                              )}
                            </div>
                          </div>
                          <div
                            className={
                              this.state.questionMediaPdf
                                ? "col-sm-4"
                                : "col-sm-4 hide_question_media"
                            }
                          >
                            {/* NOTE :- This button for add more PDF for a question. we don't need add more functionality yet but it's completed on my end. if you can uncomment then add more button is enable .Thanks */}
                            <button
                              type="button"
                              className="btn btn-primary-newui btn-md-newui"
                              name="questionpdf"
                              onClick={(e) => this.addMorePdf(e)}
                              disabled={
                                this.state.multiplePdf.length < 5 ? false : true
                              }
                            >
                              Add more
                            </button>
                            <div>
                              {this.state.multiplePdf.map((x, index) =>
                                existquestionpdfArrURL[x] ? (
                                  <div
                                    key={index}
                                    style={{
                                      marginBottom: "2px",
                                      marginLeft: "29px",
                                    }}
                                  >
                                    <i
                                      className="fas fa-times-circle removeInput"
                                      name="existquestionpdf"
                                      onClick={(e) =>
                                        this.removeInputfile(
                                          e,
                                          index,
                                          this.state.multiplePdf,
                                          this.state.questionpdf,
                                          "multiplePdf"
                                        )
                                      }
                                    />

                                    <a
                                      href={


                                        existquestionpdfArrURL[x]
                                      }
                                      alt="demo"
                                      style={{ width: "200px" }}
                                      target="_blank"
                                    >
                                      pdf1
                                    </a>
                                  </div>
                                ) : (
                                  <div
                                    key={index}
                                    className="fieldWrapper"
                                    style={{ marginLeft: "10px" }}
                                  >
                                    <i
                                      className="fas fa-times-circle removeInput"
                                      name="multiplePdf"
                                      onClick={(e) =>
                                        this.removeInputfile(
                                          e,
                                          index,
                                          this.state.multiplePdf,
                                          this.state.questionpdf,
                                          "multiplePdf"
                                        )
                                      }
                                    />
                                    <AddInput
                                      name="questionpdf"
                                      handleIndex={index}
                                      label="PDF"
                                      filename={
                                        questionpdfArr[index]
                                          ? questionpdfArr[index].name
                                          : ""
                                      }
                                      handlefile={(e) =>
                                        this.handleMedia(
                                          e,
                                          this.state.questionpdf,
                                          index
                                        )
                                      }
                                      spinner={
                                        this.state.questionLoaderpdfIn[index]
                                      }
                                    />
                                  </div>
                                )
                              )}
                              <div className="clear-1">&nbsp;</div>
                              {this.validator.message(
                                "Question Pdf",
                                this.state.questionpdf,
                                "validPdf"
                              )}
                            </div>
                          </div>
                        </div>
                        <span className="srv-validation-message">
                          {this.state.QustionError === false
                            ? "The question field is required."
                            : ""}
                        </span>
                        {/* Answer options Start */}
                        <h4 className="card-title pd-ans">Answer</h4>
                        <div className="form-group row">
                          <button
                            type="button"
                            className={
                              this.state.optionTextContent
                                ? "btn btn-dark btn-sm"
                                : "btn btn-light btn-sm"
                            }
                            name="optionTextContent"
                            title="Text"
                            onClick={(e) =>
                              this.handleClickOption(
                                e,
                                this.state.optionTextContent ? false : true,
                                "optionTextContent",
                                1,
                                this.state.category_id
                              )
                            }
                          >
                            <i className="fas fa-text-height" />
                          </button>
                          <button
                            type="button"
                            className={
                              this.state.optionImageMedia
                                ? "btn btn-dark btn-sm"
                                : "btn btn-light btn-sm"
                            }
                            title="Image"
                            name="optionImageMedia"
                            onClick={(e) =>
                              this.handleClickOption(
                                e,
                                this.state.optionImageMedia ? false : true,
                                "optionImageMedia",
                                2,
                                this.state.category_id
                              )
                            }
                          >
                            <i className="fas fa-image" />
                          </button>
                          <button
                            type="button"
                            title="Video"
                            className={
                              this.state.optionVideoMedia
                                ? "btn btn-dark btn-sm"
                                : "btn btn-light btn-sm"
                            }
                            name="optionVideoMedia"
                            onClick={(e) =>
                              this.handleClickOption(
                                e,
                                this.state.optionVideoMedia ? false : true,
                                "optionVideoMedia",
                                3,
                                this.state.category_id
                              )
                            }
                          >
                            <i className="fas fa-video" />
                          </button>
                          <button
                            type="button"
                            title="Pdf"
                            className={
                              this.state.optionPdfMedia
                                ? "btn btn-dark btn-sm"
                                : "btn btn-light btn-sm"
                            }
                            name="optionPdfMedia"
                            onClick={(e) =>
                              this.handleClickOption(
                                e,
                                this.state.optionPdfMedia ? false : true,
                                "optionPdfMedia",
                                4,
                                this.state.category_id
                              )
                            }
                          >
                            <i className="fas fa-file-pdf" />
                          </button>
                        </div>
                        {/* options text start */}
                        <div
                          className={
                            this.state.optionTextContent
                              ? ""
                              : "hide_question_media"
                          }
                        >
                          {this.state.optionTexts.length > 0
                            ? this.state.optionTexts.map(
                              (optiontext, index) => (
                                <div className="form-group row" key={index}>
                                  <div className="col-sm-12">
                                    <label className="control-label col-form-label label-text">
                                      Option {index + 1}
                                      <sup className='req-color'>
                                      { this.state.category_id == 9 && index == 2 ? '' : "*" }
                                      </sup>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control custom-input"
                                      placeholder={"Option " + (index + 1)}
                                      value={this.state.optionTexts[index]}
                                      onChange={(e) =>
                                        this.handleChangeOption(e, index)
                                      }
                                    />
                                    {/* {this.validator.message(
                                        "Option text",
                                        this.state.optionTexts[index],
                                        "alpha_num_dash_space"
                                      )} */}
                                  </div>
                                </div>
                              )
                            )
                            : ""}

                          <span className="srv-validation-message">
                            {this.state.TextAnswerRequried === false
                              ? "Text options are required."
                              : ""}
                          </span>
                        </div>
                        {/* Option text end */}

                        {/* Option image start */}
                        <div
                          className={
                            this.state.optionImageMedia
                              ? ""
                              : "hide_question_media"
                          }
                        >
                          {this.state.optionImages.length > 0
                            ? this.state.optionImages.map(
                              (optionimg, index) => {
                                if (
                                  optionimg === "" ||
                                  typeof optionimg === "object"
                                ) {
                                  return (
                                    <div key={index}>
                                      <div className="form-group row">
                                        <div className="col-sm-12">
                                          <label className=" control-label col-form-label label-text">
                                            Option {index + 1} Image
                                            <sup className='req-color'>
                                            { this.state.category_id == 9 && index == 2 ? '' : "*" }
                                            </sup>
                                          </label>
                                          <input
                                            type="file"
                                            className="form-control imageInput"
                                            onChange={(e) =>
                                              this.handleOptionImages(
                                                e,
                                                index
                                              )
                                            }
                                          />
                                          {this.validator.message(
                                            "Option Image",
                                            this.state.optionImages[index],
                                            "validImage"
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div key={index}>
                                      <div className="form-group row">
                                        <div className="col-sm-10">
                                          <label className=" control-label col-form-label label-text">
                                            Option {index + 1} Image
                                            <sup className='req-color'>
                                            { this.state.category_id == 9 && index == 2 ? '' : "*" }
                                            </sup>
                                          </label>
                                          <input
                                            type="file"
                                            className="form-control imageInput"
                                            onChange={(e) =>
                                              this.handleOptionImages(
                                                e,
                                                index,
                                                this.state.optionIds[index],
                                                2
                                              )
                                            }
                                          />
                                          {this.validator.message(
                                            "Option Image",
                                            this.state.optionImages[index],
                                            "validImage"
                                          )}
                                        </div>
                                        <div className="col-sm-2">
                                          <img
                                            width="200"
                                            src={optionimg}
                                            alt="optionImage"
                                            title="Click here to view answer image"
                                            onClick={() =>
                                              this.handleimageModal(
                                                optionimg,
                                                "IMAGE"
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              }
                            )
                            : ""}
                          <span className="srv-validation-message">
                            {this.state.ImageAnswerRequried === false
                              ? "Image options are required."
                              : ""}
                          </span>
                        </div>
                        {/* Option image end */}
                        {/* Option Video start */}
                        <div
                          className={
                            this.state.optionVideoMedia
                              ? ""
                              : "hide_question_media"
                          }
                        >
                          {this.state.optionVideos.length > 0
                            ? this.state.optionVideos.map(
                              (optionvideo, index) => {
                                if (
                                  optionvideo === "" ||
                                  typeof optionimg === "object"
                                ) {
                                  return (
                                    <div key={index}>
                                      <div className="form-group row">
                                        <div className="col-sm-12">
                                          <label className="control-label col-form-label label-text">
                                            Option {index + 1} Video
                                            <sup className='req-color'>
                                            { this.state.category_id == 9 && index == 2 ? '' : "*" }
                                            </sup>
                                          </label>
                                          <input
                                            type="file"
                                            className="form-control videoInput"
                                            onChange={(e) =>
                                              this.handleOptionVideos(
                                                e,
                                                index
                                              )
                                            }
                                          />
                                          {this.validator.message(
                                            "Option Video",
                                            this.state.optionVideos[index],
                                            "validVideo"
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div key={index}>
                                      <div className="form-group row">
                                        <div className="col-sm-10">
                                          <label className="control-label col-form-label label-text">
                                            Option {index + 1} Video
                                            <sup className='req-color'>
                                            { this.state.category_id == 9 && index == 2 ? '' : "*" }
                                            </sup>
                                          </label>
                                          <input
                                            type="file"
                                            className="form-control videoInput"
                                            onChange={(e) =>
                                              this.handleOptionVideos(
                                                e,
                                                index
                                              )
                                            }
                                          />
                                          {this.state.optionLoaderVideosIn[
                                            index
                                          ] === index ? (
                                            <Spinner
                                              isLoading={true}
                                            ></Spinner>
                                          ) : (
                                            ""
                                          )}
                                          {this.validator.message(
                                            "Option Video",
                                            this.state.optionVideos[index],
                                            "validVideo"
                                          )}
                                        </div>
                                        <div className="col-sm-2">
                                          <video
                                            width="200"
                                            controls
                                            alt="option video"
                                          >
                                            <source
                                              src={
                                                optionvideo
                                              }
                                            />
                                          </video>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              }
                            )
                            : ""}
                          <span className="srv-validation-message">
                            {this.state.VideoAnswerRequried === false
                              ? "Video options are required."
                              : ""}
                          </span>
                        </div>
                        {/* Option Video end */}
                        {/* Option Pdf start */}
                        <div
                          className={
                            this.state.optionPdfMedia
                              ? ""
                              : "hide_question_media"
                          }
                        >
                          {this.state.optionPdfs.length > 0
                            ? this.state.optionPdfs.map((optionpdf, index) => {
                              if (
                                optionpdf === "" ||
                                typeof optionimg === "object"
                              ) {
                                return (
                                  <div key={index}>
                                    <div className="form-group row">
                                      <div className="col-sm-12">
                                        <label className=" control-label col-form-label label-text">
                                          Option {index + 1} Pdf
                                          <sup className='req-color'>
                                          { this.state.category_id == 9 && index == 2 ? '' : "*" }
                                          </sup>
                                        </label>
                                        <input
                                          type="file"
                                          className="form-control pdfInput"
                                          onChange={(e) =>
                                            this.handleOptionPdfs(e, index)
                                          }
                                        />
                                        {this.state.optionLoaderpdfIn[
                                          index
                                        ] === index ? (
                                          <Spinner isLoading={true}></Spinner>
                                        ) : (
                                          ""
                                        )}
                                        {this.validator.message(
                                          "Option Pdf",
                                          this.state.optionPdfs[index],
                                          "validPdf"
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                );
                              } else {
                                return (
                                  <div key={index}>
                                    <div className="form-group row">
                                      <div className="col-sm-10">
                                        <label className=" control-label col-form-label label-text">
                                          Option {index + 1} Pdf
                                          <sup className='req-color'>
                                          { this.state.category_id == 9 && index == 2 ? '' : "*" }
                                          </sup>
                                        </label>
                                        <input
                                          type="file"
                                          className="form-control pdfInput"
                                          onChange={(e) =>
                                            this.handleOptionPdfs(e, index)
                                          }
                                        />
                                        {this.validator.message(
                                          "Option Pdf",
                                          this.state.optionPdfs[index],
                                          "validPdf"
                                        )}
                                      </div>
                                      <div className="col-sm-2">
                                        <a href={optionpdf}>
                                          PDF
                                        </a>
                                        {/* <img
                                            src="/assets/images/pdf.png"
                                            alt="option pdf"
                                            title="Click here to view answer pdf"
                                            onClick={() =>
                                              this.openModal(
                                                optionpdf,
                                                "PDF"
                                              )
                                            }
                                          /> */}
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            })
                            : ""}
                          <span className="srv-validation-message">
                            {this.state.PdfAnswerRequried === false
                              ? "Pdf options are required."
                              : ""}
                          </span>
                        </div>
                        {/* Option Pdf end */}
                        {/* Correct answer start*/}
                        <div className="form-group row">
                          <div className="col-md-12" data-select2-id="11">
                            <label className="control-label col-form-label label-text">
                              Correct Answer<sup className="req-color">*</sup>
                            </label>
                            <select
                              className="select2 form-control custom-select select2-hidden-accessible"
                              data-select2-id="1"
                              aria-hidden="true"
                              name="correctAnswer"
                              onChange={(e) => this.handleChange(e)}
                              value={
                                this.state.correctAnswer
                              }
                              disabled={
                                this.state.quiz_status == "2" ? "" : "disabled"
                              }
                            // disabled={
                            //   this.state.optionIds[this.state.correctAnswer]
                            // }
                            >
                              <option value="">Select Correct Answer</option>
                              {this.state.optionCorrectAnswer.length > 0
                                ? this.state.optionCorrectAnswer.map(
                                  (answer, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={this.state.optionIds[index]}
                                      >
                                        Option {index + 1}
                                      </option>
                                    );
                                  }
                                )
                                : ""}
                            </select>
                            {this.validator.message(
                              "Correct Answer",
                              this.state.correctAnswer,
                              "required"
                            )}
                          </div>
                        </div>
                        {/* Correct answer end */}
                        {/* Answer options End */}
                        {/* Explanation Start */}
                        <h4 className="card-title">Explanation</h4>
                        <div className="form-group row">
                          <div className="col-sm-6">
                            <button
                              type="button"
                              className="btn btn-newui"
                              name="explanationMedia"
                              onClick={(e) =>
                                this.addExplantion(
                                  e,
                                  this.state.explanationMedia ? false : true
                                )
                              }
                            >
                              Add explanation
                            </button>
                          </div>
                          <div className="col-sm-6" />
                        </div>
                        {this.state.explantionHtml.map((form, index) => (
                          <AddExplantionForm
                            key={index}
                            is_disable={
                              this.state.quiz_status == "2" ? false : true
                            }
                            objKey={index}
                            validImage_error={this.validImage_error}
                            validVideo_error={this.validVideo_error}
                            validPdf_error={this.validPdf_error}
                            validUrl_error={this.validUrl_error}
                            explanationObj={this.state.explanationObj[index]}
                            model={this.openModal}
                          />
                        ))}

                        {/* Explanation end */}
                        <div className="">
                          <div className="">
                            <button
                              type="button"
                              className="btn btn-primary-newui btn-md-newui"
                              disabled={this.state.IsSubmit}
                              onClick={(e) => this.updateQuetionAnswer(e)}
                            >
                              Save
                            </button>
                            <NavLink
                              to="/questionlist"
                              type="button"
                              className="btn btn-primary-newui btn-md-newui"
                            >
                              Cancel
                            </NavLink>

                            <button
                              type="button"
                              className="btn btn-primary-newui btn-md-newui"
                              onClick={() => this.handleModal()}
                            >
                              Preview
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal show={this.state.show} onHide={() => this.handleModal()}>
            <Modal.Header>Question Preview</Modal.Header>
            <Modal.Body>
              <div className="border">
                <div className="question bg-white p-3 border-bottom">
                  <div className="d-flex flex-row align-items-center question-title">
                    <h3 className="text-danger">Q.</h3>
                    <h5 className="mt-1 ml-2">{this.state.question}</h5>
                  </div>

                  {this.state.existquestionImagesURL.length > 0 ? (
                    <div>
                      {this.state.existquestionImagesURL[0] ? (
                        <div className="ans ml-2">
                          1.
                          <img
                            src={
                              this.state.existquestionImagesURL[0]
                            }
                            alt="QuestionImage"
                            style={{ width: "200px" }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.existquestionImagesURL[1] ? (
                        <div className="ans ml-2">
                          2.
                          <img
                            src={
                              this.state.existquestionImagesURL[1]
                            }
                            alt="QuestionImage"
                            style={{ width: "200px" }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.existquestionImagesURL[2] ? (
                        <div className="ans ml-2">
                          3.
                          <img
                            src={
                              this.state.existquestionImagesURL[2]
                            }
                            alt="QuestionImage"
                            style={{ width: "200px" }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.existquestionImagesURL[3] ? (
                        <div className="ans ml-2">
                          4.
                          <img
                            src={
                              this.state.existquestionImagesURL[3]
                            }
                            alt="QuestionImage"
                            style={{ width: "200px" }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.existquestionImagesURL[4] ? (
                        <div className="ans ml-2">
                          5.
                          <img
                            src={
                              this.state.existquestionImagesURL[4]
                            }
                            alt="QuestionImage"
                            style={{ width: "200px" }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {this.state.questionImagesIn.length > 0 ? (
                    <div>
                      {this.state.questionImagesIn[0] ? (
                        <div className="ans ml-2">
                          1.
                          <img
                            src={
                              this.state.questionImagesIn[0]
                            }
                            alt="QuestionImage"
                            style={{ width: "200px" }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.questionImagesIn[1] ? (
                        <div className="ans ml-2">
                          2.
                          <img
                            src={
                              this.state.questionImagesIn[1]
                            }
                            alt="QuestionImage"
                            style={{ width: "200px" }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.questionImagesIn[2] ? (
                        <div className="ans ml-2">
                          3.
                          <img
                            src={
                              this.state.questionImagesIn[2]
                            }
                            alt="QuestionImage"
                            style={{ width: "200px" }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.questionImagesIn[3] ? (
                        <div className="ans ml-2">
                          4.
                          <img
                            src={
                              this.state.questionImagesIn[3]
                            }
                            alt="QuestionImage"
                            style={{ width: "200px" }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.questionImagesIn[4] ? (
                        <div className="ans ml-2">
                          5.
                          <img
                            src={
                              this.state.questionImagesIn[4]
                            }
                            alt="QuestionImage"
                            style={{ width: "200px" }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {this.state.existquestionVideosURL.length > 0 ? (
                    <div>
                      {this.state.existquestionVideosURL[0] ? (
                        <div className="ans ml-2">
                          1.
                          <video width="320" height="240" controls>
                            <source
                              src={


                                this.state.existquestionVideosURL[0]
                              }
                              type="video/mp4"
                            />
                          </video>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.existquestionVideosURL[1] ? (
                        <div className="ans ml-2">
                          2.
                          <video width="320" height="240" controls>
                            <source
                              src={


                                this.state.existquestionVideosURL[1]
                              }
                              type="video/mp4"
                            />
                          </video>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.existquestionVideosURL[2] ? (
                        <div className="ans ml-2">
                          3.
                          <video width="320" height="240" controls>
                            <source
                              src={


                                this.state.existquestionVideosURL[2]
                              }
                              type="video/mp4"
                            />
                          </video>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.existquestionVideosURL[3] ? (
                        <div className="ans ml-2">
                          4.
                          <video width="320" height="240" controls>
                            <source
                              src={


                                this.state.existquestionVideosURL[3]
                              }
                              type="video/mp4"
                            />
                          </video>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.existquestionVideosURL[4] ? (
                        <div className="ans ml-2">
                          5.
                          <video width="320" height="240" controls>
                            <source
                              src={


                                this.state.existquestionVideosURL[4]
                              }
                              type="video/mp4"
                            />
                          </video>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {this.state.questionVideosIn.length > 0 ? (
                    <div>
                      {this.state.questionVideosIn[0] ? (
                        <div className="ans ml-2">
                          1.
                          <video width="320" height="240" controls>
                            <source
                              src={
                                this.state.questionVideosIn[0]
                              }
                              type="video/mp4"
                            />
                          </video>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.questionVideosIn[1] ? (
                        <div className="ans ml-2">
                          2.
                          <video width="320" height="240" controls>
                            <source
                              src={
                                this.state.questionVideosIn[1]
                              }
                              type="video/mp4"
                            />
                          </video>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.questionVideosIn[2] ? (
                        <div className="ans ml-2">
                          3.
                          <video width="320" height="240" controls>
                            <source
                              src={
                                this.state.questionVideosIn[2]
                              }
                              type="video/mp4"
                            />
                          </video>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.questionVideosIn[3] ? (
                        <div className="ans ml-2">
                          4.
                          <video width="320" height="240" controls>
                            <source
                              src={
                                this.state.questionVideosIn[3]
                              }
                              type="video/mp4"
                            />
                          </video>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.questionVideosIn[4] ? (
                        <div className="ans ml-2">
                          5.
                          <video width="320" height="240" controls>
                            <source
                              src={
                                this.state.questionVideosIn[4]
                              }
                              type="video/mp4"
                            />
                          </video>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {this.state.existquestionpdfURL.length > 0 ? (
                    <div>
                      {this.state.existquestionpdfURL[0] ? (
                        <div className="ans ml-2">
                          1.
                          <a
                            href={
                              this.state.existquestionpdfURL[0]
                            }
                            alt="existquestionpdf"
                            style={{ width: "199px", marginLeft: "20px" }}
                            target="_blank"
                          >
                            pdf1
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.existquestionpdfURL[1] ? (
                        <div className="ans ml-2">
                          2.
                          <a
                            href={
                              this.state.existquestionpdfURL[1]
                            }
                            alt="existquestionpdf"
                            style={{ width: "199px", marginLeft: "20px" }}
                            target="_blank"
                          >
                            pdf1
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.existquestionpdfURL[2] ? (
                        <div className="ans ml-2">
                          3.
                          <a
                            href={
                              this.state.existquestionpdfURL[2]
                            }
                            alt="existquestionpdf"
                            style={{ width: "199px", marginLeft: "20px" }}
                            target="_blank"
                          >
                            pdf1
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.existquestionpdfURL[3] ? (
                        <div className="ans ml-2">
                          4.
                          <a
                            href={
                              this.state.existquestionpdfURL[3]
                            }
                            alt="existquestionpdf"
                            style={{ width: "199px", marginLeft: "20px" }}
                            target="_blank"
                          >
                            pdf1
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.existquestionpdfURL[4] ? (
                        <div className="ans ml-2">
                          5.
                          <a
                            href={
                              this.state.existquestionpdfURL[4]
                            }
                            alt="existquestionpdf"
                            style={{ width: "199px", marginLeft: "20px" }}
                            target="_blank"
                          >
                            pdf1
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {this.state.questionpdfIn.length > 0 ? (
                    <div>
                      {this.state.questionpdfIn[0] ? (
                        <div className="ans ml-2">
                          1.
                          <a
                            href={this.state.questionpdfIn[0]}
                            alt="demo"
                            style={{ width: "200px", marginLeft: "20px" }}
                            target="_blank"
                          >
                            pdf1
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.questionpdfIn[1] ? (
                        <div className="ans ml-2">
                          2.
                          <a
                            href={this.state.questionpdfIn[1]}
                            alt="demo"
                            style={{ width: "200px", marginLeft: "20px" }}
                            target="_blank"
                          >
                            pdf1
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.questionpdfIn[2] ? (
                        <div className="ans ml-2">
                          3.
                          <a
                            href={this.state.questionpdfIn[2]}
                            alt="demo"
                            style={{ width: "200px", marginLeft: "20px" }}
                            target="_blank"
                          >
                            pdf1
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.questionpdfIn[3] ? (
                        <div className="ans ml-2">
                          4.
                          <a
                            href={this.state.questionpdfIn[3]}
                            alt="demo"
                            style={{ width: "200px", marginLeft: "20px" }}
                            target="_blank"
                          >
                            pdf1
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.questionpdfIn[4] ? (
                        <div className="ans ml-2">
                          5.
                          <a
                            href={this.state.questionpdfIn[4]}
                            alt="demo"
                            style={{ width: "200px", marginLeft: "20px" }}
                            target="_blank"
                          >
                            pdf1
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  <h3 className="text-danger">Options</h3>

                  {this.state.optionTextContent === true ? (
                    <div>
                      <div className="ans ml-2">
                        <label className="radio">
                          1. <span>{this.state.optionTexts[0]}</span>
                        </label>
                      </div>
                      <div className="ans ml-2">
                        <label className="radio">
                          2. <span>{this.state.optionTexts[1]}</span>
                        </label>
                      </div>
                      <div className="ans ml-2">
                        <label className="radio">
                          3. <span>{this.state.optionTexts[2]}</span>
                        </label>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {this.state.optionImageMedia === true ? (
                    <div>
                      {this.state.optionImages[0] &&
                        this.state.optionImagesIn[0] == "" ? (
                        <div className="ans ml-2">
                          <label className="radio">
                            1.
                            <img
                              src={this.state.optionImages[0]}
                              alt="oldoptionimg"
                              style={{ width: "200px" }}
                            />
                          </label>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.optionImagesIn[0] ? (
                        <div className="ans ml-2">
                          <label className="radio">
                            1.
                            <img
                              src={
                                this.state.optionImagesIn[0]
                              }
                              alt="newuploadedoptionimage"
                              style={{ width: "200px" }}
                            />
                          </label>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.optionImages[1] &&
                        this.state.optionImagesIn[1] == "" ? (
                        <div className="ans ml-2">
                          2.
                          <img
                            src={this.state.optionImages[1]}
                            alt="oldoptionimg"
                            style={{ width: "200px" }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.optionImagesIn[1] ? (
                        <div className="ans ml-2">
                          2.
                          <img
                            src={this.state.optionImagesIn[1]}
                            alt="newuploadedoptionimage"
                            style={{ width: "200px" }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.optionImages[2] &&
                        this.state.optionImagesIn[2] == "" ? (
                        <div className="ans ml-2">
                          3.
                          <img
                            src={this.state.optionImages[2]}
                            alt="oldoptionimg"
                            style={{ width: "200px" }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.optionImagesIn[2] ? (
                        <div className="ans ml-2">
                          3.
                          <img
                            src={this.state.optionImagesIn[2]}
                            alt="newuploadedoptionimage"
                            style={{ width: "200px" }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {this.state.optionVideoMedia === true ? (
                    <div>
                      {this.state.optionVideosIn[0] == "" &&
                        this.state.optionVideos[0] ? (
                        <div className="ans ml-2">
                          <label className="radio">
                            1.
                            <video width="320" height="240" controls>
                              <source
                                src={
                                  this.state.optionVideos[0]
                                }
                                type="video/mp4"
                              />
                            </video>
                          </label>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.optionVideosIn[0] ? (
                        <div className="ans ml-2">
                          <label className="radio">
                            1.
                            <video width="320" height="240" controls>
                              <source
                                src={
                                  this.state.optionVideosIn[0]
                                }
                                type="video/mp4"
                              />
                            </video>
                          </label>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.optionVideosIn[1] == "" &&
                        this.state.optionVideos[1] ? (
                        <div className="ans ml-2">
                          <label className="radio">
                            2.
                            <video width="320" height="240" controls>
                              <source
                                src={
                                  this.state.optionVideos[1]
                                }
                                type="video/mp4"
                              />
                            </video>
                          </label>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.optionVideosIn[1] ? (
                        <div className="ans ml-2">
                          <label className="radio">
                            2.
                            <video width="320" height="240" controls>
                              <source
                                src={
                                  this.state.optionVideosIn[1]
                                }
                                type="video/mp4"
                              />
                            </video>
                          </label>
                        </div>
                      ) : (
                        ""
                      )}

                      {this.state.optionVideosIn[2] == "" &&
                        this.state.optionVideos[2] ? (
                        <div className="ans ml-2">
                          <label className="radio">
                            3.
                            <video width="320" height="240" controls>
                              <source
                                src={
                                  this.state.optionVideos[2]
                                }
                                type="video/mp4"
                              />
                            </video>
                          </label>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.optionVideosIn[2] ? (
                        <div className="ans ml-2">
                          <label className="radio">
                            3.
                            <video width="320" height="240" controls>
                              <source
                                src={
                                  this.state.optionVideosIn[2]
                                }
                                type="video/mp4"
                              />
                            </video>
                          </label>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {this.state.optionPdfMedia === true ? (
                    <div>
                      {this.state.optionPdfsIn[0] == "" &&
                        this.state.optionPdfs[0] ? (
                        <div className="ans ml-2">
                          <label className="radio">
                            1.
                            <a
                              href={this.state.optionPdfs[0]}
                              alt="demo"
                              style={{ width: "200px" }}
                              target="_blank"
                            >
                              PDF1
                            </a>
                          </label>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.optionPdfsIn[0] ? (
                        <div className="ans ml-2">
                          <label className="radio">
                            1.
                            <a
                              href={
                                this.state.optionPdfsIn[0]
                              }
                              alt="demo"
                              style={{ width: "200px" }}
                              target="_blank"
                            >
                              PDF1
                            </a>
                          </label>
                        </div>
                      ) : (
                        ""
                      )}

                      {this.state.optionPdfsIn[1] == "" &&
                        this.state.optionPdfs[1] ? (
                        <div className="ans ml-2">
                          <label className="radio">
                            2.
                            <a
                              href={this.state.optionPdfs[1]}
                              alt="demo"
                              style={{ width: "200px" }}
                              target="_blank"
                            >
                              PDF2
                            </a>
                          </label>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.optionPdfsIn[1] ? (
                        <div className="ans ml-2">
                          <label className="radio">
                            2.
                            <a
                              href={
                                this.state.optionPdfsIn[1]
                              }
                              alt="demo"
                              style={{ width: "200px" }}
                              target="_blank"
                            >
                              PDF2
                            </a>
                          </label>
                        </div>
                      ) : (
                        ""
                      )}

                      {this.state.optionPdfsIn[2] == "" &&
                        this.state.optionPdfs[2] ? (
                        <div className="ans ml-2">
                          <label className="radio">
                            3.
                            <a
                              href={this.state.optionPdfs[2]}
                              alt="demo"
                              style={{ width: "200px" }}
                              target="_blank"
                            >
                              PDF3
                            </a>
                          </label>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.optionPdfsIn[2] ? (
                        <div className="ans ml-2">
                          <label className="radio">
                            3.
                            <a
                              href={
                                this.state.optionPdfsIn[2]
                              }
                              alt="demo"
                              style={{ width: "200px" }}
                              target="_blank"
                            >
                              PDF3
                            </a>
                          </label>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="d-flex flex-row align-items-center question-title">
                    <h3 className="text-danger">Explanation.</h3>
                  </div>
                  {this.props.explanation.length > 0
                    ? this.props.explanation.map((explanation, index) => {
                      return (
                        <>
                          <p key={index}>
                            {explanation.explanation_type === "2" ? (
                              <div>
                                {explanation.explanation_media &&
                                  explanation.url_media == "" ? (
                                  <img
                                    src={


                                      explanation.explanation_media
                                    }
                                    alt="demo"
                                    style={{ width: "200px" }}
                                  />
                                ) : (
                                  <img
                                    src={
                                      explanation.url_media
                                    }
                                    alt="demo"
                                    style={{ width: "200px" }}
                                  />
                                )}
                              </div>
                            ) : null}

                            {explanation.explanation_type === "3" ? (
                              <div>
                                {explanation.explanation_media &&
                                  explanation.url_media == "" ? (
                                  <video width="320" height="240" controls>
                                    <source
                                      src={


                                        explanation.explanation_media
                                      }
                                      type="video/mp4"
                                    />
                                  </video>
                                ) : (
                                  <video width="320" height="240" controls>
                                    <source
                                      src={
                                        explanation.url_media
                                      }
                                      type="video/mp4"
                                    />
                                  </video>
                                )}
                              </div>
                            ) : null}
                            {explanation.explanation_type === "4" ? (
                              <div>
                                {explanation.explanation_media &&
                                  explanation.url_media == "" ? (
                                  <a
                                    href={

                                      explanation.explanation_media
                                    }
                                    style={{ width: "200px" }}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    PDF
                                  </a>
                                ) : (
                                  <a
                                    href={
                                      explanation.url_media
                                    }
                                    style={{ width: "200px" }}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    PDF
                                  </a>
                                )}
                              </div>
                            ) : null}

                            {explanation.explanation_type === "5"
                              ? explanation.explanation_media
                              : null}

                            {explanation.explanation_type === "1"
                              ? explanation.explanation_media
                              : null}
                          </p>
                        </>
                      );
                    })
                    : ""}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-primary-newui btn-md-newui"
                onClick={() => this.handleModal()}
              >
                Close
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    explanation: state.QuestionReducer,
    load_category: state.LoadCategory,
    load_quiz: state.LoadQuiz,
  };
};
export default connect(mapStateToProps)(EditQuestion);
