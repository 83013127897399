import { combineReducers } from "redux";
import UserAction from "./user-ruducer";
import QuestionReducer from "./question-ruducer.";
import LoadCategory from "./category-reducer";
import LoadQuiz from "./quiz-reducer";
import Loadspecialities from "./specialities-reducer";
import LoadActionType from "./action-type-reducer";
import LoadUserName from "./username-reducer";
const allRuducer = combineReducers({
  UserAction,
  QuestionReducer,
  LoadCategory,
  LoadQuiz,
  Loadspecialities,
  LoadActionType,
  LoadUserName
});
export default allRuducer;
