import React, { Component } from "react";
import { connect } from "react-redux";
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return <Dashboard />;
  }
}

const mapStateProps = (state, props) => {
  return {
    userdata: state
  };
};

export default connect(mapStateProps)(Dashboard);
