import React, { Component } from 'react';
import QuestionListCustom from '../../component/question/question-list-custom';
import logout from '../../component/dashboard/logout';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { GetError } from '../../component/action';
import API from '../../api';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { GetAllCategory } from '../../action/category-action';
import { GetAllQuiz } from '../../action/quiz-action';
class QuestionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      question_data: [],
      modalIsOpen: false,
      modelContent: '',
      modelContentType: '',
      quizlistoption: [],
      filter_quiz_name: 0,
      page_size: 10,
      resultCount: null,
      noOfPages: 1,
      currentPage: 1,
      lastPage: 0,
      prevPage: null,
      nextPage: null,
      pagination: '',
      searchTerm: '',
      sortName: 'quiz_name',
      sortOrder: 'asc',
      isLoading: false,
      filter_category_name: 0
    };
  }
  openModal = (value, type) => {
    this.setState({
      modalIsOpen: true,
      modelContent: value,
      modelContentType: type
    });
  };

  afterOpenModal = () => {
    // references are now sync'd and can be accessed.
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };
  componentWillMount() {
    this.loadQuestionList();
    this.props.dispatch(GetAllCategory());
    this.props.dispatch({ type: 'DEFAULT_STATE' });
  }

  changeStatus = (question_id, status) => {
    let self = this;
    this.setState({
      isLoading: true
    });
    let params = new FormData();
    params.append('status', status);

    API.post(`quiz/update_question/${question_id}`, params)
      .then(res => {
        if (res.status === 200) {
          if (status === 1) {
            toast.success('Question is active.');
          } else {
            toast.success('Question is inactive.');
          }
        } else {
          toast.error('Something went wrong on server end.');
        }
        this.setState(
          {
            isLoading: false,
            question_data: []
          },
          () => {
            this.loadQuestionList();
          }
        );
      })
      .catch(function(error) {
        let errorObj = GetError(error);
        if (errorObj.status === 400) {
          if (self.state.quizlistoption.length > 0) {
            toast.error(errorObj.data.message);
          }
        } else if (errorObj.data.status === 401) {
          toast.error(errorObj.data.message);
          setTimeout(function() {
            logout(this.props);
          }, 2000);
        } else {
          toast.error('Something went wrong on server end.');
        }
        self.setState({
          isLoading: false
        });
      });
  };
  removeQuestion = question_id => {
    let self = this;
    this.setState({
      isLoading: true
    });
    confirmAlert({
      title: 'Confirm',
      message: 'Are you sure you want to delete this question?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            API.delete(`quiz/question/${question_id}`)
              .then(res => {
                if (res.data.status === 200) {
                  toast.success(res.data.message);
                }
                this.setState(
                  {
                    isLoading: false,
                    question_data: []
                  },
                  () => {
                    this.loadQuestionList();
                  }
                );
              })
              .catch(function(error) {
                let errorObj = GetError(error);
                if (errorObj.status === 400) {
                  if (self.state.quizlistoption.length > 0) {
                    toast.error(errorObj.data.message);
                  }
                } else if (errorObj.data.status === 401) {
                  toast.error(errorObj.data.message);
                  setTimeout(function() {
                    logout(this.props);
                  }, 2000);
                } else {
                  toast.error('Something went wrong on server end.');
                }
                self.setState({
                  isLoading: false
                });
              });
          }
        },
        {
          label: 'No',
          onClick: () => {
            this.setState({
              isLoading: false
            });
          }
        }
      ]
    });
  };
  loadQuestionList() {
    let self = this;
    this.setState({
      isLoading: true,
      question_data: [],
      resultCount: null,
      noOfPages: 1,
      nextPage: null,
      prevPage: null,
      lastPage: 0
    });

    setTimeout(() => {
      API.get(
        `quiz/question_all/${
          this.state.filter_quiz_name ? this.state.filter_quiz_name : 0
        }/${
          this.state.filter_category_name ? this.state.filter_category_name : 0
        }?order_by=${this.state.sortName}&order=${this.state.sortOrder}&limit=${
          this.state.page_size
        }&searchTerm=${this.state.searchTerm}&page=${this.state.currentPage}`
      )
        .then(res => {
          if (res.status === 200) {
            let nextUrl =
              res.data.data.nextlink !== '' ? res.data.data.nextlink : null;
            let prevUrl =
              res.data.data.prevlink !== ''
                ? res.data.data.prevlink === ''
                  ? 1
                  : res.data.data.prevlink
                : null;
            this.setState({
              question_data: res.data.data.question,
              resultCount: res.data.data.total_rows,
              isLoading: false,
              noOfPages: Math.ceil(
                res.data.data.total_rows / this.state.page_size
              ),
              nextPage: nextUrl,
              prevPage: prevUrl,
              lastPage: Math.ceil(
                res.data.data.total_rows / this.state.page_size
              )
            });
          }
        })
        .catch(function(erorr) {
          let errorobj = GetError(erorr);
          self.setState({
            isLoading: false
          });
          if (errorobj.status === 400) {
            self.setState({ question_data: [] }, () => {
              if (self.state.question_data.length > 0) {
                toast.error(errorobj.data.message);
              }
            });
          } else if (errorobj.status === 401) {
            toast.error(errorobj.data.message);
            setTimeout(function() {
              logout(self.props);
            }, 2000);
          } else if (errorobj.status === 403) {
            toast.error(errorobj.data.message);
            setTimeout(function() {
              logout(self.props);
            }, 2000);
          } else {
            toast.error('Something went wrong on server end.');
          }
        });
    }, 100);
  }

  handleSort = (sortName, prevSortOrder) => {
    let sortOrder = prevSortOrder === 'asc' ? 'desc' : 'asc';
    this.setState({ sortName, sortOrder }, () => {
      this.loadQuestionList();
    });
  };
  handleChange = event => {
    this.setState(
      {
        [event.target.name]: event.target.value,
        currentPage: 1,
        prevPage: null,
        nextPage: this.state.noOfPages > 1 ? 2 : null
      },
      () => {
        this.props.dispatch(GetAllQuiz(this.state.filter_category_name));
        this.loadQuestionList();
      }
    );
  };
  gotoPage = (page, totalPages) => {
    if (page && page !== this.state.currentPage) {
      this.setState(
        {
          currentPage: page
        },
        () => {
          this.loadQuestionList();
        }
      );
    }
  };

  render() {
    return (
      <QuestionListCustom
        propsState={this.state}
        afterOpenModal={this.afterOpenModal}
        closeModal={this.closeModal}
        handleChange={this.handleChange}
        handleSort={this.handleSort}
        openModal={this.openModal}
        changeStatus={this.changeStatus}
        removeQuestion={this.removeQuestion}
        gotoPage={this.gotoPage}
        category={this.props.load_category}
        quiz={this.props.load_quiz}
      />
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    load_category: state.LoadCategory,
    load_quiz: state.LoadQuiz
  };
};
export default connect(mapStateToProps)(QuestionList);
