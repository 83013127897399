import React, { Component } from 'react';
import Loader from '../dashboard/loader';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Pagination } from '../action';
import {
  ExcelExport,
  ExcelExportColumn
} from '@progress/kendo-react-excel-export';
import PropTypes from 'prop-types';
import logout from '../../component/dashboard/logout';
class QuizDetailReportsCustom extends Component {
  _exporter;
  export = () => {
    let token = localStorage.getItem('token');
    if (token !== null) {
      this._exporter.save();
    } else {
      logout(this.props.props);
    }
  };
  render() {
    return (
      <div>
        <Loader isLoading={this.props.propsState.isLoading} />
        <Loader isLoadingExport={this.props.propsState.isLoadingExport} />
        <div className='page-wrapper'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12'>
                <div className='card'>
                  <h4 className='card-title m-b-0'>Quiz Detail Report</h4>
                  <div className='card-body'>
                    <div className='row'>
                      
                      <div className='col-md-3' data-select2-id='11'>
                        <b className='filter_head'>Filter by category title</b>
                        <select
                          className='select2 form-control custom-select select2-hidden-accessible'
                          aria-hidden='true'
                          name='filter_category'
                          onChange={e => this.props.handleChange(e)}
                        >
                          <option value=''>Select category title</option>
                          {this.props.category.length > 0
                            ? this.props.category.map((category, index) => {
                              if (category.status === '1') {
                                return (
                                  <option
                                    key={index}
                                    value={category.category_id}
                                  >
                                    {category.category_name}
                                  </option>
                                );
                              }
                            })
                            : ''}
                        </select>
                      </div>

                      <div className='col-md-2' data-select2-id='11'>
                        <b className='filter_head'>Filter by quiz title</b>
                        <select
                          className='select2 form-control custom-select select2-hidden-accessible'
                          aria-hidden='true'
                          name='filter_quiz_name'
                          onChange={e => this.props.handleChange(e)}
                        >
                          <option value=''>Select quiz title</option>
                          {this.props.quiz.length > 0
                            ? this.props.quiz.map((quiz, index) => {
                              if (quiz.status === '1') {
                                return (
                                  <option key={index} value={quiz.quiz_id}>
                                    {quiz.quiz_name}
                                  </option>
                                );
                              }
                            })
                            : ''}
                        </select>
                      </div>

                      <div className='col-md-2' data-select2-id='11'>
                        <b className='filter_head'>Filter by start date</b>
                        <DatePicker
                          className='select2 form-control custom-select select2-hidden-accessible'
                          selected={this.props.propsState.filter_start_date}
                          onChange={this.props.onChange}
                          dateFormat='MM/dd/yyyy'
                          placeholderText='Select start date'
                        />
                      </div>
                      <div className='col-md-2' data-select2-id='11'>
                        <b className='filter_head'>Filter by status</b>
                        <select
                          className='select2 form-control custom-select select2-hidden-accessible'
                          data-select2-id='1'
                          aria-hidden='true'
                          name='filter_quiz_status'
                          onChange={e => this.props.handleChange(e)}
                        >
                          <option value=''>Select status</option>
                          return (<option value='1'>Pending</option>
                          <option value='2'>Completed</option>
                          );
                        </select>
                      </div>

                      <div className='col-md-3' data-select2-id='11'>
                        <b className='filter_head'>Filter by quiz type</b>
                        <select
                          className='select2 form-control custom-select select2-hidden-accessible'
                          aria-hidden='true'
                          name='filter_quiz_req_status'
                          onChange={e => this.props.handleChange(e)}
                        >
                          <option value=''>All</option>
                          <option value='1'>Required</option>
                          <option value='0'>Non-Required</option>
                        </select>
                      </div>      

                      
                    </div>
                    
                    <div className='row mt-4'>
                      <div className='col-sm-12 col-md-7'>
                        <div
                          className='dataTables_length'
                          id='zero_config_length'
                        >
                          <label className='table-label'>
                            <span className='table-span'>Show entries</span>
                            <select
                              name='page_size'
                              aria-controls='zero_config'
                              className='form-control form-control-sm'
                              value={this.props.propsState.page_size}
                              onChange={e => this.props.handleChange(e)}
                            >
                              <option value='10'>10</option>
                              <option value='25'>25</option>
                              <option value='50'>50</option>
                              <option value='100'>100</option>
                            </select>
                          </label>
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-3 text-right'>
                        <div
                          id='zero_config_filter'
                          className='dataTables_filter'
                        >
                          <label className='text-left'>
                            <span>Search:</span>
                            <input
                              type='search'
                              className='form-control form-control-sm'
                              placeholder='Search'
                              aria-controls='zero_config'
                              name='searchTerm'
                              id='searchTerm'
                              value={this.props.propsState.searchTerm}
                              onChange={e => this.props.handleChange(e)}
                            />
                          </label>
                        </div>
                      </div>
                      <div className='col-md-2 text-right' data-select2-id='11'>
                        <button
                          className='dt-button buttons-excel'
                          onClick={e => this.props.exportReport(e)}
                        >
                          <span>Excel</span>
                        </button>
                      </div>
                    </div>
                    
                    <div className='table-responsive'>
                      <table id='zero_config' className='table'>
                        <thead>
                          <tr>
                            <th
                              onClick={() =>
                                this.props.handleSort(
                                  'fullname',
                                  this.props.propsState.sortOrder
                                )
                              }
                            >
                              User
                              {this.props.propsState.sortName === 'fullname' ? (
                                <span className='sort-active'>
                                  {this.props.propsState.sortOrder === 'asc' ? (
                                    <i className='fas fa-sort-amount-up' />
                                  ) : (
                                    <i className='fas fa-sort-amount-down' />
                                  )}
                                </span>
                              ) : (
                                <span className='sort-inactive'>
                                  <i className='fas fa-sort-amount-up' />
                                </span>
                              )}
                            </th>
                            <th
                              onClick={() =>
                                this.props.handleSort(
                                  'category_name',
                                  this.props.propsState.sortOrder
                                )
                              }
                            >
                              Category Name
                              {this.props.propsState.sortName ===
                                'category_name' ? (
                                <span className='sort-active'>
                                  {this.props.propsState.sortOrder === 'asc' ? (
                                    <i className='fas fa-sort-amount-up' />
                                  ) : (
                                    <i className='fas fa-sort-amount-down' />
                                  )}
                                </span>
                              ) : (
                                <span className='sort-inactive'>
                                  <i className='fas fa-sort-amount-up' />
                                </span>
                              )}
                            </th>
                            <th
                              onClick={() =>
                                this.props.handleSort(
                                  'quiz_name',
                                  this.props.propsState.sortOrder
                                )
                              }
                            >
                              Quiz Title
                              {this.props.propsState.sortName ===
                                'quiz_name' ? (
                                <span className='sort-active'>
                                  {this.props.propsState.sortOrder === 'asc' ? (
                                    <i className='fas fa-sort-amount-up' />
                                  ) : (
                                    <i className='fas fa-sort-amount-down' />
                                  )}
                                </span>
                              ) : (
                                <span className='sort-inactive'>
                                  <i className='fas fa-sort-amount-up' />
                                </span>
                              )}
                            </th>
                            <th
                              onClick={() =>
                                this.props.handleSort(
                                  'retake_count',
                                  this.props.propsState.sortOrder
                                )
                              }
                              className='text-center'
                            >
                              Retake Count
                              {this.props.propsState.sortName ===
                                'retake_count' ? (
                                <span className='sort-active'>
                                  {this.props.propsState.sortOrder === 'asc' ? (
                                    <i className='fas fa-sort-amount-up' />
                                  ) : (
                                    <i className='fas fa-sort-amount-down' />
                                  )}
                                </span>
                              ) : (
                                <span className='sort-inactive'>
                                  <i className='fas fa-sort-amount-up' />
                                </span>
                              )}
                            </th>
                            <th
                              onClick={() =>
                                this.props.handleSort(
                                  'attend_count',
                                  this.props.propsState.sortOrder
                                )
                              }
                              className='text-center'
                            >
                              Session Count
                              {this.props.propsState.sortName ===
                                'attend_count' ? (
                                <span className='sort-active'>
                                  {this.props.propsState.sortOrder === 'asc' ? (
                                    <i className='fas fa-sort-amount-up' />
                                  ) : (
                                    <i className='fas fa-sort-amount-down' />
                                  )}
                                </span>
                              ) : (
                                <span className='sort-inactive'>
                                  <i className='fas fa-sort-amount-up' />
                                </span>
                              )}
                            </th>
                            <th
                              onClick={() =>
                                this.props.handleSort(
                                  'quiz_start_date',
                                  this.props.propsState.sortOrder
                                )
                              }
                            >
                              Start Date
                              {this.props.propsState.sortName ===
                                'quiz_start_date' ? (
                                <span className='sort-active'>
                                  {this.props.propsState.sortOrder === 'asc' ? (
                                    <i className='fas fa-sort-amount-up' />
                                  ) : (
                                    <i className='fas fa-sort-amount-down' />
                                  )}
                                </span>
                              ) : (
                                <span className='sort-inactive'>
                                  <i className='fas fa-sort-amount-up' />
                                </span>
                              )}
                            </th>
                            <th
                              onClick={() =>
                                this.props.handleSort(
                                  'quiz_end_date',
                                  this.props.propsState.sortOrder
                                )
                              }
                            >
                              End Date
                              {this.props.propsState.sortName ===
                                'quiz_end_date' ? (
                                <span className='sort-active'>
                                  {this.props.propsState.sortOrder === 'asc' ? (
                                    <i className='fas fa-sort-amount-up' />
                                  ) : (
                                    <i className='fas fa-sort-amount-down' />
                                  )}
                                </span>
                              ) : (
                                <span className='sort-inactive'>
                                  <i className='fas fa-sort-amount-up' />
                                </span>
                              )}
                            </th>
                            <th
                              onClick={() =>
                                this.props.handleSort(
                                  'quiz_percentage',
                                  this.props.propsState.sortOrder
                                )
                              }
                              className='text-center'
                            >
                              Percentage %
                              {this.props.propsState.sortName ===
                                'quiz_percentage' ? (
                                <span className='sort-active'>
                                  {this.props.propsState.sortOrder === 'asc' ? (
                                    <i className='fas fa-sort-amount-up' />
                                  ) : (
                                    <i className='fas fa-sort-amount-down' />
                                  )}
                                </span>
                              ) : (
                                <span className='sort-inactive'>
                                  <i className='fas fa-sort-amount-up' />
                                </span>
                              )}
                            </th>
                            <th
                              onClick={() =>
                                this.props.handleSort(
                                  'TakenDays',
                                  this.props.propsState.sortOrder
                                )
                              }
                              className='text-center'
                            >
                              Time Taken
                              {this.props.propsState.sortName ===
                                'TakenDays' ? (
                                <span className='sort-active'>
                                  {this.props.propsState.sortOrder === 'asc' ? (
                                    <i className='fas fa-sort-amount-up' />
                                  ) : (
                                    <i className='fas fa-sort-amount-down' />
                                  )}
                                </span>
                              ) : (
                                <span className='sort-inactive'>
                                  <i className='fas fa-sort-amount-up' />
                                </span>
                              )}
                            </th>
                            <th
                              onClick={() =>
                                this.props.handleSort(
                                  'quiz_status',
                                  this.props.propsState.sortOrder
                                )
                              }
                            >
                              Status
                              {this.props.propsState.sortName ===
                                'quiz_status' ? (
                                <span className='sort-active'>
                                  {this.props.propsState.sortOrder === 'asc' ? (
                                    <i className='fas fa-sort-amount-up' />
                                  ) : (
                                    <i className='fas fa-sort-amount-down' />
                                  )}
                                </span>
                              ) : (
                                <span className='sort-inactive'>
                                  <i className='fas fa-sort-amount-up' />
                                </span>
                              )}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.propsState.isLoading === false ? (
                            this.props.propsState.reports_data.length > 0 ? (
                              this.props.propsState.reports_data.map(
                                (row, index) => (
                                  <tr key={index} id={row.quiz_id}>
                                    <td>{row.fullname}</td>
                                    <td>{row.category_name}</td>
                                    <td>{row.quiz_name}</td>
                                    <td className='text-center'>
                                      {row.retake_count > 0
                                        ? row.retake_count
                                        : 0}
                                    </td>
                                    <td className='text-center'>
                                      {row.attend_count > 0
                                        ? row.attend_count
                                        : 0}
                                    </td>
                                    <td>
                                      {row.quizStartDate
                                        ? row.quizStartDate
                                        : '-'}
                                    </td>
                                    <td>
                                      {row.quizEndDate ? row.quizEndDate : '-'}
                                    </td>
                                    <td className='text-center'>
                                      {row.quizEndDate
                                        ? row.quiz_percentage > 0
                                          ? row.quiz_percentage
                                          : '0'
                                        : '-'}
                                    </td>
                                    <td className='text-center'>
                                      {row.TakenDays ? row.TakenDays : 'N/A'}
                                    </td>
                                    <td>
                                      {row.quiz_status === 'Pending' ? (
                                        <span className='badge badge-pill badge-warning'>
                                          Pending
                                        </span>
                                      ) : (
                                        <span className='badge badge-pill badge-success'>
                                          Completed
                                        </span>
                                      )}
                                    </td>
                                  </tr>
                                )
                              )
                            ) : (
                              <tr>
                                <td
                                  className='dataTables_empty text-center'
                                  colSpan='10'
                                >
                                  No matching records found
                                </td>
                              </tr>
                            )
                          ) : (
                            <tr>
                              <td
                                className='dataTables_empty text-center'
                                colSpan='10'
                              >
                                Loading...
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className='row mt-4'>
                      <div className='col-sm-12 col-md-5'>
                        <div
                          className='dataTables_info'
                          id='zero_config_info'
                          role='status'
                          aria-live='polite'
                        >
                          {this.props.propsState.isLoading === true
                            ? ''
                            : this.props.propsState.reports_data.length === 0
                              ? `Showing 0 of 0 entries`
                              : `Showing  
                              ${this.props.propsState.reports_data.length <
                                this.props.propsState.page_size
                                ? this.props.propsState.resultCount
                                : parseInt(
                                  this.props.propsState.reports_data
                                    .length *
                                  this.props.propsState.currentPage
                                )
                              }
                              of ${this.props.propsState.resultCount} entries`}
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-7'>
                        <div
                          className='dataTables_paginate paging_simple_numbers float-right'
                          id='zero_config_paginate'
                        >
                          {this.props.propsState.reports_data.length > 0 ? (
                            <Pagination
                              noOfPages={this.props.propsState.noOfPages}
                              currentPage={this.props.propsState.currentPage}
                              nextPage={this.props.propsState.nextPage}
                              prevPage={this.props.propsState.prevPage}
                              lastPage={this.props.propsState.lastPage}
                              gotoPage={this.props.gotoPage}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

QuizDetailReportsCustom.propTypes = {
  handleChange: PropTypes.func,
  onChange: PropTypes.func,
  gotoPage: PropTypes.func,
  handleSort: PropTypes.func,
  category: PropTypes.array,
  quiz: PropTypes.array,
  exportReport:PropTypes.func
};
export default QuizDetailReportsCustom;
