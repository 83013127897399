import React, { Component } from "react";
import AddCategoryForm from "../../component/category/add-category-form";
import API from "../../api";
import logout from "../../component/dashboard/logout";
import { toast } from "react-toastify";
import { GetError } from "../../component/action";
class AddCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      IsLoading: false,
      IsSubmit: false
    };
  }

  submitCategory = data => {
    let self = this;
    this.setState({
      IsLoading: true,
      IsSubmit: true
    });
    let categoryParam = new FormData();
    categoryParam.append("category_name", data.category_name);
    API.post("category/category_add", categoryParam)
      .then(res => {
        if (res.data.status === 200) {
          this.setState = {
            IsLoading: false,
            IsSubmit: false,
            successfull: true
          };
          this.props.history.push("/categorylist");
          toast.success(res.data.message);
        }
      })
      .catch(function(error) {
        let errorobj = GetError(error);
        if (errorobj.status === 400) {
          toast.error(errorobj.data.message);
        } else if (errorobj.status === 401) {
          toast.error(errorobj.data.message);
          setTimeout(function() {
            logout(this.props);
          }, 2000);
        } else {
          toast.error("Something went wrong on server end.");
        }
        self.setState({
          IsLoading: false,
          IsSubmit: false
        });
      });
  };
  render() {
    return (
      <AddCategoryForm
        submitCategory={this.submitCategory}
        propsState={this.state}
      />
    );
  }
}
export default AddCategory;
