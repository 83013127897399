import React, { Component } from 'react';
import OverallReportCustom from '../../component/reports/overall-reports-custom';
import API from '../../api';
import { toast } from 'react-toastify';
import { GetError } from '../../component/action';
import logout from '../../component/dashboard/logout';
import { connect } from 'react-redux';
import { GetAllCategory } from '../../action/category-action';
import { GetAllQuiz } from '../../action/quiz-action';
import Modal from 'react-modal';
class OverallReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reports_data: [],
      excel_data: [],
      page_size: 10,
      resultCount: null,
      noOfPages: 1,
      currentPage: 1,
      lastPage: 0,
      prevPage: null,
      nextPage: null,
      pagination: '',
      searchTerm: '',
      sortName: 'quiz_name',
      sortOrder: 'asc',
      isLoading: false,
      modalIsOpen: false,
      modelContent: '',
      modelContentType: '',
      quizlistoption: [],
      filter_quiz_name: 0,
      filter_category: '',
      filter_attempt: '',
      feedback :[],
      feeddetails:{},
      isLoadingExport: false
    };
    this.onClick = this.exportReport.bind(this);
  }
  openModal= (question,question_type,user_answer_text,details,option_A,option_B,option_C) =>{
   this.state.feeddetails ={
       question:question,
       question_type:question_type,
       user_answer_text:user_answer_text,
       details:details,
        option_A:option_A?option_A:0,
        option_B:option_B?option_B:0,
        option_C:option_C?option_C:0
      
     }
   this.setState({modalIsOpen:true,feedback: this.state.feeddetails});
 }

  afterOpenModal= e => {
   // references are now sync'd and can be accessed.
   this.state.subtitle.style.color = '#333';
 }

  closeModal = e =>{
    this.setState({modalIsOpen:false});
 }

  componentWillMount() {
    this.props.dispatch(GetAllCategory());
    this.props.dispatch(GetAllQuiz(9));
    this.props.dispatch({ type: 'DEFAULT_STATE' });
    this.loadReports();
  }
  handleChange = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        currentPage: 1,
        prevPage: null,
        nextPage: this.state.noOfPages > 1 ? 2 : null
      },
      () => {
        if (this.state.filter_category) {
          this.props.dispatch(GetAllQuiz(this.state.filter_category));
        }
        this.loadReports();
      }
    );
  };
  loadReports() {
    let self = this;
    this.setState({
      isLoading: true,
      reports_data: [],
      excel_data: [],
      resultCount: null,
      noOfPages: 1,
      nextPage: null,
      prevPage: null,
      lastPage: 0
    });
    setTimeout(() => {
      API.get(
        `dashboard/questions_report/${
          this.state.filter_quiz_name ? this.state.filter_quiz_name : 0
        }/${
          this.state.filter_category ? this.state.filter_category :9
        }/${
          this.state.filter_attempt ? this.state.filter_attempt : 0
        }?order_by=${this.state.sortName}&order=${this.state.sortOrder}&limit=${
          this.state.page_size
        }&searchTerm=${this.state.searchTerm}&page=${this.state.currentPage}`
      )
        .then(res => {
          if (res.status === 200) {
            let nextUrl =
              res.data.data.nextlink !== '' ? res.data.data.nextlink : null;
            let prevUrl =
              res.data.data.prevlink !== ''
                ? res.data.data.prevlink === ''
                  ? 1
                  : res.data.data.prevlink
                : null;
            this.setState({
              reports_data: res.data.data.report_data,
              excel_data: res.data.excel_data,
              resultCount: res.data.data.total_rows,
              isLoading: false,
              noOfPages: Math.ceil(
                res.data.data.total_rows / this.state.page_size
              ),
              nextPage: nextUrl,
              prevPage: prevUrl,
              lastPage: Math.ceil(
                res.data.data.total_rows / this.state.page_size
              )
            });
          }
        })
        .catch(function(erorr) {
          let errorobj = GetError(erorr);
          self.setState({
            isLoading: false,
            reports_data: [],
            excel_data: []
          });

          if (errorobj.status === 400) {
            if (self.state.reports_data.length > 0) {
              toast.error(errorobj.data.message);
            }
          } else if (errorobj.data.status === 401) {
            toast.error(errorobj.data.message);
            setTimeout(function() {
              logout(this.props);
            }, 2000);
          } else if (errorobj.status === 403) {
            toast.error(errorobj.data.message);
            setTimeout(function() {
              logout(self.props);
            }, 2000);
          } else {
            toast.error('Something went wrong on server end.');
          }
        });
    }, 100);
  }
  handleSort = (sortName, prevSortOrder) => {
    let sortOrder = prevSortOrder === 'asc' ? 'desc' : 'asc';
    this.setState({ sortName, sortOrder }, () => {
      this.loadReports();
    });
  };

  gotoPage = (page, totalPages) => {
    if (page && page !== this.state.currentPage) {
      this.setState(
        {
          currentPage: page
          //nextPage: totalPages > page ? page + 1 : null,
          //prevPage: page == 1 ? null : page - 1
        },
        () => {
          this.loadReports();
        }
      );
    }
  };

  exportReport = event => {
    let self = this;
    this.setState({
      isLoadingExport: true,
    });
    setTimeout(() => {
      API.get(
        `dashboard/questions_report/${
          this.state.filter_quiz_name ? this.state.filter_quiz_name : 0
        }/${
          this.state.filter_category ? this.state.filter_category :9
        }/${
          this.state.filter_attempt ? this.state.filter_attempt : 0
        }?order_by=${this.state.sortName}&order=${this.state.sortOrder}&limit=${
          this.state.page_size
        }&searchTerm=${this.state.searchTerm}&export_report=1&page_type=only_feedback`
      )
        .then(res => {
          if (res.status === 200) {
            
            let url = res.data.excel_url;
            let a = document.createElement('a');
            a.href = url;
            a.download = res.data.file_name;
            a.click();

            this.setState({
              isLoadingExport: false,
            });
          }
        })
        .catch(function(erorr) {
          let errorobj = GetError(erorr);
          self.setState({
            isLoadingExport: false
          });

          if (errorobj.status === 400) {
            if (self.state.reports_data.length > 0) {
              toast.error(errorobj.data.message);
            }
          } else if (errorobj.status === 401) {
            toast.error(errorobj.data.message);
            setTimeout(function() {
              logout(this.props);
            }, 2000);
          } else if (errorobj.status === 403) {
            toast.error(errorobj.data.message);
            setTimeout(function() {
              logout(self.props);
            }, 2000);
          } else {
            toast.error('Something went wrong on server end.');
          }
        });
    }, 100);
  
  };

  render() {
    return (
<div>
      <Modal
      isOpen={this.state.modalIsOpen}
      onAfterOpen={this.afterOpenModal}
      onRequestClose={this.closeModal}
      style={customStyles}
      contentLabel="Question Feedback"
      shouldCloseOnOverlayClick={false}

    >
 <i className="fa fa-times float-right"  aria-hidden="true" onClick={this.closeModal}></i>
      <h4 ref={_subtitle => (this.state.subtitle = _subtitle)}><label>Question</label></h4>
<div><label>{this.state.feedback.question}</label></div>
<div className="row feedback-scroll">
<div className={this.state.feedback.question_type==2 ? "col-md-12" : "col-md-3"}>
<h6>{this.state.feedback.question_type==2 ? <strong>User feedback</strong> : <strong>Feedback option</strong> } </h6>

{this.state.feedback.question_type==2 && this.state.feedback.user_answer_text!==undefined && this.state.feedback.user_answer_text[0].length > 0 ?
this.state.feedback.user_answer_text.map(function (answerText, index) {
      return <div key={index}>{index+1}. { answerText }</div>; 
}) : ''}

{this.state.feedback.details ? 
this.state.feedback.details.map((feedbackdetails, index) => {
    return  <div>{index+1===1?'A':index+1===2?'B':index+1===3?'C':'D'} . {feedbackdetails.option_description} </div>
    })
    : 'No data found'}
    </div>
{this.state.feedback.question_type!=2 ?
<div className="col-md-6">
  <h6><strong>Feedback user count</strong></h6>
  <div>{this.state.feedback.option_A}</div>
  <div>{this.state.feedback.option_B}</div>
  <div>{this.state.feedback.option_C?this.state.feedback.option_C:''}</div>
</div>
: ''}
 </div>   
    </Modal>
      <OverallReportCustom
        handleChange={this.handleChange}
        propsState={this.state}
        gotoPage={this.gotoPage}
        handleSort={this.handleSort}
        category={this.props.load_category}
        quiz={this.props.load_quiz}
        props={this.props}
        modalhandel={this.openModal}
        exportReport={this.exportReport}
      />
      </div>
    );
  }
}
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -20%)',
    width:                  '50%',
    color:'solid #333',
    border: '2px solid #333'
  
  }
};
const mapStateToProps = (state, props) => {
  return {
    load_category: state.LoadCategory,
    load_quiz: state.LoadQuiz
  };
};
export default connect(mapStateToProps)(OverallReport);
