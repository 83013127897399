import React from "react";
function Spinner(props) {
  return props.isLoading === true ? (
    <span>
    <i className="fa fa-spinner fa-pulse" style={{ marginRight: 5 }} />
   
  </span>
  ) : (
    ""
  );
}

export default Spinner;
