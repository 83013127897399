import React, { Component } from "react";
import EditUserForm from "../../component/users/edit-user-form";
import { GetError, authToken } from "../../component/action";
import API from "../../api";
import logout from "../../component/dashboard/logout";
import { toast } from "react-toastify";
class EditUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Issubmit: false,
      isLoad: false
    };
  }

  UpdateUser = (user_id, data) => {
    this.setState({
      isLoad: true,
      Issubmit: true
    });
    let self = this;
    let userUpdateForm = new FormData();
    userUpdateForm.append("first_name", data.firstname);
    userUpdateForm.append("last_name", data.lastname);
    userUpdateForm.append("password", data.password);
    API.post(`user/user/${user_id}`, userUpdateForm)
      .then(res => {
        if (res.data.status === 200) {
          this.setState({
            isLoad: false,
            Issubmit: false
          });
          this.props.history.push("/userslist");
          toast.success(res.data.message);
        } else {
          toast.error("Something went wrong on server end.");
        }
      })
      .catch(function(error) {
        let errorobj = GetError(error);
        self.setState({
          isLoad: false,
          Issubmit: false
        });
        if (errorobj.status === 400) {
          toast.error(errorobj.data.message);
        } else if (errorobj.data.status === 401) {
          toast.error(errorobj.data.message);
          setTimeout(function() {
            logout(this.props);
          }, 2000);
        } else {
          toast.error("Something went wrong on server end.");
        }
      });
  };
  render() {
  
    if (authToken === "") {
      this.props.history.push("/login");
    }
    return (
      <EditUserForm
        id={this.props.match.params.id}
        UpdateUser={this.UpdateUser}
        Issubmit={this.state.Issubmit}
        isLoad={this.state.isLoad}
      />
    );
  }
}

export default EditUser;
