import API from "../../api";
const logout = props => {
  API.post(`authentication/logout`)
    .then(res => {
      localStorage.removeItem("token");
      localStorage.removeItem("userdata");
      if (props.children) {
        props.children.props.history.push("/login");
      } else {
        props.history.push("/login");
      }
    })
    .catch(function(erorr) {
      localStorage.removeItem("token");
      localStorage.removeItem("userdata");
      if (props.children) {
        props.children.props.history.push("/login");
      } else {
        props.history.push("/login");
      }
    });
};
export default logout;
