import { GET_ALL_QUIZ } from "../action/quiz-action";
const DEFAULT_STATE = "DEFAULT_STATE";
export default function LoadQuiz(state = [], action) {
  switch (action.type) {
    case GET_ALL_QUIZ:
      return action.payload.data.data;
    case DEFAULT_STATE:
      return [];
    default:
      return state;
  }
}
