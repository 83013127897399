import React, { Component } from 'react';
import AdminChangeLogReportCustom from '../../component/reports/admin-change-log-report-custom';
import API from '../../api';
import { toast } from 'react-toastify';
import { GetError } from '../../component/action';
import logout from '../../component/dashboard/logout';
import { connect } from 'react-redux';
import { GetAllActionType,GetAllUserName } from '../../action/action-type-action';
class AdminChangeLogReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reports_data: [],
      action_type: [],
      excel_data: [],
      page_size: 10,
      resultCount: null,
      noOfPages: 1,
      currentPage: 1,
      lastPage: 0,
      prevPage: null,
      nextPage: null,
      pagination: '',
      searchTerm: '',
      sortName: 'admin_log_report.id',
      sortOrder: 'desc',
      isLoading: false,
      modalIsOpen: false,
      modelContent: '',
      modelContentType: '',
      filter_action_type: 0,
      filter_username: 0,
      filter_start_date: 0,
      isLoadingExport: false,
     // action_type: [],
    };
    this.onChange = this.onChange.bind(this);
    this.onClick = this.exportReport.bind(this);


 
  }

  componentWillMount() {
    this.props.dispatch(GetAllActionType());
    this.props.dispatch(GetAllUserName());
    this.props.dispatch({ type: 'DEFAULT_STATE' });
    this.loadReports();
  }

  onChange = date =>
    this.setState(
      {
        filter_start_date: date,
        currentPage: 1,
        prevPage: null,
        nextPage: this.state.noOfPages > 1 ? 2 : null
      },
      () => {
        this.loadReports();
      }
    );

  loadReports() {
    let self = this;
    this.setState({
      isLoading: true,
      action_type: [],
      reports_data: [],
      excel_data: [],
      resultCount: null,
      noOfPages: 1,
      nextPage: null,
      prevPage: null,
      lastPage: 0,
      
    });


    setTimeout(() => {

      var convert_date = ''; 
      var convert_month = '';
      var convert_day = ''; 

      if(this.state.filter_start_date) {
        convert_month = this.state.filter_start_date.getUTCMonth() + 1;
        convert_day = this.state.filter_start_date.getUTCDate() + 1;
        convert_date  = this.state.filter_start_date.getUTCFullYear()+'-'+convert_month+'-'+convert_day;
      }

      API.get(
        `dashboard/admin_change_log_reports?search_date=${ convert_date ? convert_date : 0}&filter_action_type=${  this.state.filter_action_type ? this.state.filter_action_type : 0 }&filter_username=${  this.state.filter_username ? this.state.filter_username : 0 }&order_by=${this.state.sortName}&order=${this.state.sortOrder}&limit=${
          this.state.page_size }&searchTerm=${this.state.searchTerm}&page=${this.state.currentPage}`
      )
        .then(res => {
          if (res.status === 200) {
            
            let nextUrl =
              res.data.data.nextlink !== '' ? res.data.data.nextlink : null;
            let prevUrl =
              res.data.data.prevlink !== ''
                ? res.data.data.prevlink === ''
                  ? 1
                  : res.data.data.prevlink
                : null;
            this.setState({
              reports_data: res.data.data.report_data,
              resultCount: res.data.data.total_rows,
              isLoading: false,
              noOfPages: Math.ceil(
                res.data.data.total_rows / this.state.page_size
              ),
              nextPage: nextUrl,
              prevPage: prevUrl,
              lastPage: Math.ceil(
                res.data.data.total_rows / this.state.page_size
              ),
              
            });
           
          }
        })
        .catch(function(erorr) {
          let errorobj = GetError(erorr);
          self.setState({
            isLoading: false,
            reports_data: [],
            excel_data: []
          });

          if (errorobj.status === 400) {
            if (self.state.reports_data.length > 0) {
              toast.error(errorobj.data.message);
            }
          } else if (errorobj.status === 401) {
            toast.error(errorobj.data.message);
            setTimeout(function() {
              logout(this.props);
            }, 2000);
          } else if (errorobj.status === 403) {
            toast.error(errorobj.data.message);
            setTimeout(function() {
              logout(self.props);
            }, 2000);
          } else {
            toast.error('Something went wrong on server end.');
          }
        });
    }, 100);
  }
  handleSort = (sortName, prevSortOrder) => {
    let sortOrder = prevSortOrder === 'asc' ? 'desc' : 'asc';
    this.setState({ sortName, sortOrder }, () => {
      this.loadReports();
    });
  };
  handleChange = event => {
    this.setState(
      {
        [event.target.name]: event.target.value,
        currentPage: 1,
        prevPage: null,
        nextPage: this.state.noOfPages > 1 ? 2 : null
      },
      () => {
        this.loadReports();
      }
    );
  };
  gotoPage = (page, totalPages) => {
    if (page && page !== this.state.currentPage) {
      this.setState(
        {
          currentPage: page
          //nextPage: totalPages > page ? page + 1 : null,
          //prevPage: page == 1 ? null : page - 1
        },
        () => {
          this.loadReports();
        }
      );
    }
  };

  exportReport = event => {
    let self = this;
    this.setState({
      isLoadingExport: true,
    });
    setTimeout(() => {

      var convert_date = ''; 
      var convert_month = '';
      var convert_day = ''; 

      if(this.state.filter_start_date) {
        convert_month = this.state.filter_start_date.getUTCMonth() + 1;
        convert_day = this.state.filter_start_date.getUTCDate() + 1;
        convert_date  = this.state.filter_start_date.getUTCFullYear()+'-'+convert_month+'-'+convert_day;
      }

      API.get(
        `dashboard/admin_change_log_reports?search_date=${ convert_date ? convert_date : 0}&filter_action_type=${  this.state.filter_action_type ? this.state.filter_action_type : 0 }&filter_username=${  this.state.filter_username ? this.state.filter_username : 0 }&order_by=${this.state.sortName}&order=${this.state.sortOrder}&limit=${
          this.state.page_size }&searchTerm=${this.state.searchTerm}&export_report=1`
      )
        .then(res => {
          if (res.status === 200) {
            
            let url = res.data.excel_url;
            let a = document.createElement('a');
            a.href = url;
            a.download = res.data.file_name;
            
            a.click();

            this.setState({
              isLoadingExport: false,
            });
          }
        })
        .catch(function(erorr) {
          let errorobj = GetError(erorr);
          self.setState({
            isLoadingExport: false
          });

          if (errorobj.status === 400) {
            if (self.state.reports_data.length > 0) {
              toast.error(errorobj.data.message);
            }
          } else if (errorobj.status === 401) {
            toast.error(errorobj.data.message);
            setTimeout(function() {
              logout(this.props);
            }, 2000);
          } else if (errorobj.status === 403) {
            toast.error(errorobj.data.message);
            setTimeout(function() {
              logout(self.props);
            }, 2000);
          } else {
            toast.error('Something went wrong on server end.');
          }
        });
    }, 100);
  
  };

  render() {
    return (
      <AdminChangeLogReportCustom
        propsState={this.state}
        handleChange={this.handleChange}
        onChange={this.onChange}
        gotoPage={this.gotoPage}
        handleSort={this.handleSort}
        category={this.props.load_category}
        quiz={this.props.load_quiz}
        props={this.props}
        exportReport={this.exportReport}
      />
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    
    load_category: state.LoadActionType,
    load_quiz: state.LoadUserName

  };
};


export default connect(mapStateToProps)(AdminChangeLogReport);
