import React, { Component } from 'react';
import Loader from '../dashboard/loader';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import 'react-datepicker/dist/react-datepicker.css';
import { Pagination } from '../action';
import {
  ExcelExport,
  ExcelExportColumn
} from '@progress/kendo-react-excel-export';
import PropTypes from 'prop-types';
import logout from '../../component/dashboard/logout';

const $ = window.$;

class QuizReportCustom extends Component {
  _exporter;

  export = () => {
    let token = localStorage.getItem('token');
    if (token !== null) {
      this._exporter.save();
    } else {
      logout(this.props.props);
    }
  };

  
  render() {
    
    return (
      <div>
        <Loader isLoading={this.props.propsState.isLoading} />
        <Loader isLoadingExport={this.props.propsState.isLoadingExport} />
        <div className='page-wrapper'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12'>
                <div className='card'>
                  <h4 className='card-title m-b-0'>Feedback Report</h4>
                  <div className='card-body'>
                    <div className='row'>
                      
                      <div className='col-md-12 text-right' data-select2-id='11'>
                        <button
                          className='dt-button buttons-excel'
                          onClick={e => this.props.exportReport(e)}
                        >
                          <span>Excel</span>
                        </button>
                      </div>
                    </div>
                    <div className='row mt-4'>
                      <div className='col-sm-12 col-md-6'>
                        <div
                          className='dataTables_length'
                          id='zero_config_length'
                        >
                          <label className='table-label'>
                            <span className='table-span'>Show entries</span>
                            <select
                              name='page_size'
                              aria-controls='zero_config'
                              className='form-control form-control-sm'
                              value={this.props.propsState.page_size}
                              onChange={e => this.props.handleChange(e)}
                            >
                              <option value='10'>10</option>
                              <option value='25'>25</option>
                              <option value='50'>50</option>
                              <option value='100'>100</option>
                            </select>
                          </label>
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-6 text-right'>
                        <div
                          id='zero_config_filter'
                          className='dataTables_filter'
                        >
                          <label className='text-left'>
                            <span>Search:</span>
                            <input
                              type='search'
                              className='form-control form-control-sm'
                              placeholder='Search'
                              aria-controls='zero_config'
                              name='searchTerm'
                              id='searchTerm'
                              value={this.props.propsState.searchTerm}
                              onChange={e => this.props.handleChange(e)}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className='table-responsive'>
                      <table id='zero_config' className='table'>
                        <thead>
                          <tr>
                            <th
                              onClick={() =>
                                this.props.handleSort(
                                  'fullname',
                                  this.props.propsState.sortOrder
                                )
                              }
                            >
                              User
                              {this.props.propsState.sortName === 'fullname' ? (
                                <span className='sort-active'>
                                  {this.props.propsState.sortOrder === 'asc' ? (
                                    <i className='fas fa-sort-amount-up' />
                                  ) : (
                                    <i className='fas fa-sort-amount-down' />
                                  )}
                                </span>
                              ) : (
                                <span className='sort-inactive'>
                                  <i className='fas fa-sort-amount-up' />
                                </span>
                              )}
                            </th>
                            <th
                              onClick={() =>
                                this.props.handleSort(
                                  'question_text',
                                  this.props.propsState.sortOrder
                                )
                              }
                            >
                              Question
                              {this.props.propsState.sortName ===
                              'question_text' ? (
                                <span className='sort-active'>
                                  {this.props.propsState.sortOrder === 'asc' ? (
                                    <i className='fas fa-sort-amount-up' />
                                  ) : (
                                    <i className='fas fa-sort-amount-down' />
                                  )}
                                </span>
                              ) : (
                                <span className='sort-inactive'>
                                  <i className='fas fa-sort-amount-up' />
                                </span>
                              )}
                            </th>
                            <th
                              onClick={() =>
                                this.props.handleSort(
                                  'option_description',
                                  this.props.propsState.sortOrder
                                )
                              }
                            >
                              Answer
                              {this.props.propsState.sortName ===
                              'option_description' ? (
                                <span className='sort-active'>
                                  {this.props.propsState.sortOrder === 'asc' ? (
                                    <i className='fas fa-sort-amount-up' />
                                  ) : (
                                    <i className='fas fa-sort-amount-down' />
                                  )}
                                </span>
                              ) : (
                                <span className='sort-inactive'>
                                  <i className='fas fa-sort-amount-up' />
                                </span>
                              )}
                            </th>
                            <th
                              onClick={() =>
                                this.props.handleSort(
                                  'attendedDate',
                                  this.props.propsState.sortOrder
                                )
                              }
                            >
                              Date
                              {this.props.propsState.sortName ===
                                'attendedDate' ? (
                                <span className='sort-active'>
                                  {this.props.propsState.sortOrder === 'asc' ? (
                                    <i className='fas fa-sort-amount-up' />
                                  ) : (
                                    <i className='fas fa-sort-amount-down' />
                                  )}
                                </span>
                              ) : (
                                <span className='sort-inactive'>
                                  <i className='fas fa-sort-amount-up' />
                                </span>
                              )}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.propsState.isLoading === false ? (
                            this.props.propsState.reports_data.length > 0 ? (
                              this.props.propsState.reports_data.map(
                                (row, index) => (
                                  <tr key={index} id={row.quiz_id}>
                                    <td>{row.fullname} {row.ardms_id ? '('+row.ardms_id+')' : ''}</td>
                                    <td>{row.question_text}</td>
                                    <td>{row.option_description}</td>
                                    <td>
                                      {row.attendedDate ? row.attendedDate : '-'}
                                    </td>
                                  </tr>
                                )
                              )
                            ) : (
                              <tr>
                                <td
                                  className='dataTables_empty text-center'
                                  colSpan='10'
                                >
                                  No matching records found
                                </td>
                              </tr>
                            )
                          ) : (
                            <tr>
                              <td
                                className='dataTables_empty text-center'
                                colSpan='10'
                              >
                                Loading...
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className='row mt-4'>
                      <div className='col-sm-12 col-md-5'>
                        <div
                          className='dataTables_info'
                          id='zero_config_info'
                          role='status'
                          aria-live='polite'
                        >
                          {this.props.propsState.isLoading === true
                            ? ''
                            : this.props.propsState.reports_data.length === 0
                            ? `Showing 0 of 0 entries`
                            : `Showing  
                            ${
                              this.props.propsState.reports_data.length <
                              this.props.propsState.page_size
                                ? this.props.propsState.resultCount
                                : parseInt(
                                    this.props.propsState.reports_data.length *
                                      this.props.propsState.currentPage
                                  )
                            }
                            of ${this.props.propsState.resultCount} entries`}
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-7'>
                        <div
                          className='dataTables_paginate paging_simple_numbers float-right'
                          id='zero_config_paginate'
                        >
                          {this.props.propsState.reports_data.length > 0 ? (
                            <Pagination
                              noOfPages={this.props.propsState.noOfPages}
                              currentPage={this.props.propsState.currentPage}
                              nextPage={this.props.propsState.nextPage}
                              prevPage={this.props.propsState.prevPage}
                              lastPage={this.props.propsState.lastPage}
                              gotoPage={this.props.gotoPage}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
QuizReportCustom.propTypes = {
  gotoPage: PropTypes.func,
  handleChange: PropTypes.func,
  handleSort: PropTypes.func,
  category: PropTypes.array,
  quiz: PropTypes.array,
  exportReport:PropTypes.func,
  handleInputChanged:PropTypes.func,
};
export default QuizReportCustom;
