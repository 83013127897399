import { GET_ALL_USERNAME } from "../action/action-type-action";

export default function LoadUserName(state = [], action) {
  switch (action.type) {
    case GET_ALL_USERNAME:
      return action.payload.data.data;
    default:
      return state;
  }
}
