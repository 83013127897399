import React from 'react';
import Loader from '../dashboard/loader';
import { Pagination } from '../action';
import { NavLink } from 'react-router-dom';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
const UserListCustom = props => {
  return (
    <div>
      <Loader isLoading={props.propsState.isLoading} />
      <div className='page-wrapper'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <div className='card'>
                <h5 className='card-title m-b-0 pl20'>Manage Users</h5>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-sm-12 col-md-6'>
                      <div
                        className='dataTables_length'
                        id='zero_config_length'
                      >
                        <label className='table-label'>
                          <span className='table-span'>Show entries</span>
                          <select
                            name='page_size'
                            aria-controls='zero_config'
                            className='form-control form-control-sm'
                            value={props.propsState.page_size}
                            onChange={e => props.handleChange(e)}
                          >
                            <option value='10'>10</option>
                            <option value='25'>25</option>
                            <option value='50'>50</option>
                            <option value='100'>100</option>
                          </select>
                        </label>
                      </div>
                    </div>
                    <div className='col-sm-12 col-md-6 text-right'>
                      <div
                        id='zero_config_filter'
                        className='dataTables_filter'
                      >
                        <label className='text-left'>
                          <span>Search:</span>
                          <input
                            type='search'
                            className='form-control form-control-sm'
                            placeholder='Search'
                            aria-controls='zero_config'
                            name='searchTerm'
                            id='searchTerm'
                            value={props.propsState.searchTerm}
                            onChange={e => props.handleChange(e)}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='table-responsive'>
                    <table id='zero_config' className='table'>
                      <thead>
                        <tr>
                          <th
                            onClick={() =>
                              props.handleSort(
                                'fullname',
                                props.propsState.sortOrder
                              )
                            }
                          >
                            Users
                            {props.propsState.sortName === 'fullname' ? (
                              <span className='sort-active'>
                                {props.propsState.sortOrder === 'asc' ? (
                                  <i className='fas fa-sort-amount-up' />
                                ) : (
                                  <i className='fas fa-sort-amount-down' />
                                )}
                              </span>
                            ) : (
                              <span className='sort-inactive'>
                                <i className='fas fa-sort-amount-up' />
                              </span>
                            )}
                          </th>
                          <th
                            onClick={() =>
                              props.handleSort(
                                'email',
                                props.propsState.sortOrder
                              )
                            }
                          >
                            Email
                            {props.propsState.sortName === 'email' ? (
                              <span className='sort-active'>
                                {props.propsState.sortOrder === 'asc' ? (
                                  <i className='fas fa-sort-amount-up' />
                                ) : (
                                  <i className='fas fa-sort-amount-down' />
                                )}
                              </span>
                            ) : (
                              <span className='sort-inactive'>
                                <i className='fas fa-sort-amount-up' />
                              </span>
                            )}
                          </th>
                          <th
                            onClick={() =>
                              props.handleSort(
                                'password',
                                props.propsState.sortOrder
                              )
                            }
                            className='text-center'
                          >
                            Password Set?
                            {props.propsState.sortName === 'password' ? (
                              <span className='sort-active'>
                                {props.propsState.sortOrder === 'asc' ? (
                                  <i className='fas fa-sort-amount-up' />
                                ) : (
                                  <i className='fas fa-sort-amount-down' />
                                )}
                              </span>
                            ) : (
                              <span className='sort-inactive'>
                                <i className='fas fa-sort-amount-up' />
                              </span>
                            )}
                          </th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.propsState.isLoading === false ? (
                          props.propsState.users.length > 0 ? (
                            props.propsState.users.map((user, index) => (
                              <tr key={index} id={user.user_id}>
                                <td>
                                  {user.fullname
                                    ? user.guest_user_unique_key
                                      ? `${user.fullname} (${user.guest_user_unique_key})`
                                      : user.fullname
                                    : 'N/A'}
                                </td>
                                <td>{user.email}</td>
                                <td className='text-center'>
                                  {user.password ? 'Yes' : 'No'}
                                </td>
                                <td className='center' width='200'>
                                  <div>
                                    <ReactTooltip />
                                    <button
                                      data-tip='Status'
                                      data-place='top'
                                      data-effect='solid'
                                      className={
                                        user.status === '1'
                                          ? 'btn btn-success btn-sm'
                                          : 'btn btn-dark btn-sm'
                                      }
                                      onClick={() =>
                                        props.changeStatus(
                                          user.user_id,
                                          user.status === '1' ? '2' : '1'
                                        )
                                      }
                                    >
                                      <i
                                        className={
                                          user.status === '1'
                                            ? 'fas fa-check'
                                            : 'fas fa-times'
                                        }
                                      />
                                    </button>

                                    <NavLink to={`/edituser/${user.user_id}`}>
                                      <ReactTooltip />
                                      <button
                                        className='btn btn-primary btn-sm'
                                        data-tip='Edit'
                                        data-place='top'
                                        data-effect='solid'
                                      >
                                        <i className='fas fa-pencil-alt' />
                                      </button>
                                    </NavLink>
                                    <button
                                      className='btn btn-danger btn-sm'
                                      data-tip='Delete'
                                      data-place='top'
                                      data-effect='solid'
                                      onClick={() =>
                                        props.removeUser(user.user_id)
                                      }
                                    >
                                      <i className='fas fa-trash-alt' />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                className='dataTables_empty text-center'
                                colSpan='4'
                              >
                                No matching records found
                              </td>
                            </tr>
                          )
                        ) : (
                          <tr>
                            <td
                              className='dataTables_empty text-center'
                              colSpan='4'
                            >
                              Loading...
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className='row mt-4'>
                    <div className='col-sm-12 col-md-5'>
                      <div
                        className='dataTables_info'
                        id='zero_config_info'
                        role='status'
                        aria-live='polite'
                      >
                        {props.propsState.isLoading === true
                          ? ''
                          : props.propsState.users.length === 0
                          ? `Showing 0 of 0 entries`
                          : `Showing  
                            ${
                              props.propsState.users.length <
                              props.propsState.page_size
                                ? props.propsState.resultCount
                                : parseInt(
                                    props.propsState.users.length *
                                      props.propsState.currentPage
                                  )
                            }
                            of ${props.propsState.resultCount} entries`}
                      </div>
                    </div>
                    <div className='col-sm-12 col-md-7'>
                      <div
                        className='dataTables_paginate paging_simple_numbers float-right'
                        id='zero_config_paginate'
                      >
                        {props.propsState.users.length > 0 ? (
                          <Pagination
                            noOfPages={props.propsState.noOfPages}
                            currentPage={props.propsState.currentPage}
                            nextPage={props.propsState.nextPage}
                            prevPage={props.propsState.prevPage}
                            lastPage={props.propsState.lastPage}
                            gotoPage={props.gotoPage}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

UserListCustom.propTypes = {
  removeUser: PropTypes.func,
  changeStatus: PropTypes.func,
  handleSort: PropTypes.func,
  handleChange: PropTypes.func,
  gotoPage: PropTypes.func
};
export default UserListCustom;
