import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Loader from "../dashboard/loader";
import "react-toastify/dist/ReactToastify.css";
import SimpleReactValidator from "simple-react-validator";
import PropTypes from "prop-types";
class AddCategoryForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category_name: ""
    };
    this.validator = new SimpleReactValidator();
  }
  createCategory(e) {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.props.submitCategory(this.state);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  render() {
    return (
      <div>
        <Loader IsLoading={this.props.propsState.IsLoading} />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <h4 className="card-title">Add Category</h4>
                  <div className="col-md-6">
                    <form className="form-horizontal">
                      <div className="card-body">
                        <div className="form-group row">
                          <div className="col-sm-9">
                            <label className="control-label col-form-label label-text">
                              Category Title<sup className="req-color">*</sup>
                            </label>
                            <input
                              type="text"
                              className="form-control custom-input"
                              id="fname"
                              placeholder="Enter Category Title"
                              value={this.state.category_name}
                              name="category_name"
                              onChange={e => this.handleChange(e)}
                            />
                            {this.validator.message(
                              "Category Title",
                              this.state.category_name,
                              "required|alpha_num_dash_space|min:2|max:100"
                            )}
                          </div>
                        </div>
                        <div className="">
                          <div className="">
                            <button
                              type="button"
                              className="btn btn-primary-newui btn-md-newui"
                              disabled={this.state.IsSubmit}
                              onClick={e => this.createCategory(e)}
                            >
                              Save
                            </button>
                            <NavLink
                              to="/categorylist"
                              type="button"
                              className="btn btn-primary-newui btn-md-newui"
                            >
                              Cancel
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
AddCategoryForm.propTypes = {
  submitCategory: PropTypes.func
};
export default AddCategoryForm;
