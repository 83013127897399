import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "mdbreact/dist/css/mdb.css";
import store from "./store";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";


var getLocation = function (href) {
  var l = document.createElement("a");
  l.href = href;
  return l;
};


var SentryDSN = "https://588f359d80ea4c4f999066a75a0ab603@o4504162845523968.ingest.sentry.io/4504168697102336";

var l = getLocation(window.location.href);

if (l.hostname == "www.ardmsskills.org") {
  var sentryEnvironment = 'production';
} else {
  var sentryEnvironment = 'development';
}  

Sentry.init({
  dsn: SentryDSN,
  environment : sentryEnvironment,
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  
  
}); 

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
