import React, { Component } from "react";
import AddQuizForm from "../../component/notification/add-notification-form";
import API from "../../api";
import { toast } from "react-toastify";
import { GetError } from "../../component/action";
import { GetAllspecialities } from "../../action/specialities-action";
import { connect } from "react-redux";

class AddNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      IsLoading: false,
      IsSubmit: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.props.dispatch(GetAllspecialities());
  }
  submitNotification = (data) => {
    let self = this;
    this.setState({
      IsLoading: true,
      IsSubmit: true,
    });

    let quizParam = new FormData();
    quizParam.append("user_type", data.specialities_name);
    quizParam.append("notification_title", data.notification_title);
    API.post("notification/notification_send", quizParam)
      .then((res) => {
        if (res.data.status === 200) {
          this.setState({
            IsLoading: false,
            IsSubmit: false,
            successfull: true,
          });
          toast.success(res.data.message);
          // this.props.history.push("/notification");
          window.location.reload();
        }
      })
      .catch(function (error) {
        let errorobj = GetError(error);
        console.log(errorobj);
        if (errorobj.status === 400) {
          toast.error(errorobj.data.message);
        } else if (errorobj.data.status === 401) {
          toast.error(errorobj.data.message);
        } else if (errorobj.status === 403) {
          toast.error(errorobj.data.error);
        } else {
          toast.error("Something went wrong on server end.");
        }
        self.setState({
          IsLoading: false,
          IsSubmit: false,
        });
      });
  };
  render() {
    return (
      <AddQuizForm
        submitNotification={this.submitNotification}
        propsState={this.state}
        specialities={this.props.load_specialities}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    load_specialities: state.Loadspecialities,
  };
};
export default connect(mapStateToProps)(AddNotification);
