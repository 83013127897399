import React, { Component } from "react";
import { AddExplantion } from "../../action/question-action";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import API from "../../api";
import Loader from "../dashboard/loader";
import logout from "../dashboard/logout";
import Spinner from "../dashboard/spinner";

import {
  GetError,
  validImage,
  validVideo,
  validPdf,
  validUrl,
  assetsURL,
  mediaSizeValidation
} from "../action";
import { FileSizeErrorMessage } from "../global";

class AddExplantionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      explanation_id: "0",
      explanation_type: "0",
      explantion_text: "",
      explantion_url: "",
      explantion_Image: "",
      explantion_video: "",
      explantion_pdf: "",
      explantion_key: this.props.objKey,
      explantion_obj: [],
      validImage_error: true,
      validVideo_error: true,
      validPdf_error: true,
      validUrl_error: true,
      IsLoading: false,
      explanation_image_preview: [],
      url_media: "",

      attachLoader: [],
    };
  }
  componentWillMount() {
    if (this.props.explanationObj) {
      if (this.props.explanationObj.media_type === "1") {
        this.setState(
          {
            explanation_id: this.props.explanationObj.explanation_id,
            explanation_type: this.props.explanationObj.media_type,
            explantion_text: this.props.explanationObj.value,
          },
          () => {
            this.props.addExplantion(this.state);
          }
        );
      } else if (this.props.explanationObj.media_type === "2") {
        this.setState(
          {
            explanation_id: this.props.explanationObj.explanation_id,
            explanation_type: this.props.explanationObj.media_type,
            explantion_Image: this.props.explanationObj.value,
          },
          () => {
            this.props.addExplantion(this.state);
          }
        );
      } else if (this.props.explanationObj.media_type === "3") {
        this.setState(
          {
            explanation_id: this.props.explanationObj.explanation_id,
            explanation_type: this.props.explanationObj.media_type,
            explantion_video: this.props.explanationObj.value,
          },
          () => {
            this.props.addExplantion(this.state);
          }
        );
      } else if (this.props.explanationObj.media_type === "4") {
        this.setState(
          {
            explanation_id: this.props.explanationObj.explanation_id,
            explanation_type: this.props.explanationObj.media_type,
            explantion_pdf: this.props.explanationObj.value,
          },
          () => {
            this.props.addExplantion(this.state);
          }
        );
      } else if (this.props.explanationObj.media_type === "5") {
        this.setState(
          {
            explanation_id: this.props.explanationObj.explanation_id,
            explanation_type: this.props.explanationObj.media_type,
            explantion_url: this.props.explanationObj.value,
          },
          () => {
            this.props.addExplantion(this.state);
          }
        );
      }
    } else {
      this.props.addExplantion(this.state);
    }
  }
  handleChangeDropDown = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        validImage_error: true,
        validVideo_error: true,
        validPdf_error: true,
        validUrl_error: true,
      },
      () => { }
    );
  };
  handleChange(e, arrIndex) {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        this.updateExplantionObj(arrIndex);
      }
    );
  }
  handleMedia(e, arrIndex) {
    let media = e.target.files[0];
    var isSubmit = true;
    if (this.state.url_media) {
      let mediaformdataremove = new FormData();
      mediaformdataremove.append("filepath", this.state.url_media);
      API.post("quiz/remove_question_media", mediaformdataremove).then(
        (res) => { }
      );
    }

    let mediaName = e.target.name;
    if (mediaName === "explantion_Image") {
      if (!media.name.match(/\.(jpg|jpeg|png|gif)$/)) {
        e.target.value = null;
        isSubmit = false;
        toast.error("Allowed file types are: .jpg, .png, .gif, .jpeg ");
      }
    }
    if (mediaName === "explantion_video") {
      if (!media.name.match(/\.(mp4|MP4)$/)) {
        e.target.value = null;
        isSubmit = false;
        toast.error("Allowed file types are: .mp4 ");
      }
    }
    if (mediaName === "explantion_pdf") {
      if (!media.name.match(/\.(pdf)$/)) {
        e.target.value = null;
        isSubmit = false;
        toast.error("Allowed file types are: .pdf ");
      }
    }

    if(media.size != '') {
      let resultMedia = mediaSizeValidation(media.size);
      if(resultMedia) {
        e.target.value = null;
        isSubmit = false;
        toast.error(FileSizeErrorMessage);
      }
    }


    if (isSubmit === true) {
      let attachLoader = this.state.attachLoader.slice();
      attachLoader[arrIndex] = arrIndex;
      this.setState({ attachLoader: attachLoader });

      let mediaformdataoption = new FormData();
      mediaformdataoption.append(mediaName, media);

      API.post("quiz/upload_question_media", mediaformdataoption)
        .then((res) => {
          if (res.status === 200) {
            let explanation_image_preview =
              this.state.explanation_image_preview.slice();
            explanation_image_preview[arrIndex] = URL.createObjectURL(media);
            this.setState(
              {
                [mediaName]: media,
                url_media: res.data.media_url,

                explanation_image_preview: explanation_image_preview,
              },
              () => {
                this.updateExplantionObj(arrIndex);
              }
            );

            let attachLoader = this.state.attachLoader.slice();
            attachLoader[arrIndex] = "";
            this.setState({ attachLoader: attachLoader });

            toast.success(res.data.message);
          } else {
            let attachLoader = this.state.attachLoader.slice();
            attachLoader[arrIndex] = "";
            this.setState({ attachLoader: attachLoader });
            toast.error("Something went wrong on werver end.");
          }
        })
        .catch(function (error) {
          let errorobj = GetError(error);
          if (errorobj) {
            if (errorobj.status === 400) {
              let attachLoader = this.state.attachLoader.slice();
              attachLoader[arrIndex] = "";
              this.setState({ attachLoader: attachLoader });
              toast.error(errorobj.data.message.replace(/<[^>]+>/g, ""));
            } else if (errorobj.data.status === 401) {
              let attachLoader = this.state.attachLoader.slice();
              attachLoader[arrIndex] = "";
              this.setState({ attachLoader: attachLoader });
              toast.error(errorobj.data.message.replace(/<[^>]+>/g, ""));
              setTimeout(function () {
                logout();
              }, 2000);
            } else {
              let attachLoader = this.state.attachLoader.slice();
              attachLoader[arrIndex] = "";
              this.setState({ attachLoader: attachLoader });
              toast.error("Something went wrong on server end.");
            }
          } else {
            let attachLoader = this.state.attachLoader.slice();
            attachLoader[arrIndex] = "";
            this.setState({ attachLoader: attachLoader });
            toast.error("Something went wrong on server end.");
          }
        });
    }
  }
  handleimageModal(e) {
    window.open(
      e,
      "PopupWindow",
      "width=600,height=600,scrollbars=yes,resizable=no"
    );
  }
  updateExplantionObj = (arrIndex) => {
    if (
      validImage(this.state) === false &&
      validVideo(this.state) === false &&
      validPdf(this.state) === false &&
      validUrl(this.state) === false
    ) {
      this.setState(
        {
          validImage_error: validImage(this.state),
          explantion_Image: "",
          validVideo_error: validVideo(this.state),
          explantion_video: "",
          validPdf_error: validPdf(this.state),
          explantion_pdf: "",
          validUrl_error: validUrl(this.state),
          explantion_url: "",
        },
        () => {
          this.props.validImage_error(this.state.validImage_error);
          this.props.validVideo_error(this.state.validVideo_error);
          this.props.validPdf_error(this.state.validPdf_error);
          this.props.validUrl_error(this.state.validUrl_error);

          this.props.addExplantion(this.state, arrIndex);
        }
      );
    } else {
      this.setState(
        {
          validImage_error: validImage(this.state),
          validVideo_error: validVideo(this.state),
          validPdf_error: validPdf(this.state),
          validUrl_error: validUrl(this.state),
        },
        () => {
          this.props.validImage_error(this.state.validImage_error);
          this.props.validVideo_error(this.state.validVideo_error);
          this.props.validPdf_error(this.state.validPdf_error);
          this.props.validUrl_error(this.state.validUrl_error);
          this.props.addExplantion(this.state, arrIndex);
        }
      );
    }
  };

  modelBox = (explanation, type) => {
    this.props.model(explanation, type);
  };
  render() {
    return (
      <div>
        <div className="form-group row">
          <div className="col-sm-5">
            <label className="control-label col-form-label label-text">
              Explanation
            </label>
            <select
              disabled={this.props.is_disable}
              className="select2 form-control custom-select select2-hidden-accessible"
              data-select2-id="1"
              aria-hidden="true"
              name="explanation_type"
              id={this.props.objKey}
              onChange={(e) => this.handleChangeDropDown(e)}
              defaultValue={
                this.props.explanationObj
                  ? this.props.explanationObj.media_type
                  : ""
              }
            >
              <option value="0">Select explanation type</option>
              <option value="1">Text</option>
              <option value="2">Image</option>
              <option value="3">Video</option>
              <option value="4">Pdf</option>
              <option value="5">Reference Url</option>
            </select>
          </div>
          <div className="col-sm-7">
            <div
              className={
                this.state.explanation_type === "1"
                  ? "hide_show"
                  : "hide_question_media"
              }
            >
              <label className="control-label col-form-label label-text">
                Explanation Text
              </label>
              <textarea
                id={this.props.objKey}
                disabled={this.props.is_disable}
                type="text"
                className="form-control custom-input"
                name="explantion_text"
                placeholder="Enter Explanation Text"
                value={this.state.explantion_text}
                onChange={(e) => {
                  this.handleChange(e, this.props.objKey);
                }}
              />
            </div>
            <div
              className={
                this.state.explanation_type === "2"
                  ? "hide_show"
                  : "hide_question_media"
              }
            >
              {this.props.explanationObj ? (
                this.props.explanationObj.value ? (
                  <div className="form-group row">
                    <div className="col-sm-9">
                      <label className="control-label col-form-label label-text">
                        Explanation Image
                      </label>
                      <input
                        id={this.props.objKey}
                        type="file"
                        className="form-control"
                        name="explantion_Image"
                        title="Image"
                        onChange={(e) => {
                          this.handleMedia(e, this.props.objKey);
                        }}
                      />
                      {this.state.attachLoader[this.props.objKey] ===
                        this.props.objKey ? (
                        <Spinner isLoading={true}></Spinner>
                      ) : (
                        ""
                      )}
                      <span className="srv-validation-message">
                        {this.state.validImage_error === false
                          ? "Only .jpg, .jpeg, .png and .gif images types are allowed."
                          : ""}
                      </span>
                    </div>
                    <div className="col-sm-3">
                      <img
                        width="200"
                        className="top-align"
                        src={this.props.explanationObj.value_url}
                        alt="explanation images"
                        title="Click here to view explanation image"
                        // onClick={() =>
                        //   this.modelBox(
                        //     this.props.explanationObj.value,
                        //     "IMAGE"
                        //   )
                        // }

                        onClick={() =>
                          this.handleimageModal(
                            this.props.explanationObj.value,
                            "IMAGE"
                          )
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <label className="control-label col-form-label label-text">
                      Explanation Image
                    </label>
                    <input
                      id={this.props.objKey}
                      type="file"
                      className="form-control"
                      name="explantion_Image"
                      title="Image"
                      onChange={(e) => {
                        this.handleMedia(e, this.props.objKey);
                      }}
                    />
                    {this.state.attachLoader[this.props.objKey] ===
                      this.props.objKey ? (
                      <Spinner isLoading={true}></Spinner>
                    ) : (
                      ""
                    )}
                    <span className="srv-validation-message">
                      {this.state.validImage_error === false
                        ? "Only .jpg, .jpeg, .png and .gif images types are allowed."
                        : ""}
                    </span>
                  </div>
                )
              ) : (
                <div>
                  <label className="control-label col-form-label label-text">
                    Explanation Image
                  </label>
                  <input
                    id={this.props.objKey}
                    type="file"
                    className="form-control"
                    name="explantion_Image"
                    title="Image"
                    onChange={(e) => {
                      this.handleMedia(e, this.props.objKey);
                    }}
                  />

                  {this.state.attachLoader[this.props.objKey] ===
                    this.props.objKey ? (
                    <Spinner isLoading={true}></Spinner>
                  ) : (
                    ""
                  )}
                  <span className="srv-validation-message">
                    {this.state.validImage_error === false
                      ? "Only .jpg, .jpeg, .png and .gif images types are allowed."
                      : ""}
                  </span>
                </div>
              )}
            </div>
            <div
              className={
                this.state.explanation_type === "3"
                  ? "hide_show"
                  : "hide_question_media"
              }
            >
              {this.props.explanationObj ? (
                this.props.explanationObj.value ? (
                  <div className="form-group row">
                    <div className="col-sm-9">
                      <label className="control-label col-form-label label-text">
                        Explanation Video
                      </label>
                      <input
                        type="file"
                        id={this.props.objKey}
                        className="form-control"
                        name="explantion_video"
                        title="video"
                        onChange={(e) => {
                          this.handleMedia(e, this.props.objKey);
                        }}
                      />

                      {this.state.attachLoader[this.props.objKey] ===
                        this.props.objKey ? (
                        <Spinner isLoading={true}></Spinner>
                      ) : (
                        ""
                      )}
                      <span className="srv-validation-message">
                        {this.state.validVideo_error === false
                          ? "Only .mp4 video type is allowed."
                          : ""}
                      </span>
                    </div>
                    <div className="col-sm-3">
                      <video width="200" controls>
                        <source
                          src={this.props.explanationObj.value}
                        />
                        Your browser does not support the video tag.
                      </video>
                      {/* <img
                        src="/assets/images/video.png"
                        alt="explnation video"
                        title="Click here to view explanation video"
                        onClick={() =>
                          this.modelBox(
                            this.props.explanationObj.value,
                            "VIDEO"
                          )
                        }
                      /> */}
                    </div>
                  </div>
                ) : (
                  <div>
                    <label className="control-label col-form-label label-text">
                      Explanation Video
                    </label>
                    <input
                      type="file"
                      id={this.props.objKey}
                      className="form-control"
                      name="explantion_video"
                      title="video"
                      onChange={(e) => {
                        this.handleMedia(e, this.props.objKey);
                      }}
                    />
                    {this.state.attachLoader[this.props.objKey] ===
                      this.props.objKey ? (
                      <Spinner isLoading={true}></Spinner>
                    ) : (
                      ""
                    )}
                    <span className="srv-validation-message">
                      {this.state.validVideo_error === false
                        ? "Only .mp4 video type is allowed."
                        : ""}
                    </span>
                  </div>
                )
              ) : (
                <div>
                  <label className="control-label col-form-label label-text">
                    Explanation Video
                  </label>
                  <input
                    type="file"
                    id={this.props.objKey}
                    className="form-control"
                    name="explantion_video"
                    title="video"
                    onChange={(e) => {
                      this.handleMedia(e, this.props.objKey);
                    }}
                  />
                  {this.state.attachLoader[this.props.objKey] ===
                    this.props.objKey ? (
                    <Spinner isLoading={true}></Spinner>
                  ) : (
                    ""
                  )}
                  <span className="srv-validation-message">
                    {this.state.validVideo_error === false
                      ? "Only .mp4 video type is allowed."
                      : ""}
                  </span>
                </div>
              )}
            </div>
            <div
              className={
                this.state.explanation_type === "4"
                  ? "hide_show"
                  : "hide_question_media"
              }
            >
              {this.props.explanationObj ? (
                this.props.explanationObj.value ? (
                  <div className="form-group row">
                    <div className="col-sm-9">
                      <label className="control-label col-form-label label-text">
                        Explanation Pdf
                      </label>
                      <input
                        type="file"
                        id={this.props.objKey}
                        className="form-control"
                        name="explantion_pdf"
                        title="Pdf"
                        onChange={(e) => {
                          this.handleMedia(e, this.props.objKey);
                        }}
                      />
                      {this.state.attachLoader[this.props.objKey] ===
                        this.props.objKey ? (
                        <Spinner isLoading={true}></Spinner>
                      ) : (
                        ""
                      )}
                      <span className="srv-validation-message">
                        {this.state.validPdf_error === false
                          ? "Only .pdf file type is allowed."
                          : ""}
                      </span>
                    </div>
                    <div className="col-sm-3">
                      <a
                        href={this.props.explanationObj.value}
                        target="_blank"
                      >
                        PDF
                      </a>
                      {/* <img
                        src="/assets/images/pdf.png"
                        alt="explnation pdf"
                        title="Click here to view explanation pdf"
                        onClick={() =>
                          this.modelBox(this.props.explanationObj.value, "PDF")
                        }
                      /> */}
                    </div>
                  </div>
                ) : (
                  <div>
                    <label className="control-label col-form-label label-text">
                      Explanation Pdf
                    </label>
                    <input
                      type="file"
                      id={this.props.objKey}
                      className="form-control"
                      name="explantion_pdf"
                      title="Pdf"
                      onChange={(e) => {
                        this.handleMedia(e, this.props.objKey);
                      }}
                    />
                    {this.state.attachLoader[this.props.objKey] ===
                      this.props.objKey ? (
                      <Spinner isLoading={true}></Spinner>
                    ) : (
                      ""
                    )}
                    <span className="srv-validation-message">
                      {this.state.validPdf_error === false
                        ? "Only .pdf file type is allowed."
                        : ""}
                    </span>
                  </div>
                )
              ) : (
                <div>
                  <label className="control-label col-form-label label-text">
                    Explanation Pdf
                  </label>
                  <input
                    type="file"
                    id={this.props.objKey}
                    className="form-control"
                    name="explantion_pdf"
                    title="Pdf"
                    onChange={(e) => {
                      this.handleMedia(e, this.props.objKey);
                    }}
                  />
                  {this.state.attachLoader[this.props.objKey] ===
                    this.props.objKey ? (
                    <Spinner isLoading={true}></Spinner>
                  ) : (
                    ""
                  )}
                  <span className="srv-validation-message">
                    {this.state.validPdf_error === false
                      ? "Only .pdf file type is allowed."
                      : ""}
                  </span>
                </div>
              )}
            </div>

            <div
              className={
                this.state.explanation_type === "5"
                  ? "hide_show"
                  : "hide_question_media"
              }
            >
              <label className="control-label col-form-label label-text">
                Explanation Reference Url
              </label>
              <input
                type="text"
                id={this.props.objKey}
                className="form-control custom-input"
                name="explantion_url"
                title="Reference Url"
                placeholder="Enter Reference Url"
                value={this.state.explantion_url}
                onChange={(e) => {
                  this.handleChange(e, this.props.objKey);
                }}
              />
              <span className="srv-validation-message">
                {this.state.validUrl_error === false
                  ? "Please enter valid Reference url."
                  : ""}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapActionToPros = {
  addExplantion: AddExplantion,
};
const mapStateProps = (state, props) => {
  return {
    explanation: state.QuestionReducer,
  };
};
export default connect(mapStateProps, mapActionToPros)(AddExplantionForm);
