import React, { Component } from 'react';
import QuizListCustom from '../../component/quiz/quiz-list-custom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import API from '../../api';
import { toast } from 'react-toastify';
import logout from '../../component/dashboard/logout';
import { GetError } from '../../component/action';
import { GetAllCategory } from '../../action/category-action';
import { connect } from 'react-redux';
class QuizList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quiz_data: [],
      page_size: 10,
      resultCount: null,
      noOfPages: 1,
      currentPage: 1,
      lastPage: 0,
      prevPage: null,
      nextPage: null,
      pagination: '',
      searchTerm: '',
      sortName: 'quiz_name',
      sortOrder: 'asc',
      isLoading: false,
      filter_category_name: ''
    };
  }

  removeQuiz = quiz_id => {
    let self = this;
    this.setState({
      isLoading: true
    });
    confirmAlert({
      title: 'Confirm',
      message: 'Are you sure you want to delete this quiz?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            API.delete(`quiz/quiz/${quiz_id}`)
              .then(res => {
                if (res.data.status === 200) {
                  toast.success(res.data.message);
                }
                this.loadQuizList();
                this.setState({
                  isLoading: false
                });
              })
              .catch(function(error) {
                let errorObj = GetError(error);
                if (errorObj.status === 400) {
                  toast.error(errorObj.data.message);
                } else if (errorObj.data.status === 401) {
                  toast.error(errorObj.data.message);
                  setTimeout(function() {
                    logout(this.props);
                  }, 2000);
                } else if (errorObj.status === 403) {
                  toast.error(errorObj.data.message);
                  setTimeout(function() {
                    logout(self.props);
                  }, 2000);
                } else {
                  toast.error('Something went wrong on server end.');
                }

                self.setState({
                  isLoading: false
                });
              });
          }
        },
        {
          label: 'No',
          onClick: () => {
            this.setState({
              isLoading: false
            });
          }
        }
      ]
    });
  };
  changeStatus = (quiz_id, status) => {
    let self = this;
    this.setState({
      isLoading: true
    });
    let params = new FormData();
    params.append('status', status);
    API.post(`quiz/quiz_edit/${quiz_id}`, params)
      .then(res => {
        if (res.data.status === 200) {
          this.setState({
            isLoading: false
          });
          this.loadQuizList();
          if (status === '1') {
            toast.success('Quiz is active.');
          } else if (status === '2') {
            toast.success('Quiz is inactive.');
          }
        }
      })
      .catch(function(error) {
        let errorObj = GetError(error);
        if (errorObj.status === 400) {
          toast.error(errorObj.data.message);
        } else if (errorObj.status === 401) {
          toast.error(errorObj.data.message);
          setTimeout(function() {
            logout(this.props);
          }, 2000);
        } else {
          toast.error('something went wrong on server end.');
        }
        self.setState({
          isLoading: false
        });
      });
  };

  componentWillMount() {
    this.loadQuizList();
    this.props.dispatch(GetAllCategory());
  }

  loadQuizList() {
    let self = this;
    this.setState({
      isLoading: true
    });
    API.get(
      `quiz/quiz?cat_id=${
        this.state.filter_category_name ? this.state.filter_category_name : 0
      }&order_by=${this.state.sortName}&order=${this.state.sortOrder}&limit=${
        this.state.page_size
      }&searchTerm=${this.state.searchTerm}&page=${this.state.currentPage}`
    )
      .then(res => {
        if (res.status === 200) {
          let nextUrl =
            res.data.data.nextlink !== '' ? res.data.data.nextlink : null;
          let prevUrl =
            res.data.data.prevlink !== ''
              ? res.data.data.prevlink === ''
                ? 1
                : res.data.data.prevlink
              : null;
          this.setState({
            quiz_data: res.data.data.quiz,
            resultCount: res.data.data.total_rows,
            isLoading: false,
            noOfPages: Math.ceil(
              res.data.data.total_rows / this.state.page_size
            ),
            nextPage: nextUrl,
            prevPage: prevUrl,
            lastPage: Math.ceil(res.data.data.total_rows / this.state.page_size)
          });
        }
      })
      .catch(function(erorr) {
        let errorobj = GetError(erorr);
        self.setState({
          isLoading: false
        });
        if (errorobj.status === 400) {
          self.setState({ quiz_data: [] }, () => {
            if (self.state.quiz_data.length > 0) {
              toast.error(errorobj.data.message);
            }
          });
        } else if (errorobj.status === 401) {
          toast.error(errorobj.data.message);
          setTimeout(function() {
            logout(this.props);
          }, 2000);
        } else if (errorobj.status === 403) {
          toast.error(errorobj.data.message);
          setTimeout(function() {
            logout(self.props);
          }, 2000);
        } else {
          toast.error('Something went wrong on server end.');
        }
      });
  }
  handleSort = (sortName, prevSortOrder) => {
    let sortOrder = prevSortOrder === 'asc' ? 'desc' : 'asc';
    this.setState({ sortName, sortOrder }, () => {
      this.loadQuizList();
    });
  };
  handleChange = event => {
    this.setState(
      {
        [event.target.name]: event.target.value,
        currentPage: 1,
        prevPage: null,
        nextPage: this.state.noOfPages > 1 ? 2 : null
      },
      () => {
        this.loadQuizList();
      }
    );
  };
  gotoPage = (page, totalPages) => {
    if (page && page !== this.state.currentPage) {
      this.setState(
        {
          currentPage: page
        },
        () => {
          this.loadQuizList();
        }
      );
    }
  };

  render() {
    return (
      <QuizListCustom
        removeQuiz={this.removeQuiz}
        changeStatus={this.changeStatus}
        gotoPage={this.gotoPage}
        handleSort={this.handleSort}
        handleChange={this.handleChange}
        propsState={this.state}
        category={this.props.load_category}
      />
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    load_category: state.LoadCategory
  };
};
export default connect(mapStateToProps)(QuizList);
