import React, { Component } from "react";
import Loader from "../dashboard/loader";
import SimpleReactValidator from "simple-react-validator";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert"; // Import

class AddNotificationForm extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      notification_title: "",
      user_type: "",
      is_required: false,
      IsLoading: false,
      IsSubmit: false,
      show: false,
    };
    this.validator = new SimpleReactValidator();
  }

  createNotification(e) {
    e.preventDefault();
    if (this.validator.allValid()) {
      confirmAlert({
        title: "Confirm",
        message: "Are you sure you want to send this notification?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              this.props.submitNotification(this.state);
            },
          },
          {
            label: "No",
            onClick: () => {
              this.setState({
                isLoading: false,
              });
            },
          },
        ],
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  handlecheckboxChange(e) {
    this.setState({ [e.target.name]: e.target.checked });
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleModal() {
    this.setState({ show: !this.state.show });
    this._isMounted = true;
    this.setState({}, () => {
      console.log(this.state);
    });
  }
  render() {
    return (
      <div>
        <Loader IsLoading={this.props.propsState.IsLoading} />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <h4 className="card-title">Add Notification</h4>
                  <div className="col-md-6">
                    <form className="form-horizontal">
                      <div className="card-body">
                        <div className="form-group row">
                          <div className="col-sm-9" data-select2-id="11">
                            <label className="control-label col-form-label label-text">
                              Select User Specialities
                              <sup className="req-color">*</sup>
                            </label>
                            <select
                              className="select2 form-control custom-select select2-hidden-accessible"
                              data-select2-id="1"
                              aria-hidden="true"
                              name="specialities_name"
                              onChange={(e) => this.handleChange(e)}
                            >
                              <option value="">Select Specialities</option>
                              {this.props.specialities
                                ? this.props.specialities.map(
                                    (specialities, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={specialities.name}
                                        >
                                          {specialities.name}
                                        </option>
                                      );
                                    }
                                  )
                                : ""}
                            </select>
                            {this.validator.message(
                              "User Specialities",
                              this.state.specialities_name,
                              "required"
                            )}
                          </div>
                        </div>

                        <div className="form-group row">
                          <div className="col-sm-9">
                            <label
                              className="control-label col-form-label label-text"
                              htmlFor="notification_title"
                            >
                              Notification Message
                              <sup className="req-color">*</sup>
                            </label>
                            <textarea
                              className="form-control custom-input"
                              id="notification_title"
                              placeholder="Enter Notification Message"
                              value={this.state.notification_title}
                              name="notification_title"
                              onChange={(e) => this.handleChange(e)}
                              maxLength="450"
                            />
                            {this.validator.message(
                              "message",
                              this.state.notification_title,
                              "required"
                            )}
                          </div>
                        </div>

                        <div className="">
                          <div className="">
                            <button
                              type="button"
                              className="btn btn-primary-newui btn-md-newui"
                              disabled={this.state.IsSubmit}
                              onClick={(e) => this.createNotification(e)}
                            >
                              Send
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary-newui btn-md-newui"
                              onClick={() => this.handleModal()}
                            >
                              Preview
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal show={this.state.show} onHide={() => this.handleModal()}>
          <Modal.Header>Notification Preview</Modal.Header>
          <Modal.Body>
            <div className="border">
              <div className="question bg-white p-3 border-bottom">
                <div className="d-flex flex-row align-items-center question-title">
                  <h5 className="mt-1 ml-2">{this.state.notification_title}</h5>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-primary-newui btn-md-newui"
              onClick={() => this.handleModal()}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
AddNotificationForm.propTypes = {
  submitNotification: PropTypes.func,
  specialities: PropTypes.array,
};
export default AddNotificationForm;
