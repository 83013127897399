import API from "../api";
import { GetError } from "../component/action";
export const GET_ALL_QUIZ = "GetAllQuiz";
export function GetAllQuiz(category_id) {
  return async function(dispatch, getState) {
    await API.get(`quiz/quiz_all/${category_id}`)
      .then(res => {
        dispatch({ type: GET_ALL_QUIZ, payload: res });
      })
      .catch(function(error) {
        dispatch({ type: "DEFAULT_STATE" });
        let errorObj = GetError(error);
        if (errorObj.status === 400) {
          console.log(errorObj.data.message);
          // toast.error(errorObj.data.message);
        } else if (errorObj.status === 401) {
          console.log(errorObj.data.message);
          // toast.error(errorObj.data.message);
        } else {
          console.log(errorObj.data.message);
          // toast.error("Something went wrong on server end.");
        }
      });
  };
}
