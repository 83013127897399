import React, { Component } from 'react';
import Loader from '../dashboard/loader';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Pagination } from '../action';
import PropTypes from 'prop-types';
import logout from '../dashboard/logout';
import { NavLink } from "react-router-dom";


class AdminChangeLogReportCustom extends Component {
  _exporter;
  export = () => {
    let token = localStorage.getItem('token');
    if (token !== null) {
      this._exporter.save();
    } else {
      logout(this.props.props);
    } 
  };
  render() {
    return (
      <div>
        <Loader isLoading={this.props.propsState.isLoading} />
        <Loader isLoadingExport={this.props.propsState.isLoadingExport} />
        <div className='page-wrapper'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12'>
                <div className='card'>
                  <h4 className='card-title m-b-0'>Admin Change Log Report</h4>
                  <div className='card-body'>
                    <div className='row'>
                      <div className='col-md-2' data-select2-id='11'>
                        <b className='filter_head'>Filter by Date</b>
                        <DatePicker
                          className='select2 form-control custom-select select2-hidden-accessible'
                          selected={this.props.propsState.filter_start_date}
                          onChange={this.props.onChange}
                          dateFormat='MM/d/Y'
                          placeholderText='Select Date'
                        />
                      </div>
                      
                      <div className='col-md-3' data-select2-id='11'>
                        <b className='filter_head'>Filter by Action</b>
                        <select
                          className='select2 form-control custom-select select2-hidden-accessible'
                          aria-hidden='true'
                          name='filter_action_type'
                          onChange={e => this.props.handleChange(e)}
                        >
                          <option value=''>Select Action</option>
                          {this.props.category.length > 0
                            ? this.props.category.map((value, index) => {
                              return (
                                <option
                                  key={index}
                                  value={value.id}
                                >
                                  {value.value}
                                </option>
                              );
                              
                            })
                            : ''}
                        </select>
                      </div>

                      <div className='col-md-4' data-select2-id='11'>
                        <b className='filter_head'>Filter by Username</b>
                        <select
                          className='select2 form-control custom-select select2-hidden-accessible'
                          aria-hidden='true'
                          name='filter_username'
                          onChange={e => this.props.handleChange(e)}
                        >
                          <option value=''>Select User </option>
                          {this.props.quiz.length > 0
                            ? this.props.quiz.map((quiz, index) => {
                              return (
                                <option key={index} value={quiz.user_id}>
                                  {quiz.full_name}
                                </option>
                              );
                            })
                            : ''}
                        </select>
                      </div>

                      <div className='col-md-3 text-right' data-select2-id='11'>
                        <button
                          className='dt-button buttons-excel'
                          onClick={e => this.props.exportReport(e)}
                        >
                          <span>Excel</span>
                        </button>
                      </div>      

                    </div>
                    <div className='row mt-4'>
                      <div className='col-sm-12 col-md-6'>
                        <div
                          className='dataTables_length'
                          id='zero_config_length'
                        >
                          <label className='table-label'>
                            <span className='table-span'>Show entries</span>
                            <select
                              name='page_size'
                              aria-controls='zero_config'
                              className='form-control form-control-sm'
                              value={this.props.propsState.page_size}
                              onChange={e => this.props.handleChange(e)}
                            >
                              <option value='10'>10</option>
                              <option value='25'>25</option>
                              <option value='50'>50</option>
                              <option value='100'>100</option>
                            </select>
                          </label>
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-6 text-right'>
                        <div
                          id='zero_config_filter'
                          className='dataTables_filter'
                        >
                          <label className='text-left'>
                            <span>Search:</span>
                            <input
                              type='search'
                              className='form-control form-control-sm'
                              placeholder='Search'
                              aria-controls='zero_config'
                              name='searchTerm'
                              id='searchTerm'
                              value={this.props.propsState.searchTerm}
                              onChange={e => this.props.handleChange(e)}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    
                    <div className='table-responsive'>
                      <table id='zero_config' className='table'>
                        <thead>
                          <tr>
                            <th
                              onClick={() =>
                                this.props.handleSort(
                                  'fullname',
                                  this.props.propsState.sortOrder
                                )
                              }
                            >
                              Username
                              {this.props.propsState.sortName ===
                                'fullname' ? (
                                <span className='sort-active'>
                                  {this.props.propsState.sortOrder === 'asc' ? (
                                    <i className='fas fa-sort-amount-up' />
                                  ) : (
                                    <i className='fas fa-sort-amount-down' />
                                  )}
                                </span>
                              ) : (
                                <span className='sort-inactive'>
                                  <i className='fas fa-sort-amount-up' />
                                </span>
                              )}
                            </th>
                            <th
                              onClick={() =>
                                this.props.handleSort(
                                  'action_type_text',
                                  this.props.propsState.sortOrder
                                )
                              }
                            >
                              Action
                              {this.props.propsState.sortName ===
                                'action_type_text' ? (
                                <span className='sort-active'>
                                  {this.props.propsState.sortOrder === 'asc' ? (
                                    <i className='fas fa-sort-amount-up' />
                                  ) : (
                                    <i className='fas fa-sort-amount-down' />
                                  )}
                                </span>
                              ) : (
                                <span className='sort-inactive'>
                                  <i className='fas fa-sort-amount-up' />
                                </span>
                              )}
                            </th>

                            <th
                              onClick={() =>
                                this.props.handleSort(
                                  'action_name',
                                  this.props.propsState.sortOrder
                                )
                              }
                            >
                              Quiz or Category or Question Name
                              {this.props.propsState.sortName ===
                                'action_name' ? (
                                <span className='sort-active'>
                                  {this.props.propsState.sortOrder === 'asc' ? (
                                    <i className='fas fa-sort-amount-up' />
                                  ) : (
                                    <i className='fas fa-sort-amount-down' />
                                  )}
                                </span>
                              ) : (
                                <span className='sort-inactive'>
                                  <i className='fas fa-sort-amount-up' />
                                </span>
                              )}
                            </th>

                            <th
                              onClick={() =>
                                this.props.handleSort(
                                  'created',
                                  this.props.propsState.sortOrder
                                )
                              }
                            >
                              Timestamp
                              {this.props.propsState.sortName ===
                                'created' ? (
                                <span className='sort-active'>
                                  {this.props.propsState.sortOrder === 'asc' ? (
                                    <i className='fas fa-sort-amount-up' />
                                  ) : (
                                    <i className='fas fa-sort-amount-down' />
                                  )}
                                </span>
                              ) : (
                                <span className='sort-inactive'>
                                  <i className='fas fa-sort-amount-up' />
                                </span>
                              )}
                            </th>

                            <th>Quiz or Category or Question ID (URL)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.propsState.isLoading === false ? (
                            this.props.propsState.reports_data.length > 0 ? (
                              this.props.propsState.reports_data.map(
                                (row, index) => (
                                  <tr key={index} id={row.id}>
                                    <td>{row.fullname}</td>
                                    <td>{row.action_type_text}</td>
                                    <td>{row.action_name}</td>
                                    <td>{row.created}</td>
                                    <td>
                                      {
                                        (row.action_url != '' &&  row.is_deleted == 0)  ?
                                        <NavLink to={`${row.action_url}`} target="_blank" rel="noopener noreferrer" >
                                          <button className="btn btn-primary btn-sm">
                                            <i className="fas fa-external-link-alt"></i>
                                          </button>
                                        </NavLink>
                                        : <button className="btn btn-danger btn-sm disabled">
                                        <i className="fa fa-trash"></i>
                                      </button>
                                      }
                                    </td>
                                  </tr>
                                )
                              )
                            ) : (
                              <tr>
                                <td
                                  className='dataTables_empty text-center'
                                  colSpan='10'
                                >
                                  No matching records found
                                </td>
                              </tr>
                            )
                          ) : (
                            <tr>
                              <td
                                className='dataTables_empty text-center'
                                colSpan='10'
                              >
                                Loading...
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className='row mt-4'>
                      <div className='col-sm-12 col-md-5'>
                        <div
                          className='dataTables_info'
                          id='zero_config_info'
                          role='status'
                          aria-live='polite'
                        >
                          {this.props.propsState.isLoading === true
                            ? ''
                            : this.props.propsState.reports_data.length === 0
                              ? `Showing 0 of 0 entries`
                              : `Showing  
                              ${this.props.propsState.reports_data.length <
                                this.props.propsState.page_size
                                ? this.props.propsState.resultCount
                                : parseInt(
                                  this.props.propsState.reports_data
                                    .length *
                                  this.props.propsState.currentPage
                                )
                              }
                              of ${this.props.propsState.resultCount} entries`}
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-7'>
                        <div
                          className='dataTables_paginate paging_simple_numbers float-right'
                          id='zero_config_paginate'
                        >
                          {this.props.propsState.reports_data.length > 0 ? (
                            <Pagination
                              noOfPages={this.props.propsState.noOfPages}
                              currentPage={this.props.propsState.currentPage}
                              nextPage={this.props.propsState.nextPage}
                              prevPage={this.props.propsState.prevPage}
                              lastPage={this.props.propsState.lastPage}
                              gotoPage={this.props.gotoPage}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AdminChangeLogReportCustom.propTypes = {
  handleChange: PropTypes.func,
  onChange: PropTypes.func,
  gotoPage: PropTypes.func,
  handleSort: PropTypes.func,
  userName: PropTypes.array,
  actionType: PropTypes.array,
  exportReport:PropTypes.func
};
export default AdminChangeLogReportCustom;
