import React, { useState } from "react";
import Topbar from "./top-nav-bar";
import Sidebar from "../../container/dashboard/side-bar";
const Layout = (props) => {
  const [status, NavStatus] = useState(1);
  const [hovervalue, hoverNav] = useState(false);

  return (
    <div
      id="main-wrapper"
      className={status === 1 ? "" : "mini-sidebar"}
      data-sidebartype={status === 1 ? "full" : "mini-sidebar"}
    >
      <Topbar
        {...props}
        status={status}
        NavStatus={NavStatus}
        hovervalue={hovervalue}
      />
      <Sidebar hoverNav={hoverNav} />
      {props.children}
      <footer className="footer text-center">
        &copy; {new Date().getFullYear()} All Rights Reserved.
      </footer>
      );
    </div>
  );
};
export default Layout;
