import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../dashboard/loader";
import SimpleReactValidator from "simple-react-validator";
import API from "../../api";
import PropTypes from "prop-types";
import Moment from "moment";
class EditQuiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quiz_id: "",
      quiz_name: "",
      Isloading: this.props.propsState.Isloading,
      IsSubmit: this.props.propsState.Issubmit,
      category_name: "",
      category_id: "",
      start_date: "",
      end_date: "",
      is_required: false,
    };
    this.validator = new SimpleReactValidator();
  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleCheckBox(e) {
    let isChecked = e.target.checked;
    if (isChecked) {
      this.setState({
        is_required: true, // flip boolean value
      });
    } else {
      this.setState({
        is_required: false, // flip boolean value
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentWillMount() {
    let self = this;
    this.setState({
      Isloading: true,
    });
    API.get(`quiz/quiz/${this.props.id}`)
      .then((res) => {
        console.log(res.data.data);
        if (res.data.data.quiz.is_required == "1") {
          self.setState({
            is_required: true,
          });
        } else {
          self.setState({
            is_required: false,
          });
        }
        this.setState({
          quiz_id: res.data.data.quiz.quiz_id,
          quiz_name: res.data.data.quiz.quiz_name,
          category_id: res.data.data.quiz.category_id,
          start_date: Moment(res.data.data.quiz.quiz_start_date).format(
            "YYYY-MM-DD"
          ),
          end_date: Moment(res.data.data.quiz.quiz_end_date).format(
            "YYYY-MM-DD"
          ),
          Isloading: false,
        });
      })
      .catch(function (error) {
        toast.error("Something went wrong on server end.");
        self.setState({
          Isloading: false,
        });
      });
  }
  updateQuiz(e) {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({
        Isloading: true,
        IsSubmit: true,
      });
      this.props.updateQuiz(this.state);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({
        Isloading: false,
        IsSubmit: false,
      });
    }
  }

  render() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    const Todaydate = yyyy + "-" + mm + "-" + dd;
    return (
      <div>
        <Loader IsLoading={this.state.IsLoading} />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <h4 className="card-title">Edit Quiz</h4>
                  <div className="col-md-6">
                    <form className="form-horizontal">
                      <div className="card-body">
                        <div className="form-group row">
                          <div className="col-sm-9">
                            <div className="form-check form-check-inline">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="is_required"
                                value={this.state.is_required}
                                name="is_required"
                                onChange={(e) => this.handleCheckBox(e)}
                                {...(this.state.is_required === true
                                  ? { checked: true }
                                  : {})}
                              />
                            </div>
                            <label className="control-label col-form-label label-text">
                              Quiz Required
                            </label>
                            <label className="form-check-label"></label>
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-9" data-select2-id="11">
                            <label className="control-label col-form-label label-text">
                              Category Title<sup className="req-color">*</sup>
                            </label>
                            <select
                              className="select2 form-control custom-select select2-hidden-accessible"
                              data-select2-id="1"
                              aria-hidden="true"
                              name="category_name"
                              onChange={(e) => this.handleChange(e)}
                              value={this.state.category_id}
                              disabled={this.state.category_id}
                            >
                              <option value="">Select Category Title</option>
                              {this.props.category.length > 0
                                ? this.props.category.map((category, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={category.category_id}
                                      >
                                        {category.category_name}
                                      </option>
                                    );
                                  })
                                : ""}
                            </select>
                            {this.validator.message(
                              "Category Title",
                              this.state.category_id,
                              "required"
                            )}
                          </div>
                        </div>

                        <div className="form-group row">
                          <div className="col-sm-9">
                            <label
                              className="control-label col-form-label label-text"
                              htmlFor="quiz_name"
                            >
                              Quiz Title <sup>*</sup>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="fname"
                              placeholder="Enter quiz title"
                              value={this.state.quiz_name}
                              name="quiz_name"
                              onChange={(e) => this.handleChange(e)}
                            />
                            {this.validator.message(
                              "Quiz title",
                              this.state.quiz_name,
                              "required|alpha_num_dash_space|min:2|max:100"
                            )}
                          </div>
                        </div>

                        <div className="form-group row">
                          <div className="col-sm-9">
                            <label className="control-label col-form-label label-text">
                              Quiz Start date
                            </label>
                            <input
                              type="date"
                              className="form-control custom-input"
                              id="start_date"
                              placeholder="Enter start date"
                              value={this.state.start_date}
                              name="start_date"
                              min={Todaydate}
                              onChange={(e) => this.handleChange(e)}
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <div className="col-sm-9">
                            <label className="control-label col-form-label label-text">
                              Quiz End date
                            </label>
                            <input
                              type="date"
                              className="form-control custom-input"
                              id="end_date"
                              placeholder="Enter End date"
                              value={this.state.end_date}
                              min={this.state.start_date}
                              name="end_date"
                              onChange={(e) => this.handleChange(e)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="border-top">
                        <div className="card-body">
                          <button
                            type="button"
                            className="btn btn-primary-newui btn-md-newui"
                            disabled={this.state.IsSubmit}
                            onClick={(e) => this.updateQuiz(e)}
                          >
                            Save
                          </button>

                          <NavLink
                            to="/quizlist"
                            type="button"
                            className="btn btn-primary-newui btn-md-newui"
                          >
                            Cancel
                          </NavLink>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
EditQuiz.propTypes = {
  updateQuiz: PropTypes.func,
  category: PropTypes.array,
};
export default EditQuiz;
