export const ADD_EXPLANTION = "AddExplantion";
export function AddExplantion(newExplanation, arrIndex) {
  return {
    type: ADD_EXPLANTION,
    payload: {
      arrIndex: arrIndex,
      newExplanation
    }
  };
}
