import { LOGIN_USER } from "../action/user-action";
const INITIALIZE_STATE = {
  userinfo: [],
};

export default function UserAction(state = INITIALIZE_STATE, action) {
  switch (action.type) {
    case LOGIN_USER:
      let userinfodata = action.payload.data;

      let isLoggedIn = false;
      if (userinfodata) {
        localStorage.setItem("userdata", JSON.stringify(userinfodata.data));
        localStorage.setItem("token", userinfodata.data.accessToken);
        isLoggedIn = true;
      }

      return {
        ...state,
        isLoggedIn: isLoggedIn,
        userinfo: action.payload.data || [],
      };

    //return userinfo;

    default:
      return state;
  }
}
