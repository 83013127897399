import { ADD_EXPLANTION } from "../action/question-action";

const initialState = [];
const DEFAULT_STATE = "DEFAULT_STATE";
export default function QuestionReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_EXPLANTION:
      let newArray = state.slice();
      let TypeArr = [
        "",
        action.payload.newExplanation.explantion_text,
        action.payload.newExplanation.explantion_Image,
        action.payload.newExplanation.explantion_video,
        action.payload.newExplanation.explantion_pdf,
        action.payload.newExplanation.explantion_url,
        action.payload.newExplanation.url_media,
      ];

      if (newArray.length > 0) {
        let arr1 = newArray.findIndex(
          (x) => x.explantion_key === action.payload.arrIndex
        );

        if (arr1 > -1) {
          newArray[action.payload.arrIndex].explanation_id =
            action.payload.newExplanation.explanation_id;
          newArray[action.payload.arrIndex].explantion_key =
            action.payload.newExplanation.explantion_key;
          newArray[action.payload.arrIndex].explanation_type =
            action.payload.newExplanation.explanation_type; 
          if (action.payload.newExplanation.explanation_type == 1 || action.payload.newExplanation.explanation_type == 5) {
            newArray[action.payload.arrIndex].explanation_media =
              TypeArr[action.payload.newExplanation.explanation_type];
          } else {
            newArray[action.payload.arrIndex].explanation_media =
              action.payload.newExplanation.url_media;
          }

          newArray[action.payload.arrIndex].url_media =
            action.payload.newExplanation.url_media;
        } else {
          newArray.push({
            explanation_id: action.payload.newExplanation.explanation_id,
            explantion_key: action.payload.newExplanation.explantion_key,
            explanation_type: action.payload.newExplanation.explanation_type,
            explanation_media:
              TypeArr[action.payload.newExplanation.explanation_type],
            url_media: action.payload.newExplanation.url_media,
          });
        }
      } else {
        if (newArray.length === 0) {
          newArray.push({
            explanation_id: action.payload.newExplanation.explanation_id,
            explantion_key: action.payload.newExplanation.explantion_key,
            explanation_type: action.payload.newExplanation.explanation_type,
            explanation_media:
              TypeArr[action.payload.newExplanation.explanation_type],
            url_media: action.payload.newExplanation.url_media,
          });
        }
      }
      return newArray;
    case DEFAULT_STATE:
      return initialState;
    default:
      return state;
  }
}
