import React, { Component } from "react";
import EditCategoryForm from "../../component/category/edit-category-form";
import { GetError, authToken } from "../../component/action";
import API from "../../api";
import logout from "../../component/dashboard/logout";
import { toast } from "react-toastify";
class EditCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
        IsLoading: false,
        IsSubmit: false
    };
  }

  updateCategory = data => {
    let self = this;
    this.setState({
      IsLoading: true,
      IsSubmit: true
    });
    let categoryParam = new FormData();    
    categoryParam.append("category_name", data.category_name);
    API.post(`category/category_edit/${data.category_id}`, categoryParam)
      .then(res => {
        if (res.data.status === 200) {
          this.setState({
            IsLoading: false,
            IsSubmit: false
          });

          this.props.history.push("/categorylist");
          toast.success(res.data.message);
        } else {
          toast.error("Something went wrong on server end.");
        }
      })
      .catch(function(error) {
        let errorobj = GetError(error);
        if (errorobj.status === 400) {
          toast.error(errorobj.data.message);
        } else if (errorobj.data.status === 401) {
          toast.error(errorobj.data.message);
          setTimeout(function() {
            logout();
          }, 2000);
        } else {
          toast.error("Something went wrong on server end.");
        }

        self.setState({
          IsLoading: false,
          IsSubmit: false
        });
      });
  };
  render() {
    if (authToken==="") {
      this.props.history.push("/login");
    }
    return (
      <EditCategoryForm
        updateCategory={this.updateCategory}
        id={this.props.match.params.id}
        propsState={this.state}
      />
    );
  }
}

export default EditCategory;
