import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../dashboard/loader";
import Spinner from "../dashboard/spinner";
import logout from "../dashboard/logout";
import { Prompt } from "react-router";
import { Modal } from "react-bootstrap";

import {
  GetError,
  QuestionRequired,
  TextAnswerRequried,
  ImageAnswerRequried,
  VideoAnswerRequried,
  PdfAnswerRequried,
  awsURL,
  mediaSizeValidation
} from "../action";
import { FileSizeErrorMessage } from "../global";
import SimpleReactValidator from "simple-react-validator";
import API from "../../api";
import AddExplantionForm from "./add-explantion-form";
import { GetAllCategory } from "../../action/category-action";
import { GetAllQuiz } from "../../action/quiz-action";
import { connect } from "react-redux";
import { truncateFileName } from "./questionUtils";

function AddInput(props) {
  return (
    <div key={props.handleIndex} className="fieldWrapper">
      <label
        htmlFor="upload"
        className="btn btn-newui"
      >{`Upload ${props.label}`}</label>
      <input
        type="file"
        id={props.handleIndex}
        className={`form-control custom_file files_${props.handleIndex}`}
        name={props.name}
        onChange={props.handlefile}
      />
      {props.spinner === props.handleIndex ? (
        <Spinner isLoading={true}></Spinner>
      ) : (
        ""
      )}

      <div className="clear-1">&nbsp;</div>
      <span
        className="fileNameMedia"
        htmlFor="file_name"
        dangerouslySetInnerHTML={{
          __html: truncateFileName(props.filename),
        }}
      ></span>
    </div>
  );
}

class AddQuestion extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      quiz_name: "",
      show: false,
      category_id: "",
      question: "",
      questionMedia: true,
      questionMediaImage: false,
      questionMediaVideo: false,
      questionMediaPdf: false,
      IsLoading: false,
      IsSubmit: false,
      questionImages: Array(5).fill(""),
      questionVideos: Array(5).fill(""),
      questionpdf: Array(5).fill(""),
      multipleImage: [0],
      multipleVideo: [0],
      multiplePdf: [0],
      optionTextContent: true,
      optionImageMedia: false,
      optionVideoMedia: false,
      optionPdfMedia: false,
      correctAnswer: "",
      optionTexts: Array(3).fill(""),
      optionImages: Array(3).fill(""),
      optionVideos: Array(3).fill(""),
      optionPdfs: Array(3).fill(""),
      explanationMedia: true,
      explanation_type: "1",
      explantionHtml: [],
      validateOption: 0,
      QustionError: true,
      TextAnswerRequried: true,
      optionType: 1,
      ImageAnswerRequried: true,
      VideoAnswerRequried: true,
      PdfAnswerRequried: true,
      validImage_error: true,
      validVideo_error: true,
      validPdf_error: true,
      validUrl_error: true,
      SuccessfulAdded: false,
      SuccessfulMsg: "",
      questionImagesIn: Array(5).fill(""),
      questionVideosIn: Array(5).fill(""),
      questionpdfIn: Array(5).fill(""),

      optionPdfsIn: Array(3).fill(""),
      optionImagesIn: Array(3).fill(""),
      optionVideosIn: Array(3).fill(""),
      shouldBlockNavigation: false,
      mediaArr: Array(),
      showLoader: true,

      questionLoaderImagesIn: Array().fill(""),
      questionLoaderVideosIn: Array(5).fill(""),
      questionLoaderpdfIn: Array(5).fill(""),

      optionLoaderImagesIn: Array(3).fill(""),
      optionLoaderVideosIn: Array(3).fill(""),
      optionLoaderpdfIn: Array(3).fill(""),

      isFormSubmit: false,
    };
    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      validators: {
        validImage: {
          message:
            "The element has allowed only .jpg , .jpeg , .png file type.",
          rule: (val, params, validator) => {
            let arr = ["jpg", "jpeg", "png", "PNG"];
            let flag = true;
            if (val.length > 0) {
              for (let i = 0; i < val.length; i++) {
                if (val[i].name !== "") {
                  let ext = /^.+\.([^.]+)$/.exec(val[i].name);
                  if (ext) {
                    if (arr.includes(ext[1])) {
                      //flag = true;
                    } else {
                      flag = false;
                    }
                  }
                }
              }
              return flag;
            } else {
              let ext = /^.+\.([^.]+)$/.exec(val.name);
              if (ext) {
                if (arr.includes(ext[1])) {
                  return true;
                } else {
                  return false;
                }
              }
            }
          },
        },
        validVideo: {
          message: "The element has allowed only .mp4 file type.",
          rule: (val, params, validator) => {
            let arr = ["mp4", "MP4"];
            let flag = true;
            if (val.length > 0) {
              for (let i = 0; i < val.length; i++) {
                if (val[i].name !== "") {
                  let ext = /^.+\.([^.]+)$/.exec(val[i].name);
                  if (ext) {
                    if (arr.includes(ext[1])) {
                      //flag = true;
                    } else {
                      flag = false;
                    }
                  }
                }
              }
              return flag;
            } else {
              let ext = /^.+\.([^.]+)$/.exec(val.name);
              if (ext) {
                if (arr.includes(ext[1])) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return true;
              }
            }
          },
        },
        validPdf: {
          message: "The element has allowed only .pdf file type.",
          rule: (val, params, validator) => {
            let arr = ["pdf"];
            let flag = true;
            if (val.length > 0) {
              for (let i = 0; i < val.length; i++) {
                if (val[i].name !== "") {
                  let ext = /^.+\.([^.]+)$/.exec(val[i].name);
                  if (ext) {
                    if (arr.includes(ext[1])) {
                      // flag = true;
                    } else {
                      flag = false;
                    }
                  }
                }
              }
              return flag;
            } else {
              let ext = /^.+\.([^.]+)$/.exec(val.name);
              if (ext) {
                if (arr.includes(ext[1])) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return true;
              }
            }
          },
        },
      },
    });
  }

  handleModal() {
    this.setState({ show: !this.state.show });
    this._isMounted = true;
    this.setState({}, () => {
      console.log(this.state);
      console.log(this.props.explanation);
    });
  }

  componentWillUnmount() {
    if (this.state.isFormSubmit === false) {
      if (this.state.mediaArr || this.props.explanation.length) {
        if (this.props.explanation.length) {
          for (const explantion of this.props.explanation) {
            if (
              explantion.explanation_type === "2" ||
              explantion.explanation_type === "3" ||
              explantion.explanation_type === "4"
            ) {
              let mediaformdataremove = new FormData();
              mediaformdataremove.append("filepath", explantion.url_media);
              API.post("quiz/remove_question_media", mediaformdataremove).then(
                (res) => { }
              );
            }
          }
        }

        if (this.state.mediaArr.length) {
          let mediaformdataremove = new FormData();
          mediaformdataremove.append("filepath", this.state.mediaArr);
          API.post("quiz/remove_question_media", mediaformdataremove).then(
            (res) => { }
          );
        }
      }
      this._isMounted = false;
    }
  }
  validImage_error = (value) => {
    this.setState({
      validImage_error: value,
    });
  };
  validVideo_error = (value) => {
    this.setState({
      validVideo_error: value,
    });
  };
  validPdf_error = (value) => {
    this.setState({
      validPdf_error: value,
    });
  };
  validUrl_error = (value) => {
    this.setState({
      validUrl_error: value,
    });
  };
  componentWillMount() {
    this.props.dispatch({ type: "DEFAULT_STATE" });
    this.props.dispatch(GetAllCategory());
  }

  handleChange = (e) => {
    let targetName = e.target.name;
    let targetValue=e.target.value;

    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        if (targetName === "category_id") {
          this.props.dispatch(GetAllQuiz(this.state.category_id));
          
        }
      }
    );

    /*if(targetName === "category_id" && targetValue == 9) {
      let optionTexts = this.state.optionTexts.slice(1);
      this.setState({ optionTexts: optionTexts });
    } else {
      if (targetName === "category_id") {
        this.setState({ optionTexts: Array(3).fill("") }); 
      }
    }*/ 


  };
  handleChangeOption = (e, index) => {

    let optionTexts = this.state.optionTexts.slice();
    optionTexts[index] = e.target.value; // Update it with the modified email.
    this.setState({ optionTexts: optionTexts });
  };

  handleMedia(e, InputType, index) {
    let media = e.target.files[0];
    let targetId = e.target.id;
    let mediaName = e.target.name;
    var isSubmitForm = "true";

    if (mediaName === "questionImages") {
      //check for file extension

      if (!media.name.match(/\.(jpg|jpeg|png|gif)$/)) {
        isSubmitForm = "false";
        toast.error("Allowed file types are: .jpg, .png, .gif, .jpeg ");
      }
    } else if (mediaName === "questionVideos") {
      if (!media.name.match(/\.(mp4|MP4)$/)) {
        isSubmitForm = "false";
        toast.error("Allowed file types are: .mp4 ");
      }
    } else if (mediaName === "questionpdf") {
      if (!media.name.match(/\.(pdf)$/)) {
        isSubmitForm = "false";
        toast.error("Allowed file types are: .pdf ");
      }
    }

    if(media.size != '') {
      let resultMedia = mediaSizeValidation(media.size);
      if(resultMedia) {
        isSubmitForm = "false";
        e.target.value = null;
        toast.error(FileSizeErrorMessage);
      }
    }
    
    if (isSubmitForm === "true") {
      let mediaformdata = new FormData();
      mediaformdata.append(e.target.name, media);

      if (
        mediaName === "questionImages" &&
        this.state.questionImagesIn[index]
      ) {
        let mediaformdataremove = new FormData();
        mediaformdataremove.append(
          "filepath",
          this.state.questionImagesIn[index]
        );
        API.post("quiz/remove_question_media", mediaformdataremove).then(
          (res) => { }
        );

        let filteredArray = this.state.mediaArr.filter(
          (item) => item !== this.state.questionImagesIn[index]
        );
        this.setState({ mediaArr: filteredArray });
      }

      if (
        mediaName === "questionVideos" &&
        this.state.questionVideosIn[index]
      ) {
        let mediaformdataremove = new FormData();
        mediaformdataremove.append(
          "filepath",
          this.state.questionVideosIn[index]
        );
        API.post("quiz/remove_question_media", mediaformdataremove).then(
          (res) => { }
        );
        let filteredArray = this.state.mediaArr.filter(
          (item) => item !== this.state.questionVideosIn[index]
        );
        this.setState({ mediaArr: filteredArray });
      }

      if (mediaName === "questionpdf" && this.state.questionpdfIn[index]) {
        let mediaformdataremove = new FormData();
        mediaformdataremove.append("filepath", this.state.questionpdfIn[index]);
        API.post("quiz/remove_question_media", mediaformdataremove).then(
          (res) => { }
        );
        let filteredArray = this.state.mediaArr.filter(
          (item) => item !== this.state.questionpdfIn[index]
        );
        this.setState({ mediaArr: filteredArray });
      }

      if (mediaName === "questionImages") {
        let questionLoaderImagesIn = this.state.questionLoaderImagesIn.slice();
        questionLoaderImagesIn[e.target.id] = index;
        this.setState({ questionLoaderImagesIn: questionLoaderImagesIn });
      } else if (mediaName === "questionVideos") {
        let questionLoaderVideosIn = this.state.questionLoaderVideosIn.slice();
        questionLoaderVideosIn[e.target.id] = index;
        this.setState({ questionLoaderVideosIn: questionLoaderVideosIn });
      } else if (mediaName === "questionpdf") {
        let questionLoaderpdfIn = this.state.questionLoaderpdfIn.slice();
        questionLoaderpdfIn[e.target.id] = index;
        this.setState({ questionLoaderpdfIn: questionLoaderpdfIn });
      }

      API.post("quiz/upload_question_media", mediaformdata)
        .then((res) => {
          if (res.status === 200) {
            if (mediaName === "questionImages") {
              let questionImagesIn = this.state.questionImagesIn.slice();
              questionImagesIn[targetId] = res.data.media_url;
              this.setState({ questionImagesIn: questionImagesIn });

              let questionImages = this.state.questionImages.slice();
              questionImages[targetId] = media;
              this.setState({ questionImages: questionImages });

              let questionLoaderImagesIn =
                this.state.questionLoaderImagesIn.slice();
              questionLoaderImagesIn[targetId] = "";
              this.setState({ questionLoaderImagesIn: questionLoaderImagesIn });
            }

            if (mediaName === "questionVideos") {
              let questionVideosIn = this.state.questionVideosIn.slice();
              questionVideosIn[targetId] = res.data.media_url;
              this.setState({ questionVideosIn: questionVideosIn });

              let questionVideos = this.state.questionVideos.slice();
              questionVideos[targetId] = media;
              this.setState({ questionVideos: questionVideos });

              let questionLoaderVideosIn =
                this.state.questionLoaderVideosIn.slice();
              questionLoaderVideosIn[targetId] = "";
              this.setState({ questionLoaderVideosIn: questionLoaderVideosIn });
            }

            if (mediaName === "questionpdf") {
              let questionpdfIn = this.state.questionpdfIn.slice();
              questionpdfIn[targetId] = res.data.media_url;
              this.setState({ questionpdfIn: questionpdfIn });

              let questionpdf = this.state.questionpdf.slice();
              questionpdf[targetId] = media;
              this.setState({ questionpdf: questionpdf });

              let questionLoaderpdfIn = this.state.questionLoaderpdfIn.slice();
              questionLoaderpdfIn[targetId] = "";
              this.setState({ questionLoaderpdfIn: questionLoaderpdfIn });
            }

            this.setState({
              mediaArr: [...this.state.mediaArr, res.data.media_url],
            });
            toast.success(res.data.message);
          } else {
            toast.error("Something went wrong on werver end.");
          }
        })
        .catch(function (error) {
          let errorobj = GetError(error);
          if (errorobj) {
            if (errorobj.status === 400) {
              toast.error(errorobj.data.message.replace(/<[^>]+>/g, ""));
            } else if (errorobj.data.status === 401) {
              toast.error(errorobj.data.message.replace(/<[^>]+>/g, ""));
              setTimeout(function () {
                logout();
              }, 2000);
            } else {
              toast.error("Something went wrong on server end.");
            }
          } else {
            toast.error("Something went wrong on server end.");
          }
        });
    }
  }
  handleOptionImages(e, index) {
    let media = e.target.files[0];
    let targetId = e.target.id;
    let mediaName = "optionImage";

    if(media.size != '') {
      let resultMedia = mediaSizeValidation(media.size);
      if(resultMedia) {
        e.target.value = null;
        toast.error(FileSizeErrorMessage);
        return false;
      }
    }

    if (!media.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      e.target.value = null;

      toast.error("Allowed file types are: .jpg, .png, .gif, .jpeg ");
    } else {
      if (this.state.optionImagesIn[index]) {
        let mediaformdataremove = new FormData();
        mediaformdataremove.append(
          "filepath",
          this.state.optionImagesIn[index]
        );
        API.post("quiz/remove_question_media", mediaformdataremove).then(
          (res) => { }
        );

        let filteredArray = this.state.mediaArr.filter(
          (item) => item !== this.state.optionImagesIn[index]
        );
        this.setState({ mediaArr: filteredArray });
      }

      let optionLoaderImagesIn = this.state.optionLoaderImagesIn.slice();
      optionLoaderImagesIn[index] = index;
      this.setState({ optionLoaderImagesIn: optionLoaderImagesIn });

      let mediaformdataoption = new FormData();
      mediaformdataoption.append(mediaName, media);

      API.post("quiz/upload_question_media", mediaformdataoption)
        .then((res) => {
          if (res.status === 200) {
            let optionImagesIn = this.state.optionImagesIn.slice();
            optionImagesIn[index] = res.data.media_url;
            this.setState({ optionImagesIn: optionImagesIn });

            let optionImages = this.state.optionImages.slice();
            optionImages[index] = media;
            this.setState({ optionImages: optionImages });

            let optionLoaderImagesIn = this.state.optionLoaderImagesIn.slice();
            optionLoaderImagesIn[index] = "";
            this.setState({ optionLoaderImagesIn: optionLoaderImagesIn });

            this.setState({
              mediaArr: [...this.state.mediaArr, res.data.media_url],
            });

            toast.success(res.data.message);
          } else {
            let optionLoaderImagesIn = this.state.optionLoaderImagesIn.slice();
            optionLoaderImagesIn[index] = "";
            this.setState({ optionLoaderImagesIn: optionLoaderImagesIn });
            toast.error("Something went wrong on werver end.");
          }
        })
        .catch(function (error) {
          let errorobj = GetError(error);
          if (errorobj) {
            if (errorobj.status === 400) {
              let optionLoaderImagesIn =
                this.state.optionLoaderImagesIn.slice();
              optionLoaderImagesIn[index] = "";
              this.setState({ optionLoaderImagesIn: optionLoaderImagesIn });
              toast.error(errorobj.data.message.replace(/<[^>]+>/g, ""));
            } else if (errorobj.data.status === 401) {
              let optionLoaderImagesIn =
                this.state.optionLoaderImagesIn.slice();
              optionLoaderImagesIn[index] = "";
              this.setState({ optionLoaderImagesIn: optionLoaderImagesIn });
              toast.error(errorobj.data.message.replace(/<[^>]+>/g, ""));
              setTimeout(function () {
                logout();
              }, 2000);
            } else {
              let optionLoaderImagesIn =
                this.state.optionLoaderImagesIn.slice();
              optionLoaderImagesIn[index] = "";
              this.setState({ optionLoaderImagesIn: optionLoaderImagesIn });
              toast.error("Something went wrong on server end.");
            }
          } else {
            let optionLoaderImagesIn = this.state.optionLoaderImagesIn.slice();
            optionLoaderImagesIn[index] = "";
            this.setState({ optionLoaderImagesIn: optionLoaderImagesIn });
            toast.error("Something went wrong on server end.");
          }
        });
    }
  }
  handleOptionVideos(e, index) {
    let media = e.target.files[0];
    let targetId = e.target.id;
    let mediaName = "optionVideos";

    if(media.size != '') {
      let resultMedia = mediaSizeValidation(media.size);
      if(resultMedia) {
        e.target.value = null;
        toast.error(FileSizeErrorMessage);
        return false;
      }
    }

    if (!media.name.match(/\.(mp4|MP4)$/)) {
      e.target.value = null;
      toast.error("Allowed file types are: .mp4 ");
    } else {
      if (this.state.optionVideosIn[index]) {
        let mediaformdataremove = new FormData();
        mediaformdataremove.append(
          "filepath",
          this.state.optionVideosIn[index]
        );
        API.post("quiz/remove_question_media", mediaformdataremove).then(
          (res) => { }
        );
        let filteredArray = this.state.mediaArr.filter(
          (item) => item !== this.state.optionVideosIn[index]
        );
        this.setState({ mediaArr: filteredArray });
      }

      let optionLoaderVideosIn = this.state.optionLoaderVideosIn.slice();
      optionLoaderVideosIn[index] = index;
      this.setState({ optionLoaderVideosIn: optionLoaderVideosIn });

      let mediaformdataoption = new FormData();
      mediaformdataoption.append(mediaName, media);

      API.post("quiz/upload_question_media", mediaformdataoption)
        .then((res) => {
          if (res.status === 200) {
            let optionVideosIn = this.state.optionVideosIn.slice();
            optionVideosIn[index] = res.data.media_url;
            this.setState({ optionVideosIn: optionVideosIn });

            let optionVideos = this.state.optionVideos.slice();
            optionVideos[index] = media;
            this.setState({ optionVideos: optionVideos });

            let optionLoaderVideosIn = this.state.optionLoaderVideosIn.slice();
            optionLoaderVideosIn[index] = "";
            this.setState({ optionLoaderVideosIn: optionLoaderVideosIn });

            this.setState({
              mediaArr: [...this.state.mediaArr, res.data.media_url],
            });

            toast.success(res.data.message);
          } else {
            let optionLoaderVideosIn = this.state.optionLoaderVideosIn.slice();
            optionLoaderVideosIn[index] = "";
            this.setState({ optionLoaderVideosIn: optionLoaderVideosIn });
            toast.error("Something went wrong on werver end.");
          }
        })
        .catch(function (error) {
          let errorobj = GetError(error);
          if (errorobj) {
            if (errorobj.status === 400) {
              let optionLoaderVideosIn =
                this.state.optionLoaderVideosIn.slice();
              optionLoaderVideosIn[index] = "";
              this.setState({ optionLoaderVideosIn: optionLoaderVideosIn });
              toast.error(errorobj.data.message.replace(/<[^>]+>/g, ""));
            } else if (errorobj.data.status === 401) {
              let optionLoaderVideosIn =
                this.state.optionLoaderVideosIn.slice();
              optionLoaderVideosIn[index] = "";
              this.setState({ optionLoaderVideosIn: optionLoaderVideosIn });
              toast.error(errorobj.data.message.replace(/<[^>]+>/g, ""));
              setTimeout(function () {
                logout();
              }, 2000);
            } else {
              let optionLoaderVideosIn =
                this.state.optionLoaderVideosIn.slice();
              optionLoaderVideosIn[index] = "";
              this.setState({ optionLoaderVideosIn: optionLoaderVideosIn });
              toast.error("Something went wrong on server end.");
            }
          } else {
            let optionLoaderVideosIn = this.state.optionLoaderVideosIn.slice();
            optionLoaderVideosIn[index] = "";
            this.setState({ optionLoaderVideosIn: optionLoaderVideosIn });
            toast.error("Something went wrong on server end.");
          }
        });
    }
  }

  handleOptionPdfs(e, index) {
    //optionPdfs

    let media = e.target.files[0];
    let targetId = e.target.id;
    let mediaName = "optionPdfs";

    if(media.size != '') {
      let resultMedia = mediaSizeValidation(media.size);
      if(resultMedia) {
        e.target.value = null;
        toast.error(FileSizeErrorMessage);
        return false;
      }
    }

    if (!media.name.match(/\.(pdf)$/)) {
      e.target.value = null;
      toast.error("Allowed file types are: .pdf ");
    } else {
      if (this.state.optionPdfsIn[index]) {
        let mediaformdataremove = new FormData();
        mediaformdataremove.append("filepath", this.state.optionPdfsIn[index]);
        API.post("quiz/remove_question_media", mediaformdataremove).then(
          (res) => { }
        );
        let filteredArray = this.state.mediaArr.filter(
          (item) => item !== this.state.optionPdfsIn[index]
        );
        this.setState({ mediaArr: filteredArray });
      }

      let optionLoaderpdfIn = this.state.optionLoaderpdfIn.slice();
      optionLoaderpdfIn[index] = index;
      this.setState({ optionLoaderpdfIn: optionLoaderpdfIn });

      let mediaformdataoption = new FormData();
      mediaformdataoption.append(mediaName, media);

      API.post("quiz/upload_question_media", mediaformdataoption)
        .then((res) => {
          if (res.status === 200) {
            let optionPdfsIn = this.state.optionPdfsIn.slice();
            optionPdfsIn[index] = res.data.media_url;
            this.setState({ optionPdfsIn: optionPdfsIn });

            let optionPdfs = this.state.optionPdfs.slice();
            optionPdfs[index] = media;
            this.setState({ optionPdfs: optionPdfs });

            let optionLoaderpdfIn = this.state.optionLoaderpdfIn.slice();
            optionLoaderpdfIn[index] = "";
            this.setState({ optionLoaderpdfIn: optionLoaderpdfIn });

            this.setState({
              mediaArr: [...this.state.mediaArr, res.data.media_url],
            });

            toast.success(res.data.message);
          } else {
            let optionLoaderpdfIn = this.state.optionLoaderpdfIn.slice();
            optionLoaderpdfIn[index] = "";
            this.setState({ optionLoaderpdfIn: optionLoaderpdfIn });
            toast.error("Something went wrong on werver end.");
          }
        })
        .catch(function (error) {
          let errorobj = GetError(error);
          if (errorobj) {
            if (errorobj.status === 400) {
              let optionLoaderpdfIn = this.state.optionLoaderpdfIn.slice();
              optionLoaderpdfIn[index] = "";
              this.setState({ optionLoaderpdfIn: optionLoaderpdfIn });
              toast.error(errorobj.data.message.replace(/<[^>]+>/g, ""));
            } else if (errorobj.data.status === 401) {
              let optionLoaderpdfIn = this.state.optionLoaderpdfIn.slice();
              optionLoaderpdfIn[index] = "";
              this.setState({ optionLoaderpdfIn: optionLoaderpdfIn });
              toast.error(errorobj.data.message.replace(/<[^>]+>/g, ""));
              setTimeout(function () {
                logout();
              }, 2000);
            } else {
              let optionLoaderpdfIn = this.state.optionLoaderpdfIn.slice();
              optionLoaderpdfIn[index] = "";
              this.setState({ optionLoaderpdfIn: optionLoaderpdfIn });
              toast.error("Something went wrong on server end.");
            }
          } else {
            let optionLoaderpdfIn = this.state.optionLoaderpdfIn.slice();
            optionLoaderpdfIn[index] = "";
            this.setState({ optionLoaderpdfIn: optionLoaderpdfIn });
            toast.error("Something went wrong on server end.");
          }
        });
    }
  }

  handleClick(e, value, name) {
    this.setState({
      [name]: value,
    });
  }

  handleClickOption(e, value, option, type, category_id) {
    this.setState(
      {
        optionTextContent: false,
        optionImageMedia: false,
        optionVideoMedia: false,
        optionPdfMedia: false,
        optionTexts: Array(3).fill(""),
        optionImages: Array(3).fill(""),
        optionVideos: Array(3).fill(""),
        optionPdfs: Array(3).fill(""),

        optionImagesIn: Array(3).fill(""),
        optionVideosIn: Array(3).fill(""),
        optionPdfsIn: Array(3).fill(""),
        [option]: value,
        optionType: type,
        QustionError: true,
        TextAnswerRequried: true,
        ImageAnswerRequried: true,
        VideoAnswerRequried: true,
        PdfAnswerRequried: true,
      },
      () => {
        if (this.optionType !== 2) {
          document.getElementsByClassName("imageInput")[0].value = null;
        }
        if (this.optionType !== 3) {
          document.getElementsByClassName("videoInput")[0].value = null;
        }
        if (this.optionType !== 4) {
          document.getElementsByClassName("pdfInput")[0].value = null;
        }
      }
    );

    /*if(category_id == 9) {
      
      if(type == 1) {
        let optionTexts = this.state.optionTexts.slice(1);
        this.setState({ optionTexts: optionTexts });  
      } 

      if(type == 2) { 
        let optionImages = this.state.optionImages.slice(1);
        this.setState({ optionImages: optionImages });
      } 

      if(type == 3) {
        let optionVideos = this.state.optionVideos.slice(1);
        this.setState({ optionVideos: optionVideos });
      } 

      if(type == 4) { 
        let optionPdfs = this.state.optionPdfs.slice(1);
        this.setState({ optionPdfs: optionPdfs }); 
      }  
    } */


  }

  addExplantion = (e, value) => {
    this.setState({
      explantionHtml: [...this.state.explantionHtml, 1],
      shouldBlockNavigation: true,
    });
  };

  addMoreImage = (e) => {
    let len = parseInt(this.state.multipleImage.length) + 1;
    var data = this.state.multipleImage.filter(function (number) {
      return number !== null;
    });
    this.setState({
      multipleImage: [...data, len - 1],
    });
  };

  addMoreVideo = (e) => {
    let len = parseInt(this.state.multipleVideo.length) + 1;
    var data = this.state.multipleVideo.filter(function (number) {
      return number !== null;
    });
    this.setState({
      multipleVideo: [...data, len - 1],
    });
  };

  addMorePdf = (e) => {
    let len = parseInt(this.state.multiplePdf.length) + 1;
    var data = this.state.multiplePdf.filter(function (number) {
      return number !== null;
    });
    this.setState({
      multiplePdf: [...data, len - 1],
    });
  };

  removeInputfile = (e, index, newArr, fileArr, removeType) => {
    if (removeType === "multipleImage") {
      var mediaurl = this.state.questionImagesIn[index];
      newArr.splice(index, 1);
      fileArr.splice(index, 1);

      this.state.questionImagesIn.splice(index, 1);
      this.setState({
        multipleImage: newArr,
      });
    } else if (removeType === "multipleVideo") {
      var mediaurl = this.state.questionVideosIn[index];

      newArr.splice(index, 1);
      fileArr.splice(index, 1);
      this.state.questionVideosIn.splice(index, 1);
      this.setState({
        multipleVideo: newArr,
      });
    } else if (removeType === "multiplePdf") {
      var mediaurl = this.state.questionpdfIn[index];
      newArr.splice(index, 1);
      fileArr.splice(index, 1);
      this.state.questionpdfIn.splice(index, 1);
      this.setState({
        multiplePdf: newArr,
      });
    }

    if (mediaurl !== "") {
      let mediaformdataremove = new FormData();
      mediaformdataremove.append("filepath", mediaurl);

      API.post("quiz/remove_question_media", mediaformdataremove)
        .then((res) => {
          if (res.status === 200) {
            if (this._isMounted) {
              this.setState({
                IsLoading: false,
                IsSubmit: false,
              });
            }

            let filteredArray = this.state.mediaArr.filter(
              (item) => item !== mediaurl
            );
            this.setState({ mediaArr: filteredArray });

            toast.success(res.data.message);
          } else {
            toast.error("Something went wrong on werver end.");
          }
        })
        .catch(function (error) {
          let errorobj = GetError(error);
          if (errorobj) {
            if (errorobj.status === 400) {
              toast.error(errorobj.data.message);
            } else if (errorobj.data.status === 401) {
              toast.error(errorobj.data.message);
              setTimeout(function () {
                logout();
              }, 2000);
            } else {
              toast.error("Something went wrong on server end.");
            }
          } else {
            toast.error("Something went wrong on server end.");
          }
        });
    }
    //remove_question_media
  };

  submitQutionAnswer(e) {
    e.preventDefault();
    let self = this;
    this._isMounted = true;
    this.setState(
      {
        QustionError: QuestionRequired(this.state),
        TextAnswerRequried: TextAnswerRequried(this.state),
        ImageAnswerRequried: ImageAnswerRequried(this.state),
        VideoAnswerRequried: VideoAnswerRequried(this.state),
        PdfAnswerRequried: PdfAnswerRequried(this.state),
      },
      () => {
        if (
          this.validator.allValid() &&
          this.state.QustionError &&
          this.state.TextAnswerRequried &&
          this.state.ImageAnswerRequried &&
          this.state.VideoAnswerRequried &&
          this.state.PdfAnswerRequried &&
          this.state.validImage_error &&
          this.state.validVideo_error &&
          this.state.validPdf_error &&
          this.state.validUrl_error
        ) {
          this.setState({
            IsSubmit: true,
            IsLoading: true,
          });
          let formdata = new FormData();
          formdata.append("category_id", this.state.category_id);
          formdata.append("quiz_name", this.state.quiz_name);
          formdata.append("question", this.state.question);

          formdata.append("questionImagesIn", this.state.questionImagesIn);
          formdata.append("questionVideosIn", this.state.questionVideosIn);
          formdata.append("questionpdfIn", this.state.questionpdfIn);
          formdata.append("optionType", this.state.optionType);

          formdata.append("optionImages", this.state.optionImagesIn);
          formdata.append("optionVideos", this.state.optionVideosIn);
          formdata.append("optionPdfs", this.state.optionPdfsIn);

          if (this.state.optionTexts) {
            for (const text of this.state.optionTexts) {
              formdata.append("optionTexts[]", text);
            }
          }

          formdata.append("correctAnswer", this.state.correctAnswer);
          if (this.props.explanation) {
            for (const explantion of this.props.explanation) {
              if (explantion.explanation_type === "1") {
                formdata.append(
                  "explanationArr[]",
                  "text_" + explantion.explantion_key
                );
                formdata.append(
                  "text_" + explantion.explantion_key,
                  explantion.explanation_media
                );
              } else if (explantion.explanation_type === "2") {
                formdata.append(
                  "explanationArr[]",
                  "image_" + explantion.explantion_key
                );
                formdata.append(
                  "image_" + explantion.explantion_key,
                  explantion.url_media
                );
              } else if (explantion.explanation_type === "3") {
                formdata.append(
                  "explanationArr[]",
                  "video_" + explantion.explantion_key
                );
                formdata.append(
                  "video_" + explantion.explantion_key,
                  explantion.url_media
                );
              } else if (explantion.explanation_type === "4") {
                formdata.append(
                  "explanationArr[]",
                  "pdf_" + explantion.explantion_key
                );

                formdata.append(
                  "pdf_" + explantion.explantion_key,
                  explantion.url_media
                );
              } else if (explantion.explanation_type === "5") {
                formdata.append(
                  "explanationArr[]",
                  "url_" + explantion.explantion_key
                );

                formdata.append(
                  "url_" + explantion.explantion_key,
                  explantion.explanation_media
                );
              }
            }
          }

          API.post("quiz/add_question", formdata)
            .then((res) => {
              if (res.status === 200) {
                if (this._isMounted) {
                  this.setState({
                    IsLoading: false,
                    //   IsSubmit: false,
                    mediaArr: Array(),
                    isFormSubmit: true,
                  });
                }
                this.props.history.push("/questionlist");

                toast.success(res.data.message);
              } else {
                toast.error("Something went wrong on werver end.");
              }
            })
            .catch(function (error) {
              let errorobj = GetError(error);
              if (errorobj) {
                if (errorobj.status === 400) {
                  toast.error(errorobj.data.message);
                } else if (errorobj.status === 401) {
                  toast.error(errorobj.data.message);
                  setTimeout(function () {
                    logout();
                  }, 2000);
                } else {
                  toast.error("Something went wrong on server end.");
                }
              } else {
                toast.error("Something went wrong on server end.");
              }
              self.setState({
                IsSubmit: false,
                IsLoading: false,
              });
            });
        } else {
          this.validator.showMessages();
          this.forceUpdate();
          this.setState({
            IsLoading: false,
            IsSubmit: false,
          });
        }
      }
    );
  }

  render() {
    const questionImagesArr = [...this.state.questionImages];
    const questionVideosArr = [...this.state.questionVideos];
    const questionpdfArr = [...this.state.questionpdf];

    return (
      <div>
        <Prompt
          when={
            (this.state.mediaArr.length ||
              this.state.shouldBlockNavigation === true) &&
            this.state.isFormSubmit === false
          }
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <Loader isLoading={this.state.IsLoading} />
        <div className="page-wrapper">
          <div className="container-fluid custom-form-design ">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <h4 className="card-title">Add Question</h4>

                  <div className="col-md-6">
                    <form className="form-horizontal">
                      <div className="card-body">
                        <div className="form-group row">
                          <div className="col-sm-12" data-select2-id="11">
                            <label className="control-label col-form-label label-text">
                              Category Title<sup className="req-color">*</sup>
                            </label>
                            <select
                              className="select2 form-control custom-select select2-hidden-accessible"
                              data-select2-id="1"
                              aria-hidden="true"
                              name="category_id"
                              onChange={(e) => this.handleChange(e)}
                            >
                              <option value="">Select Category Title</option>

                              {this.props.load_category.length > 0
                                ? this.props.load_category.map(
                                  (category, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={category.category_id}
                                      >
                                        {category.category_name}
                                      </option>
                                    );
                                  }
                                )
                                : ""}
                            </select>
                            {this.validator.message(
                              "Category Title",
                              this.state.category_id,
                              "required"
                            )}
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-md-12" data-select2-id="11">
                            <label className="control-label col-form-label label-text">
                              Quiz Title<sup className="req-color">*</sup>
                            </label>
                            <select
                              className="select2 form-control custom-select select2-hidden-accessible"
                              data-select2-id="1"
                              aria-hidden="true"
                              name="quiz_name"
                              onChange={(e) => this.handleChange(e)}
                            >
                              <option value="">Select Quiz Title</option>
                              {this.props.load_quiz.length > 0
                                ? this.props.load_quiz.map((quiz, index) => {
                                  return (
                                    <option key={index} value={quiz.quiz_id}>
                                      {quiz.quiz_name}
                                    </option>
                                  );
                                })
                                : ""}
                            </select>
                            {this.validator.message(
                              "Quiz Title",
                              this.state.quiz_name,
                              "required"
                            )}
                          </div>
                        </div>
                        {/* Question Start */}
                        {/* <h4 className="card-title">Question</h4> */}

                        <div className="form-group row">
                          <div className="col-sm-12">
                            <label className="control-label col-form-label label-text">
                              Question<sup className="req-color">*</sup>
                            </label>
                            <textarea
                              type="text"
                              className="form-control custom-input"
                              id="fname"
                              placeholder="Enter Question "
                              value={this.state.question}
                              name="question"
                              onChange={(e) => this.handleChange(e)}
                              rows="4"
                              cols="50"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <button
                            type="button"
                            className="btn btn-newui"
                            name="questionMedia"
                            onClick={(e) =>
                              this.handleClick(
                                e,
                                this.state.questionMedia ? false : "",
                                "questionMedia"
                              )
                            }
                            disabled={this.state.questionMedia === false}
                          >
                            Add Attachment
                          </button>

                          <div
                            className={
                              this.state.questionMedia
                                ? "col-sm-8 hide_question_media"
                                : "col-sm-8"
                            }
                          >
                            <button
                              type="button"
                              className={
                                this.state.questionMediaImage
                                  ? "btn btn-dark btn-sm"
                                  : "btn btn-light btn-sm"
                              }
                              title="image"
                              name="questionMediaImage"
                              onClick={(e) =>
                                this.handleClick(
                                  e,
                                  this.state.questionMediaImage ? false : true,
                                  "questionMediaImage"
                                )
                              }
                            >
                              <i className="fas fa-image" />
                            </button>

                            <button
                              type="button"
                              className={
                                this.state.questionMediaVideo
                                  ? "btn btn-dark btn-sm"
                                  : "btn btn-light btn-sm"
                              }
                              title="video"
                              name="questionMediaVideo"
                              onClick={(e) =>
                                this.handleClick(
                                  e,
                                  this.state.questionMediaVideo ? false : true,
                                  "questionMediaVideo"
                                )
                              }
                            >
                              <i className="fas fa-video" />
                            </button>
                            <button
                              type="button"
                              className={
                                this.state.questionMediaPdf
                                  ? "btn btn-dark btn-sm"
                                  : "btn btn-light btn-sm"
                              }
                              title="pdf"
                              name="questionMediaPdf"
                              onClick={(e) =>
                                this.handleClick(
                                  e,
                                  this.state.questionMediaPdf ? false : true,
                                  "questionMediaPdf"
                                )
                              }
                            >
                              <i className="fas fa-file-pdf" />
                            </button>
                          </div>
                        </div>
                        <div
                          className="form-group row"
                          style={
                            this.state.questionMediaImage
                              ? {}
                              : this.state.questionMediaVideo
                                ? {}
                                : this.state.questionMediaPdf
                                  ? {}
                                  : { display: "none" }
                          }
                        >
                          <div
                            className={
                              this.state.questionMediaImage
                                ? "col-sm-4"
                                : "col-sm-4 hide_question_media"
                            }
                          >
                            {/* NOTE :- This button for add more image for a question. we don't need add more functionality yet but it's completed on my end. if you can uncomment then add more button is enable .Thanks */}

                            <button
                              type="button"
                              className="btn btn-primary-newui btn-md-newui"
                              name="questionImages"
                              onClick={(e) => this.addMoreImage(e)}
                              disabled={
                                this.state.multipleImage.length < 5
                                  ? false
                                  : true
                              }
                            >
                              Add more
                            </button>
                            {this.state.multipleImage.map((x, index) => (
                              <div key={index} className="fieldWrapper">
                                <i
                                  className="fas fa-times-circle removeInput"
                                  name="multipleImage"
                                  onClick={(e) =>
                                    this.removeInputfile(
                                      e,
                                      index,
                                      this.state.multipleImage,
                                      this.state.questionImages,
                                      "multipleImage"
                                    )
                                  }
                                />

                                <AddInput
                                  name="questionImages"
                                  handleIndex={index}
                                  label="IMAGE"
                                  filename={
                                    questionImagesArr[index]
                                      ? questionImagesArr[index].name
                                      : ""
                                  }
                                  handlefile={(e) =>
                                    this.handleMedia(
                                      e,
                                      this.state.questionImages,
                                      index
                                    )
                                  }
                                  spinner={
                                    this.state.questionLoaderImagesIn[index]
                                  }
                                />
                              </div>
                            ))}

                            <div className="clear-1"></div>
                            <small>
                              Allowed file types are: .jpg, .png, .gif, .jpeg
                            </small>

                            {this.validator.message(
                              "Question Image",
                              this.state.questionImages,
                              "validImage"
                            )}
                          </div>
                          <div
                            className={
                              this.state.questionMediaVideo
                                ? "col-sm-4"
                                : "col-sm-4 hide_question_media"
                            }
                          >
                            {/* NOTE :- This button for add more Video for a question. we don't need add more functionality yet but it's completed on my end. if you can uncomment then add more button is enable .Thanks */}
                            <button
                              type="button"
                              className="btn btn-primary-newui btn-md-newui"
                              name="questionVideos"
                              onClick={(e) => this.addMoreVideo(e)}
                              disabled={
                                this.state.multipleVideo.length < 5
                                  ? false
                                  : true
                              }
                            >
                              Add more
                            </button>
                            {this.state.multipleVideo.map((x, index) => (
                              <div key={index} className="fieldWrapper">
                                <i
                                  className="fas fa-times-circle removeInput"
                                  name="multipleVideo"
                                  onClick={(e) =>
                                    this.removeInputfile(
                                      e,
                                      index,
                                      this.state.multipleVideo,
                                      this.state.questionVideos,
                                      "multipleVideo"
                                    )
                                  }
                                />
                                <AddInput
                                  name="questionVideos"
                                  handleIndex={index}
                                  label="VIDEO"
                                  filename={
                                    questionVideosArr[index]
                                      ? questionVideosArr[index].name
                                      : ""
                                  }
                                  handlefile={(e) =>
                                    this.handleMedia(
                                      e,
                                      this.state.questionVideos,
                                      index
                                    )
                                  }
                                  spinner={
                                    this.state.questionLoaderVideosIn[index]
                                  }
                                />
                              </div>
                            ))}
                            <div className="clear-1"></div>
                            <small>Allowed file type is: .mp4</small>
                            {this.validator.message(
                              "Question Video",
                              this.state.questionVideos,
                              "validVideo"
                            )}
                          </div>
                          <div
                            className={
                              this.state.questionMediaPdf
                                ? "col-sm-4"
                                : "col-sm-4 hide_question_media"
                            }
                          >
                            {/* NOTE :- This button for add more PDF for a question. we don't need add more functionality yet but it's completed on my end. if you can uncomment then add more button is enable .Thanks */}
                            <button
                              type="button"
                              className="btn btn-primary-newui btn-md-newui"
                              name="questionpdf"
                              onClick={(e) => this.addMorePdf(e)}
                              disabled={
                                this.state.multiplePdf.length < 5 ? false : true
                              }
                            >
                              Add more
                            </button>

                            {this.state.multiplePdf.map((x, index) => (
                              <div key={index} className="fieldWrapper">
                                <i
                                  className="fas fa-times-circle removeInput"
                                  name="multiplePdf"
                                  onClick={(e) =>
                                    this.removeInputfile(
                                      e,
                                      index,
                                      this.state.multiplePdf,
                                      this.state.questionpdf,
                                      "multiplePdf"
                                    )
                                  }
                                />
                                <AddInput
                                  name="questionpdf"
                                  handleIndex={index}
                                  label="PDF"
                                  filename={
                                    questionpdfArr[index]
                                      ? questionpdfArr[index].name
                                      : ""
                                  }
                                  handlefile={(e) =>
                                    this.handleMedia(
                                      e,
                                      this.state.questionpdf,
                                      index
                                    )
                                  }
                                  spinner={
                                    this.state.questionLoaderpdfIn[index]
                                  }
                                />
                              </div>
                            ))}
                            <div className="clear-1"></div>
                            <small>Allowed file type is: .pdf</small>
                            {this.validator.message(
                              "Question Video",
                              this.state.questionpdf,
                              "validPdf"
                            )}
                          </div>
                        </div>

                        <span className="srv-validation-message">
                          {this.state.QustionError === false
                            ? "The question field is required."
                            : ""}
                        </span>
                        {/* Question End */}
                        {/* Answer options Start */}
                        <h4 className="card-title pd-ans">Answer</h4>
                        <div className="form-group row">
                          <button
                            type="button"
                            title="Text"
                            className={
                              this.state.optionTextContent
                                ? "btn btn-dark btn-sm"
                                : "btn btn-light btn-sm"
                            }
                            name="optionTextContent"
                            onClick={(e) =>
                              this.handleClickOption(
                                e,
                                this.state.optionTextContent ? false : true,
                                "optionTextContent",
                                1,
                                this.state.category_id
                              )
                            }
                          >
                            <i className="fas fa-text-height" />
                          </button>
                          <button
                            type="button"
                            title="Image"
                            className={
                              this.state.optionImageMedia
                                ? "btn btn-dark btn-sm"
                                : "btn btn-light btn-sm"
                            }
                            name="optionImageMedia"
                            onClick={(e) =>
                              this.handleClickOption(
                                e,
                                this.state.optionImageMedia ? false : true,
                                "optionImageMedia",
                                2,
                                this.state.category_id
                              )
                            }
                          >
                            <i className="fas fa-image" />
                          </button>
                          <button
                            type="button"
                            title="Video"
                            className={
                              this.state.optionVideoMedia
                                ? "btn btn-dark btn-sm"
                                : "btn btn-light btn-sm"
                            }
                            name="optionVideoMedia"
                            onClick={(e) =>
                              this.handleClickOption(
                                e,
                                this.state.optionVideoMedia ? false : true,
                                "optionVideoMedia",
                                3,
                                this.state.category_id
                              )
                            }
                          >
                            <i className="fas fa-video" />
                          </button>
                          <button
                            type="button"
                            title="Pdf"
                            className={
                              this.state.optionPdfMedia
                                ? "btn btn-dark btn-sm"
                                : "btn btn-light btn-sm"
                            }
                            name="optionPdfMedia"
                            onClick={(e) =>
                              this.handleClickOption(
                                e,
                                this.state.optionPdfMedia ? false : true,
                                "optionPdfMedia",
                                4,
                                this.state.category_id
                              )
                            }
                          >
                            <i className="fas fa-file-pdf" />
                          </button>
                        </div>
                        {/* options text start */}
                        <div
                          className={
                            this.state.optionTextContent
                              ? ""
                              : "hide_question_media"
                          }
                        >
                          {this.state.optionTexts.length > 0
                            ? this.state.optionTexts.map(
                              (optiontext, index) => (
                                <div className="form-group row" key={index}>
                                  <div className="col-sm-12">
                                    <label className="control-label col-form-label label-text">
                                      Option {index + 1}
                                      <sup className='req-color'>
                                      { this.state.category_id == 9 && index == 2 ? '' : "*" }
                                      </sup>
                                      
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control custom-input"
                                      placeholder={"Option " + (index + 1)}
                                      value={this.state.optionTexts[index]}
                                      onChange={(e) =>
                                        this.handleChangeOption(e, index)
                                      }
                                    />
                                    {/* {this.validator.message(
                                        "Option text",
                                        this.state.optionTexts[index],
                                        "alpha_num_dash_space"
                                      )} */}
                                  </div>
                                </div>
                              )
                            )
                            : ""}

                          <span className="srv-validation-message">
                            {this.state.TextAnswerRequried === false
                              ? "Text options are required."
                              : ""}
                          </span>
                        </div>
                        {/* Option text end */}
                        {/* Option image start */}
                        <div
                          className={
                            this.state.optionImageMedia
                              ? ""
                              : "hide_question_media"
                          }
                        >
                          {this.state.optionImages.length > 0
                            ? this.state.optionImages.map(
                              (optionimg, index) => (
                                <div className="form-group row" key={index}>
                                  <div className="col-sm-12">
                                    <label className=" control-label col-form-label label-text">
                                      Option {index + 1} Image
                                      <sup className='req-color'>
                                      { this.state.category_id == 9 && index == 2 ? '' : "*" }
                                      </sup>
                                    </label>
                                    <input
                                      type="file"
                                      className="form-control imageInput"
                                      onChange={(e) =>
                                        this.handleOptionImages(e, index)
                                      }
                                    />
                                    {this.state.optionLoaderImagesIn[
                                      index
                                    ] === index ? (
                                      <Spinner isLoading={true}></Spinner>
                                    ) : (
                                      ""
                                    )}
                                    <div className="clear-1"></div>
                                    <small>
                                      Allowed file types are: .jpg, .png,
                                      .gif, .jpeg
                                    </small>
                                    {this.validator.message(
                                      "Option Image",
                                      this.state.optionImages[index],
                                      "validImage"
                                    )}
                                  </div>
                                </div>
                              )
                            )
                            : ""}

                          <span className="srv-validation-message">
                            {this.state.ImageAnswerRequried === false
                              ? "Image options are required."
                              : ""}
                          </span>
                        </div>
                        {/* Option image end */}
                        {/* Option Video start */}
                        <div
                          className={
                            this.state.optionVideoMedia
                              ? ""
                              : "hide_question_media"
                          }
                        >
                          {this.state.optionVideos.length > 0
                            ? this.state.optionVideos.map(
                              (optionvideo, index) => (
                                <div className="form-group row" key={index}>
                                  <div className="col-sm-12">
                                    <label className="control-label col-form-label label-text">
                                      Option {index + 1} Video
                                      <sup className='req-color'>
                                      { this.state.category_id == 9 && index == 2 ? '' : "*" }
                                      </sup>
                                    </label>
                                    <input
                                      type="file"
                                      className="form-control videoInput"
                                      onChange={(e) =>
                                        this.handleOptionVideos(e, index)
                                      }
                                    />
                                    {this.state.optionLoaderVideosIn[
                                      index
                                    ] === index ? (
                                      <Spinner isLoading={true}></Spinner>
                                    ) : (
                                      ""
                                    )}
                                    <div className="clear-1"></div>
                                    <small>Allowed file type is: .mp4</small>
                                    {this.validator.message(
                                      "Option Video",
                                      this.state.optionVideos[index],
                                      "validVideo"
                                    )}
                                  </div>
                                </div>
                              )
                            )
                            : ""}

                          <span className="srv-validation-message">
                            {this.state.VideoAnswerRequried === false
                              ? "Video options are required."
                              : ""}
                          </span>
                        </div>
                        {/* Option Video end */}
                        {/* Option Pdf start */}
                        <div
                          className={
                            this.state.optionPdfMedia
                              ? ""
                              : "hide_question_media"
                          }
                        >
                          {this.state.optionPdfs.length > 0
                            ? this.state.optionPdfs.map((optionpdf, index) => (
                              <div className="form-group row" key={index}>
                                <div className="col-sm-12">
                                  <label className=" control-label col-form-label label-text">
                                    Option {index + 1} Pdf
                                    <sup className='req-color'>
                                    { this.state.category_id == 9 && index == 2 ? '' : "*" }
                                    </sup>
                                  </label>
                                  <input
                                    type="file"
                                    className="form-control pdfInput"
                                    onChange={(e) =>
                                      this.handleOptionPdfs(e, index)
                                    }
                                  />
                                  {this.state.optionLoaderpdfIn[index] ===
                                    index ? (
                                    <Spinner isLoading={true}></Spinner>
                                  ) : (
                                    ""
                                  )}
                                  <small>Allowed file type is: .pdf</small>
                                  {this.validator.message(
                                    "Option Pdf",
                                    this.state.optionPdfs[index],
                                    "validPdf"
                                  )}
                                </div>
                              </div>
                            ))
                            : ""}

                          <span className="srv-validation-message">
                            {this.state.PdfAnswerRequried === false
                              ? "Pdf options are required."
                              : ""}
                          </span>
                        </div>
                        {/* Option Pdf end */}
                        {/* Correct answer start*/}
                        <div className="form-group row">
                          <div className="col-md-12" data-select2-id="11">
                            <label className="control-label col-form-label label-text">
                              Correct Answer<sup className="req-color">*</sup>
                            </label>
                            <select
                              className="select2 form-control custom-select select2-hidden-accessible"
                              data-select2-id="1"
                              aria-hidden="true"
                              name="correctAnswer"
                              onChange={(e) => this.handleChange(e)}
                            >
                              <option value="">Select Correct Answer</option>
                              <option value="0">Option 1</option>
                              <option value="1">Option 2</option>
                              <option value="2">Option 3</option>
                              {/* <option value="3">Option 4</option> */}
                            </select>
                            {this.validator.message(
                              "Correct Answer",
                              this.state.correctAnswer,
                              "required"
                            )}
                          </div>
                        </div>
                        {/* Correct answer end */}
                        {/* Answer options End */}
                        {/* Explanation Start */}
                        <h4 className="card-title">Explanation</h4>

                        <div className="form-group row">
                          <div className="col-sm-6">
                            <button
                              type="button"
                              className="btn btn-newui"
                              name="explanationMedia"
                              onClick={(e) =>
                                this.addExplantion(
                                  e,
                                  this.state.explanationMedia ? false : true
                                )
                              }
                            >
                              Add explanation
                            </button>
                          </div>
                          <div className="col-sm-6" />
                        </div>

                        {this.state.explantionHtml.map((form, index) => (
                          <AddExplantionForm
                            key={index}
                            objKey={index}
                            validImage_error={this.validImage_error}
                            validVideo_error={this.validVideo_error}
                            validPdf_error={this.validPdf_error}
                            validUrl_error={this.validUrl_error}
                          />
                        ))}

                        {/* Explanation end */}
                        <div className="">
                          <div className="">
                            <button
                              type="button"
                              className="btn btn-primary-newui btn-md-newui"
                              disabled={this.state.IsSubmit}
                              onClick={(e) => this.submitQutionAnswer(e)}
                            >
                              Save
                            </button>
                            {/* <Loader isLoading={this.state.isLoad} /> */}
                            <NavLink
                              to="/questionlist"
                              type="button"
                              className="btn btn-primary-newui btn-md-newui"
                            >
                              Cancel
                            </NavLink>
                            <button
                              type="button"
                              className="btn btn-primary-newui btn-md-newui"
                              onClick={() => this.handleModal()}
                            >
                              Preview
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.show} onHide={() => this.handleModal()}>
          <Modal.Header>Question Preview</Modal.Header>
          <Modal.Body>
            <div className="border">
              <div className="question bg-white p-3 border-bottom">
                <div className="d-flex flex-row align-items-center question-title">
                  <h3 className="text-danger">Q.</h3>
                  <h5 className="mt-1 ml-2">{this.state.question}</h5>
                </div>

                {this.state.questionImagesIn.length > 0 ? (
                  <div>
                    {this.state.questionImagesIn[0] ? (
                      <div className="ans ml-2">
                        1.
                        <img
                          src={awsURL + "" + this.state.questionImagesIn[0].replace("api/media/", "")}
                          alt="QuestionImage"
                          style={{ width: "200px" }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.questionImagesIn[1] ? (
                      <div className="ans ml-2">
                        2.
                        <img
                          src={awsURL + "" + this.state.questionImagesIn[1].replace("api/media/", "")}
                          alt="QuestionImage"
                          style={{ width: "200px" }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.questionImagesIn[2] ? (
                      <div className="ans ml-2">
                        3.
                        <img
                          src={awsURL + "" + this.state.questionImagesIn[2].replace("api/media/", "")}
                          alt="QuestionImage"
                          style={{ width: "200px" }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.questionImagesIn[3] ? (
                      <div className="ans ml-2">
                        4.
                        <img
                          src={awsURL + "" + this.state.questionImagesIn[3].replace("api/media/", "")}
                          alt="QuestionImage"
                          style={{ width: "200px" }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.questionImagesIn[4] ? (
                      <div className="ans ml-2">
                        5.
                        <img
                          src={awsURL + "" + this.state.questionImagesIn[4].replace("api/media/", "")}
                          alt="QuestionImage"
                          style={{ width: "200px" }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}

                {this.state.questionVideosIn.length > 0 ? (
                  <div>
                    {this.state.questionVideosIn[0] ? (
                      <div className="ans ml-2">
                        1.
                        <video width="320" height="240" controls>
                          <source
                            src={
                              awsURL + "" + this.state.questionVideosIn[0].replace("api/media/", "")
                            }
                            type="video/mp4"
                          />
                        </video>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.questionVideosIn[1] ? (
                      <div className="ans ml-2">
                        2.
                        <video width="320" height="240" controls>
                          <source
                            src={
                              awsURL + "" + this.state.questionVideosIn[1].replace("api/media/", "")
                            }
                            type="video/mp4"
                          />
                        </video>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.questionVideosIn[2] ? (
                      <div className="ans ml-2">
                        3.
                        <video width="320" height="240" controls>
                          <source
                            src={
                              awsURL + "" + this.state.questionVideosIn[2].replace("api/media/", "")
                            }
                            type="video/mp4"
                          />
                        </video>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.questionVideosIn[3] ? (
                      <div className="ans ml-2">
                        4.
                        <video width="320" height="240" controls>
                          <source
                            src={
                              awsURL + "" + this.state.questionVideosIn[3].replace("api/media/", "")
                            }
                            type="video/mp4"
                          />
                        </video>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.questionVideosIn[4] ? (
                      <div className="ans ml-2">
                        5.
                        <video width="320" height="240" controls>
                          <source
                            src={
                              awsURL + "" + this.state.questionVideosIn[4].replace("api/media/", "")
                            }
                            type="video/mp4"
                          />
                        </video>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}

                {this.state.questionpdfIn.length > 0 ? (
                  <div>
                    {this.state.questionpdfIn[0] ? (
                      <div className="ans ml-2">
                        1.
                        <a
                          href={awsURL + "/" + this.state.questionpdfIn[0].replace("api/media/", "")}
                          alt="demo"
                          style={{ width: "200px" }}
                          target="_blank"
                        >
                          pdf1
                        </a>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.questionpdfIn[1] ? (
                      <div className="ans ml-2">
                        2.
                        <a
                          href={awsURL + "/" + this.state.questionpdfIn[1].replace("api/media/", "")}
                          alt="demo"
                          style={{ width: "200px" }}
                          target="_blank"
                        >
                          pdf1
                        </a>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.questionpdfIn[2] ? (
                      <div className="ans ml-2">
                        3.
                        <a
                          href={awsURL + "/" + this.state.questionpdfIn[2].replace("api/media/", "")}
                          alt="demo"
                          style={{ width: "200px" }}
                          target="_blank"
                        >
                          pdf1
                        </a>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.questionpdfIn[3] ? (
                      <div className="ans ml-2">
                        4.
                        <a
                          href={awsURL + "/" + this.state.questionpdfIn[3].replace("api/media/", "")}
                          alt="demo"
                          style={{ width: "200px" }}
                          target="_blank"
                        >
                          pdf1
                        </a>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.questionpdfIn[4] ? (
                      <div className="ans ml-2">
                        5.
                        <a
                          href={awsURL + "/" + this.state.questionpdfIn[4].replace("api/media/", "")}
                          alt="demo"
                          style={{ width: "200px" }}
                          target="_blank"
                        >
                          pdf1
                        </a>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
                <h3 className="text-danger">Options</h3>

                {this.state.optionTextContent === true ? (
                  <div>
                    <div className="ans ml-2">
                      <label className="radio">
                        1. <span>{this.state.optionTexts[0]}</span>
                      </label>
                    </div>
                    <div className="ans ml-2">
                      <label className="radio">
                        2. <span>{this.state.optionTexts[1]}</span>
                      </label>
                    </div>
                    <div className="ans ml-2">
                      <label className="radio">
                        3. <span>{this.state.optionTexts[2]}</span>
                      </label>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {this.state.optionImageMedia === true ? (
                  <div>
                    {this.state.optionImagesIn[0] != "" ? (
                      <div className="ans ml-2">
                        <label className="radio">
                          1.
                          <img
                            src={this.state.optionImagesIn[0].replace("api/media/", "")}
                            alt="demo"
                            style={{ width: "200px" }}
                          />
                        </label>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.optionImagesIn[1] != "" ? (
                      <div className="ans ml-2">
                        2.
                        <img
                          src={this.state.optionImagesIn[1].replace("api/media/", "")}
                          alt="demo"
                          style={{ width: "200px" }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.optionImagesIn[2] != "" ? (
                      <div className="ans ml-2">
                        3.
                        <img
                          src={this.state.optionImagesIn[2].replace("api/media/", "")}
                          alt="demo"
                          style={{ width: "200px" }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}

                {this.state.optionVideoMedia === true ? (
                  <div>
                    <div className="ans ml-2">
                      <label className="radio">
                        1.
                        <video width="320" height="240" controls>
                          <source
                            src={this.state.optionVideosIn[0].replace("api/media/", "")}
                            type="video/mp4"
                          />
                        </video>
                      </label>
                    </div>
                    <div className="ans ml-2">
                      2.
                      <video width="320" height="240" controls>
                        <source
                          src={this.state.optionVideosIn[1].replace("api/media/", "")}
                          type="video/mp4"
                        />
                      </video>
                    </div>
                    <div className="ans ml-2">
                      3.
                      <video width="320" height="240" controls>
                        <source
                          src={this.state.optionVideosIn[2].replace("api/media/", "")}
                          type="video/mp4"
                        />
                      </video>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {this.state.optionPdfMedia === true ? (
                  <div>
                    <div className="ans ml-2">
                      <label className="radio">
                        1.
                        <a
                          href={this.state.optionPdfsIn[0].replace("api/media/", "")}
                          alt="demo"
                          style={{ width: "200px" }}
                          target="_blank"
                        >
                          pdf1
                        </a>
                      </label>
                    </div>
                    <div className="ans ml-2">
                      2.
                      <a
                        href={this.state.optionPdfsIn[1].replace("api/media/", "")}
                        alt="demo"
                        style={{ width: "200px" }}
                        target="_blank"
                      >
                        pdf1
                      </a>
                    </div>
                    <div className="ans ml-2">
                      3.
                      <a
                        href={this.state.optionPdfsIn[2].replace("api/media/", "")}
                        alt="demo"
                        style={{ width: "200px" }}
                        target="_blank"
                      >
                        pdf1
                      </a>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="d-flex flex-row align-items-center question-title">
                  <h3 className="text-danger">Explanation.</h3>
                </div>
                {this.props.explanation.length > 0
                  ? this.props.explanation.map((explanation, index) => {
                    return (
                      <>
                        <p key={index}>
                          {explanation.explanation_type === "2" ? (
                            <img
                              src={explanation.url_media}
                              alt="demo"
                              style={{ width: "200px" }}
                            />
                          ) : null}
                          {explanation.explanation_type === "3" ? (
                            <video width="320" height="240" controls>
                              <source
                                src={explanation.url_media}
                                type="video/mp4"
                              />
                            </video>
                          ) : null}
                          {explanation.explanation_type === "4" ? (
                            <a
                              href={explanation.url_media}
                              style={{ width: "200px" }}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Click
                            </a>
                          ) : null}

                          {explanation.explanation_type === "5"
                            ? explanation.explanation_media
                            : null}

                          {explanation.explanation_type === "1"
                            ? explanation.explanation_media
                            : null}
                        </p>
                      </>
                    );
                  })
                  : ""}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-primary-newui btn-md-newui"
              onClick={() => this.handleModal()}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    explanation: state.QuestionReducer,
    load_category: state.LoadCategory,
    load_quiz: state.LoadQuiz,
  };
};
export default connect(mapStateToProps)(AddQuestion);
