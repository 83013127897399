import React, { Component } from "react";
import CategoryListCustom from "../../component/category/category-list-custom";
import { confirmAlert } from "react-confirm-alert"; // Import
import API from "../../api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logout from "../../component/dashboard/logout";
import { GetError } from "../../component/action";

class CategoryList extends Component {
  constructor(props) {
    super(props);

    //const UrlStringSearch = window.location.search;
    //const UrlStringParams = new URLSearchParams(UrlStringSearch);
    //const UrlStringParam = UrlStringParams.get('category_name');

    this.state = {
      category_data: [],
      page_size: 10,
      resultCount: null,
      noOfPages: 1,
      currentPage: 1,
      lastPage: 0,
      prevPage: null,
      nextPage: null,
      pagination: "",
      searchTerm: "",
      sortName: "category_name",
      sortOrder: "asc",
      isLoading: false,
    };

  }

  
  toastId = null;
  removeCategory = category_id => {
    let self = this;
    this.setState({
      isLoading: true
    });
    confirmAlert({
      title: "Confirm",
      message: "Are you sure you want to delete this category?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            API.delete(`category/category/${category_id}`)
              .then(res => {
                if (res.status === 200) {
                  toast.success(res.data.message);
                }
                this.loadCategoryList();
                this.setState({
                  isLoading: false
                });
              })
              .catch(function(error) {
                let errorObj = GetError(error);
                if (errorObj.status === 400) {
                  toast.error(errorObj.data.message);
                } else if (errorObj.data.status === 401) {
                  toast.error(errorObj.data.message);
                  setTimeout(function() {
                    logout();
                  }, 2000);
                } else {
                  toast.error("Something went wrong on server end.");
                }

                self.setState({
                  isLoading: false
                });
              });
          }
        },
        {
          label: "No",
          onClick: () => {
            this.setState({
              isLoading: false
            });
          }
        }
      ]
    });
  };
  changeStatus = (category_id, status) => {
    let self = this;
    this.setState({
      isLoading: true
    });
    let params = new FormData();
    params.append("status", status);
    API.post(`category/category_edit/${category_id}`, params)
      .then(res => {
        if (res.status === 200) {
          this.setState({
            isLoading: false
          });
          this.loadCategoryList();
          if (status === "1") {
            toast.success("Category is active.");
          } else if (status === "2") {
            toast.success("Category is inactive.");
          }
        }
      })
      .catch(function(error) {
        let errorObj = GetError(error);
        if (errorObj.status === 400) {
          toast.error(errorObj.data.message);
        } else if (errorObj.status === 401) {
          toast.error(errorObj.data.message);
          setTimeout(function() {
            logout();
          }, 2000);
        } else {
          toast.error("something went wrong on server end.");
        }
        self.setState({
          isLoading: false
        });
      });
  };
  componentWillMount() {
    this.loadCategoryList();
  }
  loadCategoryList() {
    let self = this;
    this.setState({
      isLoading: true,
    });

    API.get(
      `category/category?order_by=${this.state.sortName}&order=${this.state.sortOrder}&limit=${this.state.page_size}&searchTerm=${this.state.searchTerm}&page=${this.state.currentPage}`
    )
      .then(res => {
        if (res.status === 200) {
          let nextUrl =
            res.data.data.nextlink !== "" ? res.data.data.nextlink : null;
          let prevUrl =
            res.data.data.prevlink !== ""
              ? res.data.data.prevlink === ""
                ? 1
                : res.data.data.prevlink
              : null;
          this.setState({
            category_data: res.data.data.category,
            resultCount: res.data.data.total_rows,
            isLoading: false,
            noOfPages: Math.ceil(
              res.data.data.total_rows / this.state.page_size
            ),
            nextPage: nextUrl,
            prevPage: prevUrl,
            lastPage: Math.ceil(res.data.data.total_rows / this.state.page_size)
          });
        }
      })
      .catch(function(erorr) {
        let errorobj = GetError(erorr);
        self.setState({
          isLoading: false
        });
        if (errorobj.status === 400) {
          self.setState({ category_data: [] }, () => {
            if (self.state.category_data.length > 0) {
              toast.error(errorobj.data.message);
            }
          });
        } else if (errorobj.status === 401) {
          toast.error(errorobj.data.message);
          setTimeout(function() {
            logout();
          }, 2000);
        } else {
          toast.error("Something went wrong on server end.");
        }
      });
  }
  handleSort = (sortName, prevSortOrder) => {
    let sortOrder = prevSortOrder === "asc" ? "desc" : "asc";
    this.setState({ sortName, sortOrder }, () => {
      this.loadCategoryList();
    });
  };
  handleChange = event => {
    this.setState(
      {
        [event.target.name]: event.target.value,
        currentPage: 1,
        prevPage: null,
        nextPage: this.state.noOfPages > 1 ? 2 : null
      },
      () => {
        this.loadCategoryList();
      }
    );
  };
  gotoPage = (page, totalPages) => {
    if (page && page !== this.state.currentPage) {
      this.setState(
        {
          currentPage: page
        },
        () => {
          this.loadCategoryList();
        }
      );
    }
  };

  render() {
    return (
      <CategoryListCustom
        removeCategory={this.removeCategory}
        changeStatus={this.changeStatus}
        gotoPage={this.gotoPage}
        handleSort={this.handleSort}
        handleChange={this.handleChange}
        propsState={this.state}
      />
    );
  }
}

export default CategoryList;
