import React from "react";
function Loader(props) {
  return (props.isLoading === true || props.isLoadingExport === true) ? (
    <div id="loader-overlay" className="loader-overlay">
      <i className="fa fa-spinner fa-pulse" style={{ marginRight: 5 }} />
      Loading...
    </div>
  ) : (
    ""
  );
}

export default Loader;
