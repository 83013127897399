import { GET_ALL_ACTION_TYPE } from "../action/action-type-action";

export default function LoadActionType(state = [], action) {
  switch (action.type) {
    case GET_ALL_ACTION_TYPE:
      return action.payload.data.data;
    default:
      return state;
  }
}
