import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Loader from './loader';
import 'react-toastify/dist/ReactToastify.css';
import SimpleReactValidator from 'simple-react-validator';
import PropTypes from 'prop-types';
class ChangePasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      IsLoading: this.props.propsState.IsLoading,
      IsSubmit: this.props.propsState.IsSubmit,
      old_password: '',
      new_password: '',
      confirm_password: ''
    };
    this.validator = new SimpleReactValidator({
      validators: {
        validPassword: {
          message: 'Password must be 8 characters long.',
          rule: (val, params, validator) => {
            return val.length >= 8;
          },
          required: false // optional
        },

        validConfirmPassword: {
          message: 'The :attribute must be equal to password.',
          rule: (val, params, validator) => {
            if (this.state.new_password === val) {
              return true;
            } else {
              return false;
            }
          },
          required: true // optional
        }
      }
    });
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  SaveChangePassword = e => {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.validator.hideMessages();
      this.props.SaveChangePassword(this.state);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({
        IsLoading: false,
        IsSubmit: false
      });
    }
  };

  render() {
    return (
      <div>
        <Loader IsLoading={this.state.IsLoading} />
        <div className='page-wrapper'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='card'>
                  <h4 className='card-title'>Change Password</h4>
                  <div className='col-md-6'>
                    <form className='form-horizontal'>
                      <div className='card-body'>
                        <div className='form-group row'>
                          <div className='col-sm-9'>
                            <label className='control-label col-form-label label-text'>
                              Current Password<sup className='req-color'>*</sup>
                            </label>
                            <input
                              type='password'
                              className='form-control custom-input'
                              placeholder='Enter Current Password'
                              value={this.state.old_password}
                              name='old_password'
                              onChange={e => this.handleChange(e)}
                            />
                            {this.validator.message(
                              'Current Password',
                              this.state.old_password,
                              'required'
                            )}
                          </div>
                        </div>
                        <div className='form-group row'>
                          <div className='col-sm-9'>
                            <label className='control-label col-form-label label-text'>
                              New Password<sup className='req-color'>*</sup>
                            </label>
                            <input
                              type='password'
                              className='form-control custom-input'
                              placeholder='Enter New Password'
                              value={this.state.new_password}
                              name='new_password'
                              onChange={e => this.handleChange(e)}
                            />
                            {this.validator.message(
                              'New Password',
                              this.state.new_password,
                              'required|validPassword'
                            )}
                          </div>
                        </div>
                        <div className='form-group row'>
                          <div className='col-sm-9'>
                            <label className='control-label col-form-label label-text'>
                              Confirm Password<sup className='req-color'>*</sup>
                            </label>
                            <input
                              type='password'
                              className='form-control custom-input'
                              placeholder='Enter Confirm Password'
                              value={this.state.confirm_password}
                              name='confirm_password'
                              onChange={e => this.handleChange(e)}
                            />
                            {this.validator.message(
                              'Confirm Password',
                              this.state.confirm_password,
                              'required|validConfirmPassword'
                            )}
                          </div>
                        </div>
                        <div className=''>
                          <div className=''>
                            <button
                              type='button'
                              className='btn btn-primary-newui btn-md-newui'
                              disabled={this.state.IsSubmit}
                              onClick={e => this.SaveChangePassword(e)}
                            >
                              save
                            </button>
                            <NavLink
                              to='/quizreport'
                              type='button'
                              className='btn btn-primary-newui btn-md-newui'
                            >
                              Cancel
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ChangePasswordForm.propTypes = {
  SaveChangePassword: PropTypes.func
};
export default ChangePasswordForm;
