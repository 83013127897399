import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
const SideNavbar = (props) => {
  return (
    <aside
      className="left-sidebar"
      data-sidebarbg="skin5"
      onMouseEnter={() => props.hoverNav(true)}
      onMouseLeave={() => props.hoverNav(false)}
    >
      <div className="scroll-sidebar">
        <nav className="sidebar-nav">
          <ul id="sidebarnav" className="p-t-30">
            {/* <li className="sidebar-item">
              <a
                className={props.isActive("Users")}
                onClick={(e) => props.toggleNav("Users")}
                aria-expanded="false"
              >
                <i className="fas fa-users" />
                <span className="hide-menu">Users </span>
              </a>
              <ul aria-expanded="false" className={props.isActiveUl("Users")}>
                  <li className='sidebar-item'>
                  <NavLink
                    to='/adduser'
                    className='sidebar-link'
                    activeClassName='activechild'
                  >
                    <i className='fas fa-user' />
                    <span className='hide-menu'> Add User </span>
                  </NavLink>
                </li>
                <li className="sidebar-item">
                  <NavLink
                    to="/userslist"
                    className="sidebar-link"
                    activeClassName="activechild"
                  >
                    <i className="fas fa-users" />
                    <span className="hide-menu"> Users List </span>
                  </NavLink>
                </li>
              </ul>
            </li> */}
            <li className="sidebar-item">
              <a
                className={props.isActive("Category")}
                onClick={(e) => props.toggleNav("Category")}
                aria-expanded="false"
              >
                <i className="fas fa-list-alt" />
                <span className="hide-menu">Category </span>
              </a>
              <ul
                aria-expanded="false"
                className={props.isActiveUl("Category")}
              >
                {/* <li className="sidebar-item">
                  <NavLink
                    to="/addcategory"
                    className="sidebar-link"
                    activeClassName="activechild"
                  >
                    <i className="fas fa-calendar-check" />
                    <span className="hide-menu"> Add Category </span>
                  </NavLink>
                </li> */}
                <li className="sidebar-item">
                  <NavLink
                    to="/categorylist"
                    className="sidebar-link"
                    activeClassName="activechild"
                  >
                    <i className="fas fa-calendar-alt" />
                    <span className="hide-menu"> Category List </span>
                  </NavLink>
                </li>
              </ul>
            </li>

            <li className="sidebar-item">
              <a
                className={props.isActive("Quiz")}
                onClick={(e) => props.toggleNav("Quiz")}
                aria-expanded="false"
              >
                <i className="fas fa-calendar" />
                <span className="hide-menu">Quiz </span>
              </a>
              <ul aria-expanded="false" className={props.isActiveUl("Quiz")}>
                <li className="sidebar-item">
                  <NavLink
                    to="/addquiz"
                    className="sidebar-link"
                    activeClassName="activechild"
                  >
                    <i className="fas fa-calendar-check" />
                    <span className="hide-menu"> Add Quiz </span>
                  </NavLink>
                </li>
                <li className="sidebar-item">
                  <NavLink
                    to="/quizlist"
                    className="sidebar-link"
                    activeClassName="activechild"
                  >
                    <i className="fas fa-calendar-alt" />
                    <span className="hide-menu"> Quiz List </span>
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="sidebar-item">
              <a
                className={props.isActive("Questions")}
                onClick={(e) => props.toggleNav("Questions")}
                aria-expanded="false"
              >
                <i className="fas fa-list-alt" />
                <span className="hide-menu">Questions </span>
              </a>
              <ul
                aria-expanded="false"
                className={props.isActiveUl("Questions")}
              >
                <li className="sidebar-item">
                  <NavLink
                    to="/addquestion"
                    className="sidebar-link"
                    activeClassName="activechild"
                  >
                    <i className="fas fa-edit" />
                    <span className="hide-menu"> Add Question </span>
                  </NavLink>
                </li>
                <li className="sidebar-item">
                  <NavLink
                    to="/questionlist"
                    className="sidebar-link"
                    activeClassName="activechild"
                  >
                    <i className="fas fa-tasks" />
                    <span className="hide-menu"> Question List </span>
                  </NavLink>
                </li>
              </ul>
            </li>

            <li className="sidebar-item">
              <a
                className={props.isActive("Reports")}
                onClick={(e) => props.toggleNav("Reports")}
                aria-expanded="false"
              >
                <i className="fas fa-table" />
                <span className="hide-menu">Reports </span>
              </a>
              <ul aria-expanded="false" className={props.isActiveUl("Reports")}>
                <li className="sidebar-item">
                  <NavLink
                    to="/quizdetailreport"
                    className="sidebar-link"
                    activeClassName="activechild"
                  >
                    <i className="fas fa-list-alt" />
                    <span className="hide-menu"> Quiz Detail Report </span>
                  </NavLink>
                </li>
                <li className="sidebar-item">
                  <NavLink
                    to="/userreport"
                    className="sidebar-link"
                    activeClassName="activechild"
                  >
                    <i className="fas fa-list-alt" />
                    <span className="hide-menu"> User Report</span>
                  </NavLink>
                </li>
                <li className="sidebar-item">
                  <NavLink
                    to="/quizreport"
                    className="sidebar-link"
                    activeClassName="activechild"
                  >
                    <i className="fas fa-list-alt" />
                    <span className="hide-menu"> Quiz Report </span>
                  </NavLink>
                </li>
                <li className="sidebar-item">
                  <NavLink
                    to="/questionreport"
                    className="sidebar-link"
                    activeClassName="activechild"
                  >
                    <i className="fas fa-list-alt" />
                    <span className="hide-menu"> Questions Report</span>
                  </NavLink>
                </li>
                <li className='sidebar-item'>
                  <NavLink
                    to='/overallreport'
                    className='sidebar-link'
                    activeClassName='activechild'
                  >
                    <i className='fas fa-list-alt' />
                    <span className='hide-menu'>Feedback Report-Overall</span>
                  </NavLink>
                </li>
                <li className='sidebar-item'>
                  <NavLink
                    to='/feedback-report'
                    className='sidebar-link'
                    activeClassName='activechild'
                  >
                    <i className='fas fa-list-alt' />
                    <span className='hide-menu'>Feedback Report</span>
                  </NavLink>
                </li>


                <li className='sidebar-item'>
                  <NavLink
                    to='/admin-change-log-report'
                    className='sidebar-link'
                    activeClassName='activechild'
                  >
                    <i className='fas fa-list-alt' />
                    <span className='hide-menu'>Admin Log Report</span>
                  </NavLink>
                </li>

              </ul>

            </li>
            <li className="sidebar-item">
              <NavLink
                className="sidebar-link waves-effect waves-dark sidebar-link"
                to="/notification"
                aria-expanded="false"
                activeClassName="activechild"
              >
                <i className="mdi mdi-view-dashboard" />
                <span className="hide-menu">Banner Notification</span>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
};
SideNavbar.propTypes = {
  toggleNav: PropTypes.func,
  isActive: PropTypes.func,
  isActiveUl: PropTypes.func,
};
export default SideNavbar;
