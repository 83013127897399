import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../dashboard/loader";
import "react-toastify/dist/ReactToastify.css";
import SimpleReactValidator from "simple-react-validator";
import API from "../../api";
import PropTypes from "prop-types";
class EditCategoryForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      category_id: "",
      category_name: "",
      Isloading: this.props.propsState.IsLoading,
      IsSubmit: this.props.propsState.IsSubmit,
    };
    this.validator = new SimpleReactValidator();
  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  componentWillMount() {
    let self = this;
    this.setState({
      Isloading: true,
    });
    API.get(`category/category/${this.props.id}`)
      .then((res) => {
        this.setState({
          category_id: res.data.data.category.category_id,
          category_name: res.data.data.category.category_name,
          Isloading: false,
        });
      })
      .catch(function (error) {
        toast.error("Something went wrong on server end.");
        self.setState({
          Isloading: false,
        });
      });
  }
  updateCategory(e) {
    e.preventDefault();
    if (this.validator.allValid()) {
      this.setState({ IsSubmit: true });
      this.props.updateCategory(this.state);
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      this.setState({
        Isloading: false,
        IsSubmit: false,
      });
    }
  }

  render() {
    return (
      <div>
        <Loader IsLoading={this.state.Isloading} />
        <div className="page-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <h4 className="card-title">Edit Category</h4>
                  <div className="col-md-6">
                    <form className="form-horizontal">
                      <div className="card-body">
                        <div className="form-group row">
                          <div className="col-sm-9">
                            <label className="control-label col-form-label">
                              Category Title <sup>*</sup>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="fname"
                              placeholder="Enter category title"
                              value={this.state.category_name}
                              name="category_name"
                              onChange={(e) => this.handleChange(e)}
                            />
                            {this.validator.message(
                              "Category title",
                              this.state.category_name,
                              "required|alpha_num_dash_space|min:2|max:100"
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="border-top">
                        <div className="card-body">
                          <button
                            type="button"
                            className="btn btn-primary-newui btn-md-newui"
                            disabled={this.state.IsSubmit}
                            onClick={(e) => this.updateCategory(e)}
                          >
                            Save
                          </button>

                          <NavLink
                            to="/categorylist"
                            type="button"
                            className="btn btn-primary-newui btn-md-newui"
                          >
                            Cancel
                          </NavLink>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
EditCategoryForm.propTypes = {
  updateCategory: PropTypes.func,
};
export default EditCategoryForm;
