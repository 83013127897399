import API from "../api";
import { GetError } from "../component/action";
export const GET_ALL_ACTION_TYPE = "GetAllActionType";
export const GET_ALL_USERNAME = "GetAllUserName";

export function GetAllActionType() {
  return async function (dispatch, getState) {
    await API.get("dashboard/admin_change_log_search_element?type=action_type")
      .then((res) => {
        //console.log(res.data);
        dispatch({ type: GET_ALL_ACTION_TYPE, payload: res });
      })
      .catch(function (error) {
        let errorObj = GetError(error);
        if (errorObj.status === 400) {
          console.log(errorObj.data.message);
          // toast.error(errorObj.data.message);
        } else if (errorObj.status === 401) {
          console.log(errorObj.data.message);
          // toast.error(errorObj.data.message);
        } else {
          console.log(errorObj.data.message);
          // toast.error("Something went wrong on server end.");
        }
      });
  };
}

export function GetAllUserName() {
  return async function (dispatch, getState) {
    await API.get("dashboard/admin_change_log_search_element?type=user_name")
      .then((res) => {
        dispatch({ type: GET_ALL_USERNAME, payload: res });
      })
      .catch(function (error) {
        let errorObj = GetError(error);
        if (errorObj.status === 400) {
          console.log(errorObj.data.message);
          // toast.error(errorObj.data.message);
        } else if (errorObj.status === 401) {
          console.log(errorObj.data.message);
          // toast.error(errorObj.data.message);
        } else {
          console.log(errorObj.data.message);
          // toast.error("Something went wrong on server end.");
        }
      });
  };
}
